import React from 'react';
import { AudioRecorder } from "react-audio-voice-recorder";
import { addAudioElement } from "@/utils/utils";

const UploadVoiceForm = ({ recorderControls, setVoice }) => {
  return (
    <>
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob, setVoice)}
        recorderControls={recorderControls}
        showVisualizer
      />
      <div id="audio" className="my-2 mt-3"></div>
    </>
  );
};

export default UploadVoiceForm;