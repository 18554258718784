import { changePass } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Button, Form, Input, message, Modal } from "antd";
import React from "react";

function ModalChangePass({ visible, handleCancel }) {
  const [formChangePass] = Form.useForm();
  const changePassword = async (data) => {
    try {
      const response = await changePass(data);
      if (response.code === StatusResponse.SUCCESS) {
        message.success("Đổi mật khẩu thành công!");
      } else {
        message.error(response.message);
      }
      handleCancel();
      formChangePass.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        title="Đổi mật khẩu"
        open={visible}
        footer={false}
        onCancel={handleCancel}
      >
        <Form layout="vertical" onFinish={changePassword} form={formChangePass}>
          <Form.Item
            label="Mật khẩu cũ"
            name="oldPassword"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu cũ!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Mật khẩu mới"
            name="newPassword"
            dependencies={["oldPassword"]}
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu mới!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("oldPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mật khẩu mới không được trùng với mật khẩu cũ!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Nhập lại mật khẩu"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Vui lòng nhập lại mật khẩu!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mật khẩu nhập lại không khớp!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
              htmlType="submit"
            >
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ModalChangePass;
