import React from "react";
import {
  Col,
  Divider,
  Image,
  Row,
  Tag,
  Typography,
  Tooltip,
  Flex,
  Button,
  Popover,
} from "antd";
import { QuestionCircleOutlined, EyeOutlined } from "@ant-design/icons";
import GroupUserPopover from "@/components/popover/GroupUserPopover";
import moment from "moment/moment";
import { formatCurrencyVND } from "@/utils/common";
import {
  getFileExtension,
  goToPage,
  addDays,
  getDateTimeOfNextMonth,
} from "@/utils/utils";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

const ExpenseDetailInfo = ({ dataDetail }) => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname.split("/")[1];
  const endReportDate = getDateTimeOfNextMonth(dataDetail?.endDate, 1);
  const endApprovalDate = addDays(dataDetail?.startDate, 7);
  const expenseAttachFiles = dataDetail?.expenseAttachFiles;
  const attFiles = dataDetail?.attFiles;
  const suffixGroup = dataDetail?.purpose?.purposeCode === "Tiktok" ? "a" : "";

  const renderListFiles = (listFiles) => {
    return (
      <div className="flex mt-4 gap-2 flex-wrap">
        {listFiles?.map((item, index) => {
          const fileExtension = getFileExtension(item?.filePath);
          const isVideo = ["mp4", "webm", "ogg"].includes(fileExtension);
          if (item.isVoice === false) {
            const fileExtension = getFileExtension(item?.filePath);
            return (
              <Flex className="flex-col" key={index}>
                <div className="w-[180px] h-[140px] overflow-hidden relative image-border flex items-center justify-center">
                  {isVideo ? (
                    <video
                      controls
                      className="absolute top-0 left-0 w-full h-full"
                      autoPlay={true}
                    >
                      <source
                        src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                        type={`video/${fileExtension}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Image
                      key={index}
                      src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                      alt={item?.filePath}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <div
                  className="min-h-[1rem] mt-2 max-w-[180px] break-words"
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </Flex>
            );
          }
        })}
      </div>
    );
  };

  const allFileExpenseOuput = renderListFiles(expenseAttachFiles);
  const singleFileExpenseOutput = renderListFiles(attFiles);

  const content = (
    <div className="max-w-[580px] max-h-[575px] overflow-y-auto custom-scroll-history">
      {allFileExpenseOuput}
    </div>
  );

  return (
    <>
      <Row>
        <Col span={8}>
          <Text strong>Mã phiếu: </Text>
          <Tag>{`${dataDetail?.expenseType?.shortName}${dataDetail?.id}`}</Tag>
          {(route === "increase-request" || route === "decrease-request") && (
            <>
              <span>| </span>
              <Text strong>Mã phiếu gốc: </Text>
              <Tag
                className={"cursor-pointer"}
                color={"blue"}
                onClick={() => {
                  goToPage(dataDetail?.refId, "NS", navigate, true);
                }}
              >{`${dataDetail?.refCode}`}</Tag>
            </>
          )}
        </Col>

        <Col span={8}>
          <Text strong>Loại đề xuất: </Text>
          <Text>{dataDetail?.expenseType?.name}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Quy trình: </Text>
          <Text>{dataDetail?.processType?.name}</Text>
        </Col>
      </Row>
      <Row className={"mt-4"}>
        <Col span={12}>
          <Text strong>Phiếu báo cáo: </Text>
          {dataDetail?.expenseSpendingHistory?.length > 0 ? (
            dataDetail?.expenseSpendingHistory?.map((item, index) => (
              <Tag
                className={"cursor-pointer text-blue-500 font-bold"}
                onClick={() => {
                  goToPage(item, "BC", navigate, true);
                }}
                key={index}
              >
                {`BC${item}`}
              </Tag>
            ))
          ) : (
            <span className={"font-bold"}> Chưa có báo cáo</span>
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Nhân viên tạo ngân sách: </Text>
          <Text>{dataDetail?.userCreate?.fullName ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Nhân viên được giao: </Text>
          <Text>
            {dataDetail?.userAssignTo?.fullName ?? "--"}
            <GroupUserPopover userGroup={dataDetail?.assignGroupUser} />
          </Text>
        </Col>
        <Col span={8}>
          <Text strong>Nhân viên phụ trách camp: </Text>
          <Text>{dataDetail?.userSupervisorCamp?.fullName ?? "--"}</Text>
        </Col>
      </Row>
      <Row className={"mt-4"}>
        <Col span={8}>
          <Text strong>Nhân viên xử lý: </Text>
          <Text>{dataDetail?.processingUser?.fullName || "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Nhóm hậu đài: </Text>
          <Text>{dataDetail?.group?.groupName}{suffixGroup}</Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Loại hình quảng cáo: </Text>
          <Text>{dataDetail?.purpose?.purposeName}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Ghi chú: </Text>
          <Text>{dataDetail?.note}</Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Thời gian tạo: </Text>
          <Text>
            {moment(dataDetail?.creationDate + "z").format("DD/MM/YYYY HH:mm")}
          </Text>
        </Col>
        <Col span={8}>
          <Text strong>Thời gian sử dụng ngân sách: </Text>
          <Text>
            {" "}
            Từ {moment(dataDetail?.startDate + "").format("DD/MM/YYYY")}
          </Text>
          <Text>
            {" "}
            đến {moment(dataDetail?.endDate + "").format("DD/MM/YYYY")}
          </Text>
          <Tooltip
            overlayClassName="!min-w-[270px]"
            title={
              <>
                <div>
                  Hạn chót báo cáo:{" "}
                  {moment(endReportDate).format("DD/MM/YYYY HH:mm")}
                </div>
                <div>
                  Hạn chót phê duyệt:{" "}
                  {moment(endApprovalDate).format("DD/MM/YYYY HH:mm")}
                </div>
              </>
            }
          >
            <QuestionCircleOutlined className="ml-2" />
          </Tooltip>
        </Col>
        <Col span={8}>
          <Text strong>Thời gian yêu cầu thực tại: </Text>
          <Text>
            {" "}
            Từ{" "}
            {moment(dataDetail?.calAmountExpenseRequest?.startDate + "").format(
              "DD/MM/YYYY"
            )}
          </Text>
          <Text>
            {" "}
            đến{" "}
            {moment(dataDetail?.calAmountExpenseRequest?.endDate + "").format(
              "DD/MM/YYYY"
            )}
          </Text>
          <Tooltip
            overlayClassName="!min-w-[270px]"
            title={
              <div>
                <Text className={"text-white"}>
                  Đây là thời gian thực tại tổng hợp của tất cả các phiếu bao
                  gồm ngày của phiếu cha và tổng hợp kết thúc là ngày cuối cùng
                  của những phiếu con
                </Text>
              </div>
            }
          >
            <QuestionCircleOutlined className="ml-2" />
          </Tooltip>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Tên ngân hàng: </Text>
          <Text>{dataDetail?.bankName ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Tên tài khoản: </Text>
          <Text>{dataDetail?.accountName ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Số tài khoản: </Text>
          <Text>{dataDetail?.numberAccount ?? "--"}</Text>
        </Col>
        <Col span={8} className="mt-4">
          <Text strong>Nội dung chuyển khoản: </Text>
          <Text>{dataDetail?.bankNote ?? "--"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Ngân sách hỗ trợ: </Text>
          <span className={"font-bold"}>
            {dataDetail?.isSupportPackage ? "Có" : "Không"}
          </span>
        </Col>
        {dataDetail?.expenseType?.shortName === "DXT" && (
          <Col span={8}>
            <Text strong>Phê duyệt đặc biệt: </Text>
            <span className={"font-bold"}>
              {dataDetail?.isSpecialApprove ? "Có" : "Không"}
            </span>
          </Col>
        )}
      </Row>
      <Divider />
      {dataDetail?.expenseType?.code === "IncreaseAdj" && (
        <>
          <Row>
            <Col span={8}>
              <Text strong>Số tiền đề xuất tăng: </Text>
              <Text>{formatCurrencyVND(dataDetail?.totalAmount || 0)}</Text>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      <Row>
        <Col span={24}>
          <Flex align="center" gap={"10px"}>
            <Text className="block" strong>
              Hình ảnh:{" "}
            </Text>
            <Popover
              placement="right"
              title={
                <Text strong className="text-lg">
                  Danh sách các hình ảnh, video của các phiếu
                </Text>
              }
              content={content}
              trigger="click"
            >
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                size="medium"
              />
            </Popover>
          </Flex>
          {singleFileExpenseOutput}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Text strong>Voice xác nhận của Tổ trưởng:</Text>
      </Row>
      <div className="flex items-center gap-4 overflow-hidden">
        {dataDetail?.attFiles?.map((item, index) => {
          if (item.isVoice === true) {
            const fileExtension = getFileExtension(item?.filePath);
            return (
              fileExtension === "webm" && (
                <video
                  key={index}
                  controls
                  className="h-[70px] w-[30%]"
                  autoPlay={true}
                >
                  <source
                    src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                    type={`video/${fileExtension}`}
                  />
                  Your browser does not support the video tag.
                </video>
              )
            );
          }
        })}
      </div>
      <Divider />
    </>
  );
};

export default ExpenseDetailInfo;
