import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { getCloseRequestTicket } from "@/services/base";
import { ROLES, StatusResponse } from "@/utils/const";
import { Divider, Form, Tag, message } from "antd";
import DataTable from "@/common/DataTable";
import { infoUser } from "@/recoil/atoms";
import FormSearch from "@/common/FormSearch";
import {
  getListDepartment,
  getListSites,
  getListProcessStatus,
} from "@/utils/fetchData";
import { goToPage } from "@/utils/utils";
import CountData from "@/common/CountData";
import { formatCurrencyVND } from "@/utils/common";

const CloseRequest = () => {
  document.title = "Quản lý yêu cầu kết toán";
  const [closeReqTicket, setCloseReqTicket] = useState([]);
  const [paginationDepartment, setPaginationDepartment] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 5,
    pages: 1,
  });
  const [sites, setSites] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listProcessStatus, setlistProcessStatus] = useState([]);
  const [formSearch] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const infoUserState = useRecoilValue(infoUser);
  const userRoleCode = infoUserState?.role?.roleCode;
  const disableFilterRole =
    userRoleCode === ROLES.MEMBER ||
    userRoleCode === ROLES.TT ||
    userRoleCode === ROLES.TL ||
    userRoleCode === ROLES.TLB ||
    userRoleCode === ROLES.HLV;
  const [params, setParams] = useState({
    // siteId:
    //   disableFilterRole || userRoleCode === ROLES.PCQ
    //     ? infoUserState?.site?.id
    //     : null,
    // departmentId: disableFilterRole ? infoUserState?.department?.id : null,
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const columns = [
    {
      key: "id",
      title: "Mã kết toán",
      dataIndex: "id",
      render: (id, record) =>
        record.isRequestClose ? (
          "--"
        ) : (
          <a
            href
            onClick={() => goToPage(id, record.expenseType.shortName, navigate, true)}
          >{`${record.expenseType.shortName}${id}`}</a>
        ),
    },
    {
      key: "refId",
      title: "Mã ngân sách",
      dataIndex: "refId",
      render: (redId, record) => (
        <a
          href
          onClick={() => goToPage(redId || record.id, "NS", navigate, true)}
        >{`NS${redId || record.id}`}</a>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      key: "site",
      title: "Trang",
      dataIndex: "site",
      render: (rec) => rec.siteName,
    },
    {
      key: "team",
      title: "Tổ làm việc",
      dataIndex: "team",
      render: (rec) => rec.teamName,
    },
    {
      title: "Nhóm hậu đài",
      dataIndex: "group",
      key: "group",
      render: (value) => (value ? value.groupName : "--"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          width: 200,
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          width: 200,
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Số tài khoản",
      dataIndex: "",
      key: "bankAccount",
      render: (value) =>
        value?.numberAccount
          ? `${value.numberAccount} - ${value.bankName}`
          : "--",
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      render: ({ statusCode, statusName }, { processingUser }) => {
        const fullNameProcessingUser = processingUser?.fullName;
        const textProcessingUser = statusCode == "Inprogress" && fullNameProcessingUser ? `${fullNameProcessingUser} - ` : "";
        
        const colorMap = {
          Done: "green",
          Inprogress: "blue",
          Reject: "red",
          Pending: "yellow",
          default: "default"
        };

        const color = colorMap[statusCode] || colorMap.default;

        return <Tag color={color}>{textProcessingUser}{statusName}</Tag>;
      },
    },
  ];

  const fetchCloseRequestTicket = async (value) => {
    setLoading(true);
    try {
      const res = await getCloseRequestTicket(value);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }
      setTotalPages(res?.pagination?.total);
      const dataWithKey = res?.data
        ? res?.data.map((i) => {
            return { ...i, key: i.id };
          })
        : [];
      setCloseReqTicket(dataWithKey);
    } catch (error) {
      console.log("Error Fetching Close Request Ticket", error);
      message.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListProcessStatus(setlistProcessStatus);
    getListSites({}, setSites);
    getListDepartment(
      paginationDepartment,
      setListDepartment,
      setPaginationDepartment
    );
    formSearch.setFieldsValue({
      statusProcess: 0,
      // siteId:
      //   disableFilterRole || userRoleCode === ROLES.PCQ
      //     ? infoUserState?.site?.id
      //     : null,
      // departmentId: disableFilterRole ? infoUserState?.department?.id : null,
    });
  }, []);

  useEffect(() => {
    fetchCloseRequestTicket(params);
  }, [params]);

  const handleOnchangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...params, ...searchFields };
      setParams(newParams);
    },
    [params]
  );

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <FormSearch
          listProcessStatus={listProcessStatus}
          listSite={sites}
          setSearchParam={setParams}
          listDepartment={listDepartment}
          disableFilterRole={disableFilterRole}
          userRoleCode={userRoleCode}
          onchangeSearch={handleOnchangeSearch}
        />
      </section>
      <CountData loading={loading} total={totalPages} text="báo cáo" />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={closeReqTicket}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPages}
        loading={loading}
        onPageChange={handleOnchangePage}
      />
    </>
  );
};

export default CloseRequest;
