import React, { useEffect, useState } from 'react';
import { PROCESS_STATUS } from "@/utils/const";
import { Button, message, Space } from "antd";
import { disableButtonsProcess, showModalReject, addDays } from "@/utils/utils";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { getSteps } from "@/utils/fetchData";
import ModalPending from "@/common/ModalPending";
import { handlePendingExpense } from "@/services/base";

const GroupButtonProcess = ({
  dataDetail,
  setLoading,
  setApproveModal,
  loading,
  id,
  getExpenseDetail
}) => {
  const infoState = useRecoilValue(infoUser);
  const [steps, setSteps] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getSteps(dataDetail?.processTypeId, setSteps)
  }, []);

  const handlePending = async (value) => {
    try {
      setLoading(true);
      const res = await handlePendingExpense({ expenseRequestId: id, stepIdBack: value.step, processNote: value.note });
      if (res.isError) {
        message.error(res.message);
      } else {
        message.success("Đã chuyển trạng thái sang chờ giải quyết");
        getExpenseDetail(id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  const processStatus = dataDetail?.processStatus?.statusCode;
  const isProcessDisabled = disableButtonsProcess(infoState, dataDetail);
  const filteredSteps = (steps || []).filter(
    step => step?.stepPriority <= dataDetail?.processStep?.stepPriority
  );

  const currentDate = new Date();
  const endApprovalDate = addDays(dataDetail?.startDate, 7);
  const allowedApprovalDate = currentDate <= endApprovalDate;
  const disableWithDate = ["Proposal", "IncreaseAdj"].includes(dataDetail?.expenseType?.code) && !allowedApprovalDate;

  return (
    <div>
      {![PROCESS_STATUS.reject, PROCESS_STATUS.done].includes(processStatus) && (
        <Space className="w-full mt-4 justify-end">
          {/*{filteredSteps && filteredSteps.length > 0 && ![PROCESS_STATUS.pending].includes(processStatus) && (*/}
            <Button
              type="primary"
              className="custom-yellow-button"
              loading={loading}
              disabled={isProcessDisabled || disableWithDate}
              onClick={() => setVisible(true)}
            >
              Đợi giải quyết
            </Button>
          {/*)}*/}
          <Button
            onClick={() =>
              setApproveModal({
                isOpen: true,
              })
            }
            type="primary"
            disabled={isProcessDisabled || disableWithDate}
            loading={loading}
          >
            Phê duyệt
          </Button>
          <Button
            loading={loading}
            disabled={isProcessDisabled}
            onClick={() => showModalReject(id, setLoading, getExpenseDetail)}
            type="primary"
            danger
          >
            Từ chối
          </Button>
        </Space>
      )}
      <ModalPending
        visible={visible}
        steps={filteredSteps}
        onOk={handlePending}
        onCancel={() => setVisible(false)}
      />
    </div>
  );
};

export default GroupButtonProcess;