import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailExpenseApi } from "@/services/base";
import { Button, Divider, Flex, message, Row, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import DailyReportForm from "@/components/listRequset/DailyReportForm";
import ApproveModalForm from "@/components/expenseDetail/ApproveModalForm";
import { getListProduct, getSteps } from "@/utils/fetchData";
import GroupButtonProcess from "@/components/expenseDetail/GroupButtonProcess";
import ProposeCategoryDetail from "@/common/ProposeCategoryDetail";
import ProgressBar from "@/common/ProgressBar";
import MessageInfo from "@/common/MessageInfo";
import TicketInfo from "@/common/TicketInfo";
import ApproveHistoryInfo from "@/common/ApproveHistoryInfo";

const HistorySpendingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [historyDetail, setHistoryDetail] = useState();
  const [steps, setSteps] = useState([]);
  const [dailyModal, setDailyModal] = useState({
    isOpen: false,
    isConfirm: false,
    title: "",
    isSetMessageValue: false,
  });
  const [listProducts, setListProducts] = useState([]);
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
  });
  const nav = useNavigate();

  const fetchHistorySpending = async (id) => {
    setLoading(true);
    try {
      const res = await getDetailExpenseApi(id);
      if (res.code === 403) return nav("/forbidden");
      if (
        (res.code === 400 && res.error === -4) ||
        (res.code === 404 && res.error === -5)
      )
        return nav("/404");
      if (res.code !== 200 && res.error !== 0)
        return message.error(res?.message);
      setHistoryDetail(res.data);
      getSteps(res.data.processTypeId, setSteps);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistorySpending(id);
    getListProduct(setListProducts, {
      parentItemId: null,
      textSearch: null,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center ">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <ProgressBar
            dataDetail={historyDetail}
            steps={steps}
            title="Phiếu báo cáo hằng ngày"
            reload={() => fetchHistorySpending(id)}
          />
          <Divider />
          <TicketInfo dataDetail={historyDetail} />
          <MessageInfo messInfor={historyDetail?.messInfor} />
          <ProposeCategoryDetail dataDetail={historyDetail} />
          <ApproveHistoryInfo
            approvalHistory={historyDetail?.approvalHistory}
          />
          <Row className="mt-10">
            <Flex justify="space-between" className="w-full">
              <Button
                type="primary"
                className="px-4"
                onClick={() => navigate(-1)}
              >
                <ArrowLeftOutlined />
                Trở về
              </Button>
              <GroupButtonProcess
                dataDetail={historyDetail}
                id={id}
                loading={loading}
                setApproveModal={setApproveModal}
                getExpenseDetail={fetchHistorySpending}
                setLoading={setLoading}
              />
            </Flex>
          </Row>
          {dailyModal.isOpen && (
            <DailyReportForm
              modalState={dailyModal}
              handleCancel={() => setDailyModal({ isOpen: false })}
              dataDetail={historyDetail}
              handleReload={() => fetchHistorySpending(id)}
            />
          )}
          {approveModal.isOpen && (
            <ApproveModalForm
              listProducts={listProducts}
              approveModal={approveModal}
              handleCancel={() => setApproveModal({ isOpen: false })}
              dataDetail={historyDetail}
              handleReload={() => fetchHistorySpending(id)}
            />
          )}
        </>
      )}
    </>
  );
};

export default HistorySpendingDetail;
