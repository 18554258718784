import React from 'react';
import { Button, Space, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const GroupActionButton = ({ record, updateFn, showModalDelete }) => {
  return (
    <Space>
      <Tooltip title="Chỉnh sửa">
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          onClick={() => updateFn(record)}
        />
      </Tooltip>
      <Tooltip title="Xóa">
        <Button
          type="primary"
          shape="circle"
          danger
          icon={<DeleteOutlined />}
          size="small"
          onClick={() => showModalDelete(record.id)}
        />
      </Tooltip>
    </Space>
  );
};

export default GroupActionButton;