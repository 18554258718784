import React from 'react';
import { Form, Switch } from "antd";

const LastReportForm = () => {
  return (
    <Form.Item
      label="Xác nhận báo cáo cuối cùng"
      name="isLastReport"
      tooltip="Xác nhận báo cáo này là báo cáo chốt cuối cùng của ngân sách!"
    >
      <Switch />
    </Form.Item>
  );
};

export default LastReportForm;