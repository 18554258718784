import { Input, Space } from "antd";
import React, { memo, useCallback } from "react";
import debounce from "debounce";
import { SearchOutlined } from "@ant-design/icons";

const FormSearch = ({ onchangeSearch }) => {
  const debouncedSearch = useCallback(
    debounce((key, value) => {
      onchangeSearch(key, value);
    }, 300),
    []
  );

  const handleOnchangeTextSearch = (key, value) => {
    debouncedSearch(key, value);
  };

  return (
    <Space className="flex flex-wrap">
      <div className="flex">
        <Input
          size="middle"
          type="search"
          allowClear
          placeholder="Tìm kiếm theo hậu đài..."
          onChange={(e) =>
            handleOnchangeTextSearch({ textSearch: e.target.value })
          }
          className=" w-[300px]"
          addonBefore={<SearchOutlined />}
        />
      </div>
    </Space>
  );
};

export default memo(FormSearch);
