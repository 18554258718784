import React, { memo, useCallback, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "debounce";
import { getListAreas, getListDepartment, getListSites } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore } from "@/utils/utils";

const initParams = {
  textSearch: '',
  pageIndex: 1,
  pageSize: 20,
};

const TeamsFilterForm = ({ onchangeSearch, loading }) => {
  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [siteParams, setSiteParams] = useState(initParams);
  const [departmentParams, setDepartmentParams] = useState(initParams);
  const [areaParams, setAreaParams] = useState(initParams);
  const [form] = Form.useForm();
  const [params, setParams] = useState({
    textSearch: '',
    pageIndex: 1,
    pageSize: 20,
  });

  useEffect(() => {
    getListSites(siteParams, setSites, setSiteParams);
  }, [siteParams.pageSize, siteParams.textSearch]);

  useEffect(() => {
    getListDepartment(departmentParams, setDepartments, setDepartmentParams);
  }, [departmentParams.pageSize, departmentParams.textSearch]);

  useEffect(() => {
    if (areaParams.siteId || areaParams.departmentId) {
      getListAreas(areaParams, setAreas, setAreaParams);
    } else {
      setAreas([]);
    }
  }, [areaParams.pageSize, areaParams.textSearch, areaParams.siteId, areaParams.departmentId]);

  useEffect(() => {
    onchangeSearch(params);
  }, [params]);

  const debounceSearch = useCallback(debounce((searchText) => {
    setParams((prev) => ({
      ...prev,
      textSearch: searchText,
    }));
  }, 300), []);

  const filterOption = useCallback((input, option) =>
      option?.label.toLowerCase().includes(input.toLowerCase()),
    []);

  const siteOptions = (sites || []).map((item) => ({
    value: item.id,
    label: item.siteName,
  }));

  const departmentOptions = (departments || []).map((item) => ({
    value: item.id,
    label: item.departmentName,
  }));

  const areaOptions = (areas || []).map((item) => ({
    value: item.id,
    label: item.areaName,
  }));

  return (
    <Form form={form} className="flex flex-wrap gap-2 items-center">
      <Form.Item name="textSearch" className="w-[220px] !m-0">
        <Input
          placeholder="Tìm kiếm tổ"
          allowClear
          onChange={(e) => debounceSearch(e.target.value)}
          addonBefore={<SearchOutlined />}
        />
      </Form.Item>
      <Form.Item name="siteId" className='w-[220px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Chọn trang"
          onSearch={(e) => handleSearchLoadMore(e, setSiteParams)}
          onClear={handleClearLoadMore(setSiteParams)}
          onChange={(value) => {
            setParams((prev) => ({
              ...prev,
              siteId: value,
              departmentId: null,
              areaId: null
            }));
            setAreaParams(prev => ({
              ...prev,
              siteId: value,
            }));
            setAreas([]);

            form.resetFields(['departmentId', 'areaId'])
          }}
          filterOption={filterOption}
          options={siteOptions}
          onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
        />
      </Form.Item>
      <Form.Item name="departmentId" className='w-[220px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          className="w-[220px]"
          showSearch
          placeholder="Chọn bộ phận"
          onSearch={(e) => handleSearchLoadMore(e, setDepartmentParams)}
          onClear={handleClearLoadMore(setDepartmentParams)}
          onChange={(value) => {
            setParams((prev) => ({
              ...prev,
              departmentId: value,
              areaId: null
            }));
            setAreaParams(prev => ({
              ...prev,
              departmentId: value,
            }));
            setAreas([]);
            form.resetFields(['areaId'])
          }}
          filterOption={filterOption}
          options={departmentOptions}
          onPopupScroll={handleLoadMore(departmentParams, setDepartmentParams)}
        />
      </Form.Item>
      <Form.Item name="areaId" className='w-[220px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          className="w-[220px]"
          showSearch
          placeholder="Chọn khu"
          onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
          onClear={handleClearLoadMore(setAreaParams)}
          onChange={(value) => setParams((prev) => ({ ...prev, areaId: value}))}
          filterOption={filterOption}
          options={areaOptions}
          onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
        />
      </Form.Item>
    </Form>
  );
};

export default memo(TeamsFilterForm);