import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Input, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "debounce";
import { getListDepartment, getListSites } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore } from "@/utils/utils";

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0
};

const AreaFilterForm = ({ onchangeSearch }) => {
  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [siteParams, setSiteParams] = useState(initParams);
  const [departmentParams, setDepartmentParams] = useState(initParams);

  useEffect(() => {
    getListSites(siteParams, setSites, setSiteParams);
  }, [siteParams.pageSize, siteParams.textSearch]);

  useEffect(() => {
    getListDepartment(departmentParams, setDepartments, setDepartmentParams);
  }, [departmentParams.pageSize, departmentParams.textSearch]);

  const debouncedSearch = useCallback(
    debounce(onchangeSearch, 300),
    [onchangeSearch]
  );

  const handleInputChange = useCallback((event) => {
    debouncedSearch("textSearch", event.target.value);
  }, [debouncedSearch]);

  const handleSelectChange = useCallback((key) => (value) => {
    onchangeSearch(key, value);
  }, [onchangeSearch]);

  const filterOption = useCallback((input, option) =>
      option?.label.toLowerCase().includes(input.toLowerCase()),
    []);

  const siteOptions = useMemo(() =>
      sites?.map(({ id, siteName }) => ({
        value: id,
        label: siteName,
      })),
    [sites]
  );

  const departmentOptions = departments?.map(({ id, departmentName }) => ({
    value: id,
    label: departmentName,
  }));

  return (
    <Space className="flex flex-wrap">
      <Input
        placeholder="Tìm kiếm khu ..."
        allowClear
        onChange={handleInputChange}
        addonBefore={<SearchOutlined />}
        className="w-[220px]"
      />
      <Select
        allowClear
        className="w-[220px]"
        showSearch
        placeholder="Chọn trang"
        onChange={handleSelectChange("siteId")}
        onSearch={(e) => handleSearchLoadMore(e, setSiteParams)}
        onClear={handleClearLoadMore(setSiteParams)}
        filterOption={filterOption}
        options={siteOptions}
        onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
      />
      <Select
        allowClear
        className="w-[220px]"
        showSearch
        placeholder="Chọn bộ phận"
        onSearch={(e) => handleSearchLoadMore(e, setDepartmentParams)}
        onClear={handleClearLoadMore(setDepartmentParams)}
        onChange={handleSelectChange("departmentId")}
        filterOption={filterOption}
        options={departmentOptions}
        onPopupScroll={handleLoadMore(departmentParams, setDepartmentParams)}
      />
    </Space>
  );
};

export default memo(AreaFilterForm);