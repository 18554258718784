import React, { useState } from 'react';
import { Button, Form, message, Modal, Row, Select } from "antd";
import { handleCloseExpense } from "@/services/base";
import { useNavigate } from "react-router";
import { goToPage } from "@/utils/utils";

const CloseRequestForm = ({ id, dataDetail, modalCloseRequest, handleCancel, processTypes }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (value) => {
    setLoading(true);
    try {
      if (dataDetail?.isLastReport) {
        const res = await handleCloseExpense(id, value.closeRequestValue);
        if (res.isError) {
          message.error(res.message);
        } else {
          message.success("Đã gửi yêu cầu kết toán!");
          goToPage(res.data.id, "KT", navigate);
        }
      } else {
        message.error("Xác nhận đã chốt đủ camp mới cho phép tạo phiếu tất toán!");
      }

    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Gửi yêu cầu kết toán"
      open={modalCloseRequest.isOpen}
      width="600px"
      footer={false}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish}>
        <Form.Item name="closeRequestValue" rules={[{ required: true, message: "Vui lòng chọn quy trình kết toán!" }]}>
          <Select
            className="w-full my-5"
            allowClear
            placeholder="Chọn quy trình tạo phiếu kết toán..."
            options={processTypes?.map((process) => ({
              value: process.id,
              label: process.name,
            }))}
          />
        </Form.Item>
        <Row justify="end" className="flex gap-4 mt-4">
          <Button type="primary" danger onClick={handleCancel}>
            Hủy bỏ
          </Button>
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            Gửi kết toán
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default CloseRequestForm;
