import React, { useEffect } from "react";
import { Col, Form, Input, InputNumber, message, Modal, Row } from "antd";
import { addProcessType, updateProcessType } from "@/services/base";

const FormCreateProcessType = ({ modalState, handleClose, reload, expenseTypeId, purposeId }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      purposeId,
      expenseTypeId,
      id: modalState.id || undefined,
      name: modalState.data?.name || "",
      processPriority: modalState.data?.processPriority || "",
    });

    return () => {
      form.resetFields();
    };
  }, [expenseTypeId, purposeId, modalState, form]);

  const onFinish = async (value) => {
    const res = modalState.id
      ? await updateProcessType({ ...value, id: modalState.id })
      : await addProcessType(value);

    if (res.code !== 200) {
      return message.error(res.message);
    }

    message.success(res.message);
    handleClose();
    reload();
  };

  return (
    <Modal
      title={modalState.title}
      open={modalState.isOpen}
      width="600px"
      onCancel={handleClose}
      okText={modalState.okText}
      cancelText="Hủy bỏ"
      onOk={() => form.submit()}
      cancelButtonProps={{
        type: 'primary',
        danger: true
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="purposeId" hidden />
        <Form.Item name="expenseTypeId" hidden>
          <Input />
        </Form.Item>
        <Row className="mt-4">
          <Col span={24}>
            <Form.Item
              label="Tên quy trình"
              name="name"
              rules={[{ required: true, message: "Vui lòng nhập tên quy trình!" }]}
            >
              <Input placeholder="Nhập tên quy trình..." />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Độ ưu tiên quy trình"
              name="processPriority"
              rules={[
                { required: true, message: "Vui lòng nhập độ ưu tiên quy trình!" },
                {
                  validator: (_, value) =>
                    value < 0
                      ? Promise.reject(new Error("Độ ưu tiên quy trình không thể là số âm!"))
                      : Promise.resolve(),
                },
              ]}
            >
              <InputNumber className="w-full" placeholder="Nhập độ ưu tiên quy trình..." defaultValue={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FormCreateProcessType;
