import "@/App.css";
import { Suspense } from "react";
import Providers from "@/components/providers";

function App() {
  return (
    <Suspense fallback={false}>
      <Providers />
    </Suspense>
  );
}

export default App;
