import { infoUser } from "@/recoil/atoms";
import {
  getUserById,
  postFile,
  updateProfile,
} from "@/services/base";
import { StatusResponse } from "@/utils/const";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Upload,
  message,
} from "antd";
import { UserOutlined, CameraOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./Account.css";
dayjs.extend(customParseFormat);

const AccountInfo = () => {
  const [dataInfoUser, setDataInfoUser] = useRecoilState(infoUser);
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrl, setImageUrl] = useState(dataInfoUser?.avataUrl);
  const getImageUrl = (filePath) => {
    if (filePath) {
      let baseUrl = process.env.REACT_APP_API_URL;
      if (baseUrl.endsWith("/api")) {
        baseUrl = baseUrl.slice(0, -4);
      }

      return `${baseUrl}/${filePath}`;
    }
    return null;
  };

  const uploadFile = async () => {
    try {
      const response = await postFile({ file: imageUpload });
      return response.data?.filePath;
    } catch (error) {
      console.error("Error storing file:", error);
      throw error;
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    if (info.file.originFileObj) {
      if (info.file.originFileObj instanceof File) {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setImageUpload(info.file.originFileObj);
        });
      } else {
        console.error("originFileObj is not a File:", info.file.originFileObj);
      }
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Bạn chỉ có thể tải lên ảnh!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = async (values) => {
    const avatarUrl = imageUpload
      ? getImageUrl(await uploadFile())
      : dataInfoUser?.avataUrl;

    try {
      const dataUpdate = {
        ...dataInfoUser,
        ...values,
        avataUrl: avatarUrl,
        phoneNumber: values.phoneNumber,
        dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DDTHH:mm:ss"),
      };

      const response = await updateProfile(dataUpdate);

      if (response.code !== StatusResponse.SUCCESS) {
        message.error(response.message);
      } else {
        message.success("Cập nhật thông tin thành công!");
        const getMe = await getUserById(response.data.id);
        if (getMe.code === StatusResponse.SUCCESS) setDataInfoUser(getMe.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-2/4 mx-auto mt-10">
      <div className="p-[24px] min-h-[80vh] bg-[#fff] rounded-lg border border-solid border-[#e0e0e0]">
        <Form
          onFinish={onFinish}
          className=" mx-auto xl:my-10"
          layout="vertical"
          initialValues={{
            ...dataInfoUser,
            dateOfBirth:
              dataInfoUser?.dateOfBirth !== null
                ? dayjs(dataInfoUser?.dateOfBirth, "YYYY/MM/DD")
                : null,
            siteName: dataInfoUser?.site?.siteName,
            areaName: dataInfoUser?.area?.areaName,
            teamName: dataInfoUser?.team?.teamName,
            roleName: dataInfoUser?.role?.roleName,
            departmentName: dataInfoUser?.department?.departmentName
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="avataUrl"
                  label="Ảnh đại diện"
                  className="font-bold"
                >
                  <Space align="start">
                    <Upload
                      listType="picture-circle"
                      className="avatar-uploader relative"
                      showUploadList={false}
                      onChange={handleChange}
                      beforeUpload={beforeUpload}
                    >
                      {imageUrl ? (
                        <>
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            className="absolute w-[75%] h-[70%]   rounded-[50%] upload__bg"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.3)",
                              border: "0.5px dashed black",
                            }}
                          >
                            <div className="w-full h-full flex items-center justify-center ">
                              <CameraOutlined
                                style={{ fontSize: 30, color: "white" }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <Avatar
                            icon={<UserOutlined style={{ fontSize: 50 }} />}
                            style={{
                              background:
                                "linear-gradient(282deg, #00B0FF 5.54%, #3E98EB 100%)",
                            }}
                            className="w-full h-full"
                          />
                          <div
                            className="absolute w-[75%] h-[70%]   rounded-[50%] upload__bg"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.3)",
                              border: "0.5px dashed black",
                            }}
                          >
                            <div className="w-full h-full flex items-center justify-center ">
                              <CameraOutlined
                                style={{ fontSize: 30, color: "white" }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Upload>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  label="Tên đăng nhập"
                  name="userName"
                  className="font-bold"
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col offset={2} span={11}>
                <Form.Item
                  name="fullName"
                  label="Tên"
                  rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
                  className="font-bold"
                >
                  <Input size="large" allowClear placeholder="Nhập họ tên..." />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={11}>
                <Form.Item
                  label="Ngày sinh"
                  name="dateOfBirth"
                  className="font-bold"
                  rules={[
                    { required: true, message: "Vui lòng nhập ngày sinh" },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    allowClear={true}
                    className="w-full"
                    size="large"
                    disabledDate={(current) => {
                      return current && current.valueOf() > Date.now();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col offset={2} span={11}>
                <Form.Item
                  label="Email"
                  name="userEmail"
                  className="font-bold"
                  rules={[
                    {
                      type: "email",
                      message: "Vui lòng nhập đúng định dạng email!",
                    },
                  ]}
                >
                  <Input allowClear size="large" />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={11}>
                {/* <Form.Item
                  label="Số điện thoại"
                  name="phoneNumber"
                  className="font-bold"
                  rules={[
                    {
                      pattern: /^(\+\d{1,14}|\d{1,14})$/,
                      message: "Vui lòng nhập đúng định dạng số điện thoại!",
                    },
                  ]}
                >
                  <Input allowClear size="large" />
                </Form.Item> */}
                <Form.Item
                  label="Trang"
                  name="siteName"
                  className="font-bold"
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col offset={2} span={11}>
                <Form.Item
                  name="areaName"
                  label="Khu"
                  className="font-bold"
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  label="Tổ làm việc"
                  name="departmentName"
                  className="font-bold"
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col offset={2} span={11}>
                <Form.Item
                  name="teamName"
                  label="Bộ phận"
                  className="font-bold"
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="roleName"
                  label="Vai trò"
                  className="font-bold"
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={11}>
                <Form.Item
                  label="Trạng thái"
                  name="isActive"
                  className="font-bold"
                >
                  <Switch size="large" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={11}>
                <Form.Item>
                  <Button
                    type="primary"
                    className="custom-button w-full"
                    size="large"
                    htmlType="submit"
                  >
                    Chỉnh sửa
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default AccountInfo;
