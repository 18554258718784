import {
  getListExpenseType,
  getListProcessingStep,
  getListProcessStatusApi,
  getListProcessTypeById,
  getPagingDepartments,
  getPagingProcessingStep,
  getPagingRole,
  getPagingUsers,
  getProposedCategoryList,
  getTeamList,
  getPagingAreas,
  getPagingSites,
  getListGroup,
  getListDepartmentTypes,
  getGroupSummary,
  getListPurpose,
  getAllProcessType
} from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { message } from "antd";
import qs from "query-string";
import { sortRolesByRoleLevel } from "@/utils/common";

export const getListSites = async (value, setSites, setPaginationSites) => {
  try {
    const res = await getPagingSites(qs.stringify(value));

    if (res?.code !== StatusResponse.SUCCESS) {
      message.error(res?.message);
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setSites(data);

    if (setPaginationSites) {
      setPaginationSites((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (error) {
    message.error(error);
  }
};

export const getListDepartment = async (value, setListDepartment, setPaginationDepartment) => {
  try {
    const res = await getPagingDepartments(qs.stringify(value));

    if (res.code !== 200 && res.error !== 0) {
      message.error(res?.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListDepartment(data);

    if (setPaginationDepartment) {
      setPaginationDepartment((prev) => ({
        ...prev,
        total: res.pagination?.total
      }));
    }
  } catch (err) {
    console.error("Error in getListDepartment:", err);
  }
};

export const getListDepartmentById = async (id, setListDepartmentById,) => {
  try {
    const res = await getListDepartmentById(id);

    if (res.code !== 200 && res.error !== 0) {
      message.error(res?.message);
      return;
    }
    setListDepartmentById(res?.data);
  } catch (err) {
    console.error("Error in getListDepartment:", err);
  }
};

export const getListDepartmentType = async (params, setListDepartmentType) => {
  try {
    const res = await getListDepartmentTypes(qs.stringify(params))
    if (res.code !== StatusResponse.SUCCESS) {
      message.error(res.message);
      return;
    }
    setListDepartmentType(res.data);
  } catch (error) {
    console.log(" Error Fetch list deparment type:", error);
  }
}

export const getListAreas = async (params, setListAreas, setPaginationAreas) => {
  try {
    const res = await getPagingAreas(qs.stringify(params));
    if (res.code !== StatusResponse.SUCCESS) {
      message.error(res.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListAreas(data);

    if (setPaginationAreas) {
      setPaginationAreas((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getListTeams = async (value, setListTeams, setPaginationTeams) => {
  try {
    const res = await getTeamList(qs.stringify(value));

    if (res.code !== 200 && res.error !== 0) {
      message.error(res?.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListTeams(data);

    if (setPaginationTeams) {
      setPaginationTeams((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (err) {
    console.error("Error in getListDepartment:", err);
  }
};

export const getListProduct = async (setListProducts, params) => {
  try {
    const res = await getProposedCategoryList(params);

    if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
    setListProducts(res?.data);
  } catch (err) {
    console.log(err);
  }
};

export const getProcessTypes = async (setProcessTypes, id) => {
  const res = await getListProcessTypeById(id);
  if (res.code !== StatusResponse.SUCCESS) {
    setProcessTypes([]);
    return;
  }
  
  setProcessTypes(res.data);
};

export const getListProcessTypes = async (body, setListProcessType) => {
  const res = await getAllProcessType(qs.stringify(body));
  if (res.code !== StatusResponse.SUCCESS) {
    setListProcessType([]);
    return;
  }
  setListProcessType(res?.data || []);
};

export const getListUser = async (
  setListUser,
  setPaginationUser,
  paginationUser
) => {
  try {
    const res = await getPagingUsers(qs.stringify(paginationUser));
    if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
    setListUser(res?.data);
    setPaginationUser((prev) => ({ ...prev, pageSize: res.pagination?.pageSize, total: res.pagination?.total }));
  } catch (err) {
    console.log(err);
  }
};

export const getExpenseTypeList = async (setExpenseTypeList) => {
  try {
    const res = await getListExpenseType();
    if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
    setExpenseTypeList(res?.data);
  } catch (err) {
    console.log(err);
  }
};

export const getListProcessStatus = async (setlistProcessStatus) => {
  try {
    const res = await getListProcessStatusApi();
    if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
    setlistProcessStatus(res.data);
  } catch (err) {
    console.log(err);
  }
};

export const getListProcess = async (setlistProcess) => {
  try {
    const res = await getPagingProcessingStep();
    if (res.code !== 200 && res.error !== 0) return message.error(res.message);
    setlistProcess(res?.data);
  } catch (err) {
    console.log(err);
  }
};

export const getAllRoles = async (setRole) => {
  try {
    const res = await getPagingRole(qs.stringify({}));
    if (res?.code !== StatusResponse.SUCCESS) {
      message.error(res?.message);
    }
    const sortedRoles = sortRolesByRoleLevel(res.data);
    setRole(sortedRoles);
  } catch (error) {
    message.error(error);
  }
};

export const getSteps = async (processTypeId, setSteps) => {
  try {
    const params = {
      processTypeId,
    };
    const paramString = qs.stringify(params);

    const res = await getListProcessingStep(paramString);
    if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
    setSteps(res?.data);
  } catch (err) {
    console.log(err);
  }
};

export const fetchGroupSummary = async (groupId, setGroupSummary) => {
  try {
    if (groupId) {
      const res = await getGroupSummary(groupId);
      if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
      setGroupSummary(res?.data);
    } else {
      setGroupSummary(null);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getListGroups = async (params, setListGroup, setPaginationGroup) => {
  try {
    const res = await getListGroup(params);
    if (res.code !== StatusResponse.SUCCESS) {
      message.error(res.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListGroup(data);

    if (setPaginationGroup) {
      setPaginationGroup((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getListPurposes = async (params, setListPurpose) => {
  try {
    const res = await getListPurpose(params);
    if (res.code !== StatusResponse.SUCCESS) {
      message.error(res.message);
      return;
    }

    const data = res?.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListPurpose(data);

  } catch (error) {
    console.log("error", error);
  }
};
