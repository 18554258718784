import { Typography, List, Card, Skeleton, ConfigProvider, Tag } from "antd";
import React from "react";
import { EditOutlined, DeleteFilled } from "@ant-design/icons";
import moment from "moment";
const { Text } = Typography;
const ProcessListItem = ({
  item,
  updateProcessingSte,
  role,
  showModalConfirmDelete,
  loading,
}) => {
  const foundRole = role.find((rol) => rol.roleLevel === item.roleLevelMin);
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            paddingLG: 0,
          },
        },
      }}
    >
      <List.Item key={item.id} style={{ opacity: 1 }} className={``}>
        <Card
          className="border border-blue-300 process-item  card__process"
          actions={[
            <Skeleton loading={loading} active>
              <div
                className="sticky "
                onClick={() => {
                  updateProcessingSte(item);
                }}
              >
                <EditOutlined style={{ fontSize: 20 }} />
              </div>
            </Skeleton>,
            <Skeleton loading={loading} active>
              <div
                className="sticky "
                onClick={() => {
                  showModalConfirmDelete(item);
                }}
              >
                <DeleteFilled style={{ fontSize: 20 }} />
              </div>
            </Skeleton>,
          ]}
          title={
            <Skeleton loading={loading} active>
              <div className="p-3.5">
                <div>
                  <Text>
                    Tên quy trình: <b>{item?.stepName}</b>
                  </Text>
                </div>
                <div>
                  <Text>
                    Thứ tự của bước này:{" "}
                    <Tag color="processing">
                      <b>{item?.stepPriority}</b>
                    </Tag>
                  </Text>
                </div>
              </div>
            </Skeleton>
          }
        >
          <div className="max-h-[130px] overflow-auto container__listItem">
            <Skeleton loading={loading} active>
              <div className="p-3.5">
                <div>
                  <span>
                    Vai trò có thể duyệt bước này:
                    <b> {foundRole && foundRole?.roleName}</b>
                  </span>
                </div>
                <div>
                  <div>
                    {item.nextStepId !== null ? (
                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{
                          tooltip: item.nextStepId !== null ? true : false,
                          rows: 2,
                        }}
                      >
                        Bước tiếp theo của bước này: {""}
                        <b>({item?.nextStep?.stepName})</b>
                      </Typography.Paragraph>
                    ) : (
                      <span className="font-bold">(Bước cuối cùng)</span>
                    )}
                  </div>
                </div>
                <div>
                  <Typography.Text ellipsis={{ tooltip: true }}>
                    Thời gian tạo:{" "}
                    {moment(item?.creationDate + "z").format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text ellipsis={{ tooltip: true }}>
                    Lần chỉnh sửa cuối:{" "}
                    {moment(item?.modifiedDate + "z").format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </Typography.Text>
                </div>

                <div>
                  <Typography.Paragraph>
                    Ghi chú: {item?.note !== null ? item?.note : "N/A"}
                  </Typography.Paragraph>
                </div>
              </div>
            </Skeleton>
          </div>
        </Card>
      </List.Item>
    </ConfigProvider>
  );
};
export default ProcessListItem;
