import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SearchOutlined
} from "@ant-design/icons";
import {
  deleteProposedCategory,
  getProposedCategoryList,
} from "@/services/base";
import moment from "moment";
import debounce from "debounce";
import ButtonPrimary from "@/common/ButtonPrimary";
import DataTable from "@/common/DataTable";
import ProposedCategoryForm from "@/components/proposedCategory/ProposedCategoryForm";
import CountData from "@/common/CountData";
import GroupActionButton from "@/common/GroupActionButton";

const initStateModal = {
  title: "",
  isOpen: false,
  data: null,
  confirmText: "",
};

const initParams = {
  parentItemId: null,
  textSearch: null,
  pageIndex: 1,
  pageSize: 20,
  total: 0,
};

function ProposedCategory() {
  document.title = "Danh mục đề xuất";
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [params, setParams] = useState(initParams);
  const [selectedProducts, setSelectedProducts] = useState([])
  const [formSearch] = Form.useForm();
  const { confirm } = Modal;

  useEffect(() => {
    fetchProductList(params);
  }, []);

  const fetchProductList = async (params) => {
    setLoading(true);
    const { isError, data, pagination } = await getProposedCategoryList(params);
    if (!isError) {
      setProducts(data);
      setParams({
        pageIndex: pagination.pageIndex,
        total: pagination.total,
        pageSize: pagination.pageSize,
        textSearch: params.textSearch,
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Danh Mục Đề Xuất",
      dataIndex: "categoryName",
    },
    {
      title: "Người tạo",
      dataIndex: "userCreate",
      render: (record) => record?.fullName
    },
    {
      title: "Thời Gian Tạo",
      dataIndex: "creationDate",
      render: (creationDate) => moment(new Date(creationDate + "z")).format("DD/MM/YYYY HH:mm")
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => (
        <GroupActionButton record={record} showModalDelete={showModalConfirmDelete} updateFn={updateProposedCategory} />
      ),
    },
  ];

  const updateProposedCategory = (data) => {
    setStateModal({
      title: "Chỉnh sửa danh mục đề xuất",
      isOpen: true,
      data: data,
      confirmText: "Chỉnh sửa",
    });
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled />,
      content: "Điều này sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",
      cancelText: "Hủy",
      onOk() {
        onDelete(record);
      },
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const onDelete = async (id) => {
    const res = await deleteProposedCategory(id);
    if (res.isError) {
      message.error(res.message);
    } else {
      message.success(res.message);
      closeModal();
      reload();
    }
  };

  const onClickCreateProposedCategory = () => {
    setStateModal({
      title: "Thêm danh mục đề xuất",
      isOpen: true,
      data: {},
      confirmText: "Thêm",
    });
  };

  const handleSearch = (event) => {
    const value = event.target.value.trim().replace(/\s\s+/g, " ");
    setParams((prevParams) => ({
      ...prevParams,
      textSearch: value,
    }));
    debouncedFilter({ textSearch: value, pageSize: 10, pageIndex: 1 });
  };

  const debouncedFilter = useMemo(() => {
    return debounce((params) => onFilter(params), 300);
  }, []);

  const onFilter = (params) => {
    fetchProductList(params);
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const reload = () => {
    formSearch.resetFields();
    fetchProductList(initParams);
  };

  const onchangePage = (pageIndex, pageSize) => {
    setParams((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));

    fetchProductList({
      textSearch: params.textSearch,
      pageIndex: pageIndex,
      pageSize: pageSize,
    });
  };

  const deleteMultipleProduct = async () => {
    let deleteFails = [];
    const handleDelete = async () => {
      return await Promise.all(
        selectedProducts.map(async (item) => {
          try {
            const res = await deleteProposedCategory(item);
            if (res.isError) {
              message.error(res.message);
              deleteFails.push(res.message);
            }
          } catch (err) {
            console.error(`Failed to delete item ${item}:`, err);
          }
        })
      );
    };

    const refreshProductList = async () => {
      await fetchProductList(params);
    };

    try {
      setLoading(true);
      await handleDelete();
      if (deleteFails.length === 0) {
        message.success("Thành công");
      }
      await refreshProductList();
      setSelectedProducts([]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handlePopconfirm = {
    confirm: deleteMultipleProduct,
    cancle: () => {},
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <Form
          form={formSearch}
          className="flex flex-col xl:flex-row gap-4 items-center"
        >
          <Form.Item name="textSearch" className="w-[300px] !m-0">
            <Input
              placeholder="Tìm kiếm danh mục đề xuất..."
              allowClear
              onChange={handleSearch}
              addonBefore={<SearchOutlined/>}
            />
          </Form.Item>
        </Form>
        <div className="flex gap-3">
          {selectedProducts.length > 0 && (
            <Popconfirm
              title="Xóa danh mục đề xuất"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những danh mục đề xuất đã chọn, điều này không thể hoàn tác?"
              onConfirm={handlePopconfirm.confirm}
              onCancel={handlePopconfirm.cancle}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: 'primary',
                danger: true
              }}
            >
              <Typography.Text
                className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer"
                size="middle"
                style={{ border: "none" }}
              >
                Xóa {selectedProducts.length} danh mục đề xuất đã chọn
              </Typography.Text>
            </Popconfirm>
          )}
          <ButtonPrimary
            text="Thêm danh mục đề xuất"
            onClick={onClickCreateProposedCategory}
          />
        </div>
      </section>
      <CountData loading={loading} total={params.total} text="danh mục đề xuất"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={
          (products ?? []).map((item) => ({ ...item, key: item.id })) || []
        }
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={params.total}
        loading={loading}
        selectedRowKeys={selectedProducts}
        setSelectedRowKeys={setSelectedProducts}
        onPageChange={onchangePage}
      />
      <ProposedCategoryForm
        stateModal={stateModal}
        closeModal={closeModal}
        reload={reload}
      />
    </>
  );
}

export default ProposedCategory;
