import CompForm from "@/common/CompForm";
import { createSite, updateSite } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Checkbox, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";

const SitesFormAction = ({
  stateModal,
  closeModal,
  actionSite,
  currentPage,
}) => {
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } =
    stateModal;
  const [form] = Form.useForm();
  const [isSupper, setIsSupper] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
      setIsSupper(editData?.isSupper);
    }

    return () => {
      form.resetFields();
      setIsSupper(false);
    };
  }, [isOpenModal, editData, form]);

  const onFinish = async ({ ...fieldValues }) => {
    setLoading(true);
    const body = {
      siteName: fieldValues?.siteName,
      siteUrl: fieldValues?.siteUrl,
      isSupper: isSupper,
    };
    try {
      const res = isCreate
        ? await createSite(body)
        : await updateSite({ id: editData.id, ...body });
      if (res.code === StatusResponse.SUCCESS) {
        message.success(
          isCreate
            ? "Tạo mới trang thành công"
            : "Cập nhật trang thành công"
        );
        actionSite(
          { pageSize: 10, pageIndex: isCreate ? 1 : currentPage },
          isCreate ? "create" : "update"
        );
        closeModal();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const formItemsConfig = [
    {
      label: "Tên trang",
      name: "siteName",
      rules: [
        {
          required: true,
          message: "Tên trang không được bỏ trống!",
        },
      ],
      component: <Input placeholder="Nhập tên trang..." />,
    },
    {
      label: "Đường dẫn",
      name: "siteUrl",
      rules: [
        {
          required: true,
          message: "Đường dẫn không được bỏ trống!",
        },
      ],
      component: <Input placeholder="Nhập đường dẫn..." />,
    },
    {
      label: "Trang tổng",
      name: "isSupper",
      component: (
        <Checkbox
          defaultChecked={isSupper}
          onChange={(e) => {
            setIsSupper(e.target.checked);
          }}
        >
          Xác nhận trang tổng
        </Checkbox>
      ),
    },
  ];
  return (
    <Modal
      title={titleModal}
      open={isOpenModal}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <CompForm
        buttonOkModal={buttonOkModal}
        onFinish={onFinish}
        form={form}
        formItemsConfig={formItemsConfig}
        activeBtnSub={true}
        loading={loading}
      />
    </Modal>
  );
};

export default SitesFormAction;
