import { atom } from "recoil";
import { selectorUserInfo } from "@/recoil/selectors";

export const infoUser = atom({
  key: "infoUser",
  default: selectorUserInfo,
});

export const paginationGlobal = atom({
  key: "paginationGlobal",
  default: { pageIndex: 0, pageSize: 10 },
});

export const isModal = atom({
  key: "isModal",
  default: false,
});

export const signalRState = atom({
  key: "signalRState",
  default: null,
  dangerouslyAllowMutability: true,
});


export const paramsSuperivorState = atom({
  key: "paramsSuperivorState",
  default: {
    pageIndex: 1,
    pageSize: 99,
    total: 0,
    textSearch: "",
    groupId: null,
  },
})