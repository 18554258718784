import ButtonPrimary from "@/common/ButtonPrimary";
import {
  deleteProcessingStep,
  getListProcessingStep,
} from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Divider, List, Modal, message } from "antd";
import {
  ExclamationCircleFilled,
} from "@ant-design/icons";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import ProcessingStepFormAction from "@/components/processingStep/ProcessingStepFormAction";
import ProcessListItem from "@/components/processingStep/ProcessListItem";
import { getAllRoles } from "@/utils/fetchData";
import CountData from "@/common/CountData";
const { confirm } = Modal;
const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  loading: false,
  buttonOkModal: "",
  editData: {},
};

const ProcessingMain = ({ processTypeId, purposeId }) => {
  const [processingSteps, setProcessingSteps] = useState([]);
  const [role, setRole] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [loading, setLoading] = useState(false);

  const updateProcessingSte = (data) => {
    setStateModal({
      isOpenModal: true,
      loading: false,
      isCreate: false,
      titleModal: "Chỉnh sửa quy trình xử lý",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const onDeleteProcess = async (id) => {
    try {
      const res = await deleteProcessingStep(id);
      if (res.code === StatusResponse.SUCCESS) {
        message.success("Xóa quy trình thành công");
        actionProcess(id, "delete");
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled />,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",
      cancelText: "Hủy",
      onOk() {
        onDeleteProcess(record?.id);
      },
      onCancel() {},
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const fetchDataProcess = async () => {
    setLoading(true);
    try {
      const params = {
        processTypeId: processTypeId,
        purposeId: purposeId
      };

      const paramString = qs.stringify(params);

      const res = await getListProcessingStep(paramString);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setProcessingSteps(res?.data);
      setLoading(false);
    } catch (error) {
      console.log("Error Fetching Data Process", error);
      message.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataProcess();
    getAllRoles(setRole);
  }, []);

  const createProcessing = () => {
    setStateModal({
      isOpenModal: true,
      loading: false,
      isCreate: true,
      titleModal: "Thêm mới quy trình",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };
  const closeModal = () => {
    setStateModal(initStateModal);
  };
  const actionProcess = (data, type) => {
    switch (type) {
      case "create":
        fetchDataProcess();
        break;
      case "update":
        fetchDataProcess();
        break;
      case "delete":
        setProcessingSteps(processingSteps?.filter((pro) => pro?.id !== data));
        break;
      case "cancel":
        fetchDataProcess();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <section className="flex justify-between mb-4">
        <CountData loading={loading} text="quy trình" total={processingSteps?.length} />
        <ButtonPrimary text="Thêm quy trình" onClick={createProcessing}/>
      </section>
      <Divider/>
      <List
        grid={{gutter: 16, column: 4}}
        dataSource={processingSteps || []}
        renderItem={(item) => (
          <ProcessListItem
            loading={loading}
            updateProcessingSte={updateProcessingSte}
            role={role}
            item={item}
            showModalConfirmDelete={showModalConfirmDelete}
          />
        )}
      />
      <ProcessingStepFormAction
        processTypeId={processTypeId}
        dataProcess={processingSteps}
        stateModal={stateModal}
        setStateModal={setStateModal}
        allRole={role}
        closeModal={closeModal}
        actionProcess={actionProcess}
      />
    </>
  );
};

export default ProcessingMain;
