const { PUBLIC_KEY } = require("@/configs/config");
const { urlBase64ToUint8Array } = require("@/utils/utils");
const { subscribeNotification, deleteSubscriptionNotification } = require("@/services/base");
const { LocalStorageKey } = require("@/utils/const");
const { getAccessTokenBase } = require("@/utils/common");

const subscribe = (registration) => {
  registration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
    })
    .then(async (subscription) => {
      const data = {
        endpoint: subscription.endpoint,
        p256dh: btoa(
          String.fromCharCode.apply(
            null,
            new Uint8Array(subscription.getKey("p256dh"))
          )
        ),
        auth: btoa(
          String.fromCharCode.apply(
            null,
            new Uint8Array(subscription.getKey("auth"))
          )
        ),
      };

      const accessToken = getAccessTokenBase();

      if (accessToken) {
        const res = await subscribeNotification(data);
        if (!res.isError) {
          localStorage.setItem(
            LocalStorageKey.END_POINT_PUSH_NOTI,
            subscription.endpoint
          );
        }
      }
    });
};

const unsubscribe = (subscription, registration, userId) => {
  subscription
    .unsubscribe()
    .then(async (successful) => {
      const json = subscription.toJSON();

      // You've successfully unsubscribed
      const res = await deleteSubscriptionNotification({
        userId: userId,
        endpoint: json.endpoint,
      });
      if (!res.isError) {
        subscribe(registration);
      }
    })
    .catch((e) => {
      // Unsubscription failed
    });
};

const registerServiceWorker = (userId) => {
  const time = Date.now();

  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register(`/service-worker.js?v=${time}`)
            .then(function (registration) {
              if ("PushManager" in window) {
                registration.pushManager
                  .getSubscription()
                  .then((subscription) => {
                    if (!subscription) {
                      //the user was never subscribed
                      subscribe(registration);
                    } else {
                      //Renew subscription
                      unsubscribe(subscription, registration, userId);
                    }
                  });
              }
            });
        }
      }
    });
  } else {
    console.log("Trình duyệt không hỗ trợ Notifications API");
  }
};

export default registerServiceWorker;
