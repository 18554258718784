import { Tag } from "antd";
import React from "react";

const getTagInfo = (record) => {
  const { processStatus, expenseType } = record || {};
  const shortName = expenseType?.shortName;

  switch (processStatus?.id) {
    case 1:
      return { color: "default" };
    case 3:
      return { color: "orange" };
    case 5:
      return {
        color: "green",
        textOverride:
          shortName === "NS" || shortName === "DXT" || shortName === "DXG"
            ? processStatus?.statusName
            : undefined,
      };
    case 6:
      return { color: "red" };
    case 7:
      return { color: "green" };
    default:
      return { color: "blue" };
  }
};

const TagStatus = ({ record, className }) => {
  const { color, textOverride } = getTagInfo(record);
  const statusName = textOverride || record?.processStatus?.statusName;
  const statusCode = record?.processStatus?.statusCode;
  const fullNameProcessingUser = record?.processingUser?.fullName;
  const textProcessingUser = statusCode == "Inprogress" && fullNameProcessingUser ? `${fullNameProcessingUser} - ` : "";

  return (
    <Tag className={className} color={color}>
      {textProcessingUser}{statusName}
    </Tag>
  );
};

export default TagStatus;
