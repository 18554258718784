import { Input, Select, Space, DatePicker, Form, Row, Col, Button } from "antd";
import React, { memo, useState, useEffect } from "react";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import { getListTeams, getListGroups } from "@/utils/fetchData";
import { handleLoadMore, handleClearLoadMore, handleSearchLoadMore } from "@/utils/utils";

const { RangePicker } = DatePicker;

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  statuses: [],
  siteId: null,
  teamId: null,
  groupdId: null,
  fromDate: null,
  toDate: null,
};

const FormSearch = ({
  searchParams,
  setSearchParam,
  listProcessStatus,
  listSite,
  onchangeSearch,
}) => {
  const [formSearch] = Form.useForm();
  const [hasFilters, setHasFilters] = useState(false);
  const [listTeams, setListTeams] = useState([]);
  const [listGroups, setListGroups] = useState([]);
  const [groupParams, setGroupParams] = useState(initParams);
  const [teamParams, setTeamParams] = useState(initParams)
  listProcessStatus = listProcessStatus?.filter(process => ![1, 4].includes(process.id));

  useEffect(() => {
    getListTeams(teamParams, setListTeams, setTeamParams);
  }, [
    groupParams.pageSize,
    groupParams.textSearch,
    groupParams.siteId,
    groupParams.teamId,
  ]);

  useEffect(() => {
    getListGroups(groupParams, setListGroups, setGroupParams);
  }, [
    groupParams.pageSize,
    groupParams.textSearch,
    groupParams.siteId,
    groupParams.teamId,
  ]);

  const onChangeDate = (date) => {
    if (date) {
      const startDateString = dayjs(date[0]).format("YYYY-MM-DDTHH:mm:ss");
      const endDateString = dayjs(date[1]).endOf('day').format("YYYY-MM-DDTHH:mm:ss");

      onchangeSearch({
        fromDate: startDateString,
        toDate: endDateString,
      });
    } else {
      onchangeSearch({
        fromDate: "",
        toDate: "",
      });
    }
  };

  const checkFilters = (values) => {
    const filterApplied = Object.values(values).some(value =>
      value !== undefined && value !== null && value !== ''
    );
    setHasFilters(filterApplied);
  };

  const onValuesChange = (changedValues, allValues) => {
    checkFilters(allValues);
  };

  const handleReset = () => {
    formSearch.resetFields();
    setSearchParam(initParams);
    setHasFilters(false);
    onchangeSearch(initParams);
  };

  const handleSiteChange = (value) => {
    formSearch.setFieldsValue({ teamId: null, groupId: null });

    setGroupParams((prev) => ({
      ...prev,
      siteId: value,
    }));
    setTeamParams((prev) => ({
      ...prev,
      siteId: value,
    }));
  };

  function onSearch(data) {
    const date = data?.date || [];
    const hasDate = date.length > 0;
    const formatDate = (date, endOfDay = false) =>
      date ? dayjs(date)[endOfDay ? 'endOf' : 'startOf']('day').format("YYYY-MM-DDTHH:mm:ss") : null;
    const searchParams = {
      ...data,
      fromDate: hasDate ? formatDate(date[0]) : null,
      toDate: hasDate ? formatDate(date[1], true) : null,
      statuses: data.statuses ? data.statuses.join(',') : '',
    };

    checkFilters(data);
    onchangeSearch(searchParams);
  }

  return (
    <Space className="flex flex-wrap mb-4">
      <Form
        onReset={handleReset}
        form={formSearch}
        layout={"inline"}
        onFinish={onSearch}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span={24} className={"flex flex-col gap-2"}>
            <Row className="gap-2">
              <Col>
                <Form.Item name={"textSearch"} className="!m-0">
                  <Input
                    size="middle"
                    type="search"
                    allowClear
                    placeholder="Nhập mã..."
                    // onChange={(e) =>
                    //   handleOnchangeTextSearch({ textSearch: e.target.value })
                    // }
                    className="!w-[170px]"
                    addonBefore={<SearchOutlined/>}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="statuses" className="!m-0">
                  <Select
                    mode="multiple"
                    allowClear
                    className="!w-[170px]"
                    showSearch
                    placeholder="Chọn trạng thái ..."
                    // value={searchParams?.statusId}
                    // onChange={(e) => onchangeSearch({ statusId: e })}
                    filterOption={(input, option) =>
                      (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {(listProcessStatus || []).map((status) => (
                      <Select.Option key={status.id} value={status.id}>
                        {status.statusCode === "Done" ? "Hoàn thành" : status.statusName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={"siteId"} className="!m-0">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Chọn trang..."
                    value={searchParams?.siteId}
                    onChange={handleSiteChange}
                    filterOption={(input, option) =>
                      (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    className="!w-[170px]"
                  >
                    {(listSite || []).map((site) => (
                      <Select.Option key={site.id} value={site.id}>
                        {site.siteName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={"teamId"} className="!m-0">
                  <Select
                    // disabled={disableFilterRole}
                    // value={selectedTeam}
                    allowClear
                    showSearch
                    placeholder="Chọn tổ..."
                    // onChange={(e) => {
                    //   onchangeSearch({ teamId: e })
                    //   setSelectedTeam(e)
                    // }}
                    onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
                    onClear={handleClearLoadMore(setTeamParams)}
                    onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
                    filterOption={(input, option) =>
                      (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    className="!w-[170px]"
                  >
                    {listTeams.map((team) => (
                      <Select.Option key={team.id} value={team.id}>
                        {team.teamName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={"groupId"} className="!m-0">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Chọn nhóm hậu đài..."
                    // value={searchParams?.groupId}
                    onSearch={(e) => handleSearchLoadMore(e, setGroupParams)}
                    onClear={handleClearLoadMore(setGroupParams)}
                    filterOption={(input, option) =>
                      (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onPopupScroll={handleLoadMore(groupParams, setGroupParams)}
                    className="!w-[180px]"
                  >
                    {(listGroups || []).map((group) => (
                      <Select.Option key={group.id} value={group.id}>
                        {group?.groupName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={"date"} className="!m-0">
                  <RangePicker
                    placeholder={["Ngày Bắt Đầu", "Ngày Kết Thúc"]}
                    format={"DD/MM/YYYY"}
                    allowClear
                    size="middle"
                    className="!w-[260px]"
                    // onChange={(date) => onChangeDate(date)}
                  />
                </Form.Item>
              </Col>
              <div className="flex gap-2">
                <Button className="w-16 bg-primary hover:opacity-70" type="primary" htmlType="submit">
                  Lọc
                </Button>
                <Button className="w-16" htmlType="reset">
                  Xóa
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>

    </Space>
  );
};

export default memo(FormSearch);
