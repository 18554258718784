import ButtonPrimary from "@/common/ButtonPrimary";
import SitesFormAction from "@/components/sites/SitesFormAction";
import { deleteSite, getPagingSites } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import {
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Typography,
  message,
} from "antd";
import {
  ExclamationCircleFilled,
  SearchOutlined
} from "@ant-design/icons";
import qs from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import debounce from "debounce";
import DataTable from "@/common/DataTable";
import CompForm from "@/common/CompForm";
import moment from "moment";
import CountData from "@/common/CountData";
import GroupActionButton from "@/common/GroupActionButton";

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
}

const { confirm } = Modal;

const SitesPage = () => {
  document.title = "Quản lý trang";
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [formSearch] = Form.useForm();
  const [stateModal, setStateModal] = useState(initStateModal);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initParams);
  const [totalPages, setTotalPages] = useState(0);

  const updateSite = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa trang",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const onDeleteSite = async (id) => {
    try {
      const res = await deleteSite(id);
      if (res.code === StatusResponse.SUCCESS) {
        message.success("Xóa trang thành công");
        actionSites(id, "delete");
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled />,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",
      cancelText: "Hủy",
      onOk() {
        onDeleteSite(record);
      },
      onCancel() {},
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const columns = [
    {
      key: "siteName",
      title: "Trang",
      dataIndex: "siteName",
    },
    {
      title: "Đường dẫn trang",
      dataIndex: "siteUrl",
      key: "siteUrl",
    },
    {
      title: "Thời gian tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      render: (creationDate) => {
        return (
          <Typography.Text>
            {moment(creationDate + "z").format("DD/MM/YYYY HH:mm")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Hành động",
      key: "action",
      render: (_, record) => (
        <GroupActionButton record={record} showModalDelete={showModalConfirmDelete} updateFn={updateSite} />
      ),
    },
  ];

  const fetchDataSites = async (value) => {
    setLoading(true);
    try {
      const res = await getPagingSites(qs.stringify(value));
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }
      setParams({
        ...params,
        pageIndex: res?.pagination?.pageIndex,
        pageSize: res?.pagination?.pageSize,
        textSearch: value?.textSearch || "",
      });
      setTotalPages(res?.pagination?.total);
      const dataWithKey = res?.data
        ? res?.data.map((i) => {
            return { ...i, key: i.id };
          })
        : [];
      setSites(dataWithKey);
    } catch (error) {
      console.log("Error Fetching Data Site", error);
      message.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataSites(params);
  }, []);

  const onSearch = (data) => {
    actionSites(data, "search");
  };

  const debouncedSearch = useMemo(() => {
    return debounce((data) => onSearch(data), 300);
  }, []);

  const handleOnchangeSearch = (key, event) => {
    if (key === "textSearch") {
      const value = event.target.value.trim().replace(/\s\s+/g, " ");
      debouncedSearch({ ...params, textSearch: value });
    } else {
      onSearch({ ...params, [key]: event });
    }
  };

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
    fetchDataSites({
      ...params,
      pageIndex,
      pageSize,
    });
  };

  const createSites = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Thêm mới trang",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const actionSites = (data, type) => {
    switch (type) {
      case "create":
        fetchDataSites(data);
        break;
      case "update":
        fetchDataSites(data);
        break;
      case "delete":
        let siteId = data;
        setSites(sites.filter((site) => site.id !== siteId));
        setParams((prevParams) => ({
          ...prevParams,
        }));
        setTotalPages((prev) => prev - 1);
        break;
      case "search":
        fetchDataSites({ ...data, pageIndex: 1, pageSize: 10 });
        break;
      case "cancel":
        fetchDataSites();
        break;
      default:
        break;
    }
  };

  const deleteMultipleSites = async () => {
    let deleteFails = [];

    const handleDelete = async () => {
      return await Promise.all(
        selectedSites?.map(async (site) => {
          try {
            const res = await deleteSite(site);
            if (res.isError) {
              message.error(res.message);
              deleteFails.push(res.message);
            }
          } catch (err) {
            console.error(`Failed to delete site ${site}:`, err);
          }
        })
      );
    };

    const refreshSiteList = async () => {
      await fetchDataSites({
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
      });
    };

    try {
      setLoading(true);
      await handleDelete();
      if (deleteFails.length === 0) {
        message.success("Thành công");
      }
      await refreshSiteList();
      setSelectedSites([]);
    } catch (err) {
      console.error("Err deleteMultipleSites", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopconfirm = {
    confirm: deleteMultipleSites,
    cancle: () => {},
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <CompForm
          className={"flex gap-3 items-center"}
          children={
            <Form.Item name="textSearch" className="w-[300px] !m-0 ">
              <Input
                placeholder="Tìm kiếm trang..."
                allowClear
                onChange={(e) => handleOnchangeSearch("textSearch", e)}
                addonBefore={<SearchOutlined/>}
              />
            </Form.Item>
          }
          activeBtnSub={false}
          form={formSearch}
        />
        <div className="flex gap-3">
          {selectedSites.length > 0 && (
            <Popconfirm
              title="Xóa trang"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những trang đã chọn, điều này không thể hoàn tác?"
              onConfirm={handlePopconfirm.confirm}
              onCancel={handlePopconfirm.cancle}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: 'primary',
                danger: true
              }}
            >
              <Typography.Text
                className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer"
                size="middle"
                style={{ border: "none" }}
              >
                Xóa {selectedSites.length} trang đã chọn
              </Typography.Text>
            </Popconfirm>
          )}
          <ButtonPrimary text="Thêm trang" onClick={createSites}/>
        </div>
      </section>
      <CountData loading={loading} total={totalPages} text="trang"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={sites}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPages}
        loading={loading}
        selectedRowKeys={selectedSites}
        setSelectedRowKeys={setSelectedSites}
        onPageChange={handleOnchangePage}
      />
      <SitesFormAction
        stateModal={stateModal}
        closeModal={closeModal}
        actionSite={actionSites}
        currentPage={params.pageIndex}
      />
    </>
  );
};

export default SitesPage;
