import DataTable from "@/common/DataTable";
import {
  exportFileFigure,
  getFigureList,
  getTargetConfigList,
  importFileFigure,
} from "@/services/base";
import { formatCurrencyVND } from "@/utils/common";
import { Button, Divider, message, Modal, Table, Upload } from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { SITE_SUMMARY_STATUS } from "@/utils/const";
import CountData from "@/common/CountData";
import FormSearch from "@/components/figure/FormSearch";
import "./styles.css";
import { downloadExcelFile } from "@/utils/utils";
import UpdateFigureForm from "@/components/figure/UpdateFigureForm";

const Figure = () => {
  document.title = "Quản lý số liệu cược";

  const [listFigures, setListFigures] = useState([]);
  const [listTargetConfig, setListTargetConfig] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [stateModal, setStateModal] = useState({
    isOpen: false,
    data: null,
  });

  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
  });
  const [initialValues, setInitialValues] = useState({});

  const columns = [
    {
      title: "Hậu đài",
      dataIndex: "groupName",
      key: "groupName",
    },
    {
      title: "Số người chạy chung",
      dataIndex: "teamSize",
      key: "teamSize",
      width: 100,
      render: (value) => (value ? value : "--"),
    },
    {
      title: "NS quảng cáo",
      dataIndex: "nsqc",
      key: "nsqc",
      width: 120,
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "totalExpense",
      key: "totalExpense",
      width: 120,
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Bình quân ngân sách/ người",
      dataIndex: "budgetAverage",
      key: "budgetAverage",
      width: 130,
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Điểm KPI",
      dataIndex: "pointKpi",
      key: "pointKpi",
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Điểm KPI cần đạt của HĐQC",
      dataIndex: "pointKpiNeeded",
      key: "pointKpiNeeded",
      width: 120,
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Tổng nạp/ Ngân sách",
      dataIndex: "divDeposit",
      key: "divDeposit",
      width: 120,
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Tiền Ngân sách có thể ứng",
      dataIndex: "budgetAvailable",
      key: "budgetAvailable",
      width: 120,
      render: (value, record) =>
        value && record?.statusCheck !== "Unlimited"
          ? formatCurrencyVND(value)
          : "--",
    },
    {
      title: "Tổng nạp",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 120,
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Vòng cược",
      dataIndex: "pointRound",
      key: "pointRound",
      render: (value) => (value ? value : "--"),
    },
    {
      title: "NS đã bank trong ngày",
      dataIndex: "totalBanking",
      key: "totalBanking",
      width: 120,
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "NS đã bank trong ngày Tiktok",
      dataIndex: "totalBankingTiktok",
      key: "totalBankingTiktok",
      width: 140,
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Đạt/ Không đạt duyệt tiếp",
      dataIndex: "statusCheck",
      key: "statusCheck",
      render: (value) => {
        return SITE_SUMMARY_STATUS[value]?.text;
      },
    },
    {
      title: "Thời gian",
      dataIndex: "creationDate",
      key: "creationDate",
      render: (value) => moment(value + "z").format("HH:mm D/M/YYYY"),
    },
  ];

  const columnTarget = [
    {
      title: "Tên tiêu chí",
      dataIndex: "name",
      key: "name",
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Giá trị",
      dataIndex: "criteria",
      key: "criteria",
      render: (value) => (value ? value : "--"),
    },
  ];

  useEffect(() => {
    getFigures(searchParams);
  }, [searchParams]);

  useEffect(() => {
    getTargetConfig();
  }, []);

  const getFigures = async (params) => {
    try {
      setLoading(true);
      const res = await getFigureList(params);
      if (res.code === 200 && res.error === 0) {
        setListFigures(res?.data);
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getTargetConfig = async (params) => {
    try {
      setLoading(true);
      const res = await getTargetConfigList(params);
      if (res.code === 200 && res.error === 0) {
        const formattedCriteria = formatCurrencyVND(res?.data[0]?.criteria);
        const data = res.data.map((item, index) =>
          index === 0 ? { ...item, criteria: formattedCriteria } : item
        );
        setListTargetConfig(data);
        setInitialValues({
          [res.data[0].code]: res.data[0].criteria,
          [res.data[1].code]: res.data[1].criteria,
          [res.data[2].code]: res.data[2].criteria,
          [res.data[3].code]: res.data[3].criteria,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields, pageIndex: 1 };

      setSearchParam(newParams);
    },
    [searchParams]
  );

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
  };

  const onChangeFile = async (file) => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: (
          <p className="-ml-6">
            Bạn có chắc chắn muốn import file không? Dữ liệu cũ sẽ bị thay thế
            thành dữ liệu mới!
          </p>
        ),
        okText: "Vẫn tiếp tục",
        cancelText: "Hủy",
        onOk: async () => {
          const res = await importFileFigure({
            file: file.file.originFileObj,
            fileType: file.file.type,
          });
          if (res?.code === 200 && res?.error === 0) {
            message.success("Import file thành công!");
            getFigures(searchParams);
          } else {
            message.error("Import file thất bại!");
          }
        },
        cancelButtonProps: {
          type: "primary",
          danger: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportFile = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: <p className="-ml-6">Tải file dữ liệu về!</p>,
        okText: "Tải về",
        cancelText: "Hủy",
        onOk: async () => {
          const response = await exportFileFigure();
          if (response.code === 200 && response.error === 0) {
            const { data } = response;
            downloadExcelFile(data, "Quản lý số liệu cược");
            message.success("Export file thành công!");
          } else {
            message.error("Export file thất bại!");
            return;
          }
        },
        cancelButtonProps: {
          type: "primary",
          danger: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="flex justify-between mb-0">
        <div className="flex flex-col gap-4 justify-between ">
          <FormSearch onchangeSearch={handleChangeSearch}/>
          <CountData loading={loading} total={totalPage} text="hậu đài"/>
        </div>
        <div className="flex gap-4">
          <Upload accept=".xlsx, .xls" onChange={onChangeFile} fileList={[]}>
            <Button>
              <UploadOutlined/>
              Import file
            </Button>
          </Upload>
          <Button onClick={handleExportFile}>
            <DownloadOutlined/>
            Export file
          </Button>
        </div>
      </section>
      <div className="flex flex-col">
        <Table
          className="mr-2 mt-4 target-config max-w-[20%]"
          columns={columnTarget}
          dataSource={listTargetConfig}
          bordered
          pagination={false}
        />
        <Button
          className="max-w-[20%] mt-2"
          onClick={() =>
            setStateModal({
              isOpen: true,
              data: initialValues,
            })
          }
        >
          <EditOutlined/>
          Cập nhật tiêu chí
        </Button>
      </div>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={listFigures.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loading}
        scroll={{ x: "max-content", y: 320 }}
        isBorder
        onPageChange={onchangePage}
      />
      <UpdateFigureForm
        stateModal={stateModal}
        closeModal={() => {
          setStateModal({ isOpen: false, data: null });
        }}
        reload={() => getFigures()}
        getTargetConfig={getTargetConfig}
      />
    </>
  );
};

export default Figure;
