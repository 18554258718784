import { selector } from "recoil";
import { getAccessTokenBase, getUserInfor } from "@/utils/common";
import { StatusResponse } from "@/utils/const";
import { getUserById } from "@/services/base";

export const selectorUserInfo = selector({
  key: "selectorUserInfo",
  get: async () => {
    const accessToken = getAccessTokenBase();
    let userInfor = getUserInfor();
    if (!accessToken || !userInfor) return;

    userInfor = JSON.parse(userInfor);
    const res = await getUserById(userInfor.id);

    if (res?.code !== StatusResponse.SUCCESS)
      return localStorage.removeItem("access_token");
    return res?.data;
  },
});
