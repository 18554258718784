import { Col, Form, Input, Row, Select } from "antd";
import React from "react";

const MessageForm = ({ handleProcessTypeChange, processTypeList, typeForm}) => (
  <>
    {
      typeForm!=="Proposol"&&(
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Loại quy trình"
              name="processTypeId"
              rules={[{ required: true, message: "Vui lòng chọn quy trình" }]}
            >
              <Select
                allowClear
                showSearch
                onChange={handleProcessTypeChange}
                placeholder="Chọn quy trình"
                filterOption={(input, option) =>
                  (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
                }
              >
                {processTypeList?.map((process) => (
                  <Select.Option key={process.id} value={process.id}>
                    {process.name}
                  </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )
    }

    <Row gutter={[8, 8]}>
      <Col span={12}>
        <Form.Item
          label="Số lượng mess về"
          name="quantityIncome"
          rules={[
            { required: true, message: "Vui lòng nhập số lượng mess về!" },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(
                    new Error("Số lượng mess về không được âm!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Nhập số lượng mess về" type="number" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Số lượng mess phản hồi"
          name="quantityResponse"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số lượng mess phản hồi!",
            },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(
                    new Error("Số lượng mess phản hồi không được âm!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Nhập số lượng mess phản hồi" type="number" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <Form.Item
          label="Số lượng mess có tài khoản"
          name="quantityExistAccount"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số lượng mess có tài khoản!",
            },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(
                    new Error("Số lượng mess có tài khoản không được âm!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="Nhập số lượng mess có tài khoản"
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Số lượng mess rác"
          name="quantityTemp"
          rules={[
            { required: true, message: "Vui lòng nhập số lượng mess rác!" },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(
                    new Error("Số lượng mess rác không được âm!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Nhập số lượng mess rác" type="number" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <Form.Item
          label="Số lượng mess tham khảo"
          name="quantityReference"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số lượng mess tham khảo!",
            },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(
                    new Error("Số lượng mess tham khảo không được âm!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Nhập số lượng mess tham khảo" type="number" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Số lượng mess chốt được"
          name="quantityClosing"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số lượng mess chốt được!",
            },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(
                    new Error("Số lượng mess chốt được không được âm!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Nhập số lượng mess chốt được" type="number" />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default MessageForm;