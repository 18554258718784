import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, message, Modal, Select } from "antd";
import CompForm from "@/common/CompForm";
import { createArea, updateArea } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { getListDepartment, getListSites } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, transformData } from "@/utils/utils";

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0
};

const AreasFormAction = ({ stateModal, closeModal, reloadPage }) => {
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } = stateModal;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [siteParams, setSiteParams] = useState(initParams);
  const [departmentParams, setDepartmentParams] = useState(initParams);

  useEffect(() => {
    getListSites(siteParams, setSites, setSiteParams);
  }, [siteParams.pageSize, siteParams.textSearch]);

  useEffect(() => {
    getListDepartment(departmentParams, setDepartments, setDepartmentParams);
  }, [departmentParams.pageSize, departmentParams.textSearch]);

  useEffect(() => {
    if (editData) form.setFieldsValue({
      ...editData,
      siteId: {
        label: editData?.site?.siteName,
        value: editData?.siteId,
      },
      departmentId: {
        label: editData?.department?.departmentName,
        value: editData?.departmentId,
      }
    });
    return () => form.resetFields();
  }, [isOpenModal, editData, form]);

  const onFinish = useCallback(async (fieldValues) => {
    const data = transformData(fieldValues);
    setLoading(true);
    try {
      const action = isCreate ? createArea : updateArea;
      const res = await action(data);

      if (res.code !== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }

      message.success(isCreate ? "Tạo mới khu thành công" : "Cập nhật khu thành công.");
      reloadPage();
      closeModal();
    } catch (error) {
      console.error("Error in onFinish:", error);
    } finally {
      setLoading(false);
    }
  }, [isCreate, reloadPage, closeModal]);

  const filterOption = useCallback((input, option) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    []);

  const formItemsConfig = [
    {
      name: "id",
      hidden: true,
      component: <Input hidden />,
    },
    {
      label: "Tên khu",
      name: "areaName",
      rules: [{ required: true, message: "Tên khu không được bỏ trống!" }],
      component: <Input placeholder="Nhập tên khu ..." />,
    },
    {
      label: "Trang",
      name: "siteId",
      rules: [{ required: true, message: "Vui lòng chọn trang!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn trang"
          onSearch={(e) => handleSearchLoadMore(e, setSiteParams)}
          onClear={handleClearLoadMore(setSiteParams)}
          filterOption={filterOption}
          options={sites?.map(({ id, siteName }) => ({
            value: id,
            label: siteName,
          }))}
          onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
        />
      ),
    },
    {
      label: "Bộ phận",
      name: "departmentId",
      rules: [{ required: true, message: "Vui lòng chọn bộ phận!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn bộ phận"
          onSearch={(e) => handleSearchLoadMore(e, setDepartmentParams)}
          onClear={handleClearLoadMore(setDepartmentParams)}
          filterOption={filterOption}
          options={departments?.map(({ id, departmentName }) => ({
            value: id,
            label: departmentName,
          }))}
          onPopupScroll={handleLoadMore(departmentParams, setDepartmentParams)}
        />
      ),
    }
  ];

  return (
    <Modal
      title={titleModal}
      open={isOpenModal}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <CompForm
        loading={loading}
        buttonOkModal={buttonOkModal}
        onFinish={onFinish}
        form={form}
        formItemsConfig={formItemsConfig}
        activeBtnSub={true}
      />
    </Modal>
  );
};

export default AreasFormAction;