import { createUser, updateUser } from "@/services/base";
import { ROLE_ID, ROLES, StatusResponse } from "@/utils/const";
import { Button, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { isFullPermission, isFullPermissionManageUser } from "@/utils/common";
import { omit } from "lodash";
import { getListAreas, getListDepartment, getListGroups, getListSites, getListTeams } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, transformData } from "@/utils/utils";

const initParams = {
  textSearch: '',
  pageIndex: 1,
  pageSize: 20,
  total: 0
};

const UsersFormAction = ({ stateModal, closeModal, actionUser, roles, userInfor }) => {
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } = stateModal;
  const userInfo=useRecoilValue(infoUser);
  const [form] = Form.useForm();
  const [siteId, setSiteId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [areaId, setAreaId] = useState();
  const [role, setRole] = useState(form.getFieldValue('roleId'));
  const [loading, setLoading] = useState(false);

  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);

  const [siteParams, setSiteParams] = useState(initParams);
  const [departmentParams, setDepartmentParams] = useState(initParams);
  const [areaParams, setAreaParams] = useState({
    ...initParams,
    siteId: editData?.siteId || userInfo?.siteId,
    departmentId: editData?.departmentId || userInfo?.departmentId,
  });
  const [teamParams, setTeamParams] = useState({
    ...initParams,
    siteId: editData?.siteId || userInfo?.siteId,
    areaId: editData?.areaId || userInfo?.areaId,
    departmentId: editData?.departmentId || userInfo?.departmentId,
  });
  const [groupParams, setGroupParams] = useState({
    ...initParams,
    siteId: editData?.siteId || userInfo?.siteId,
    areaId: editData?.areaId || userInfo?.areaId,
    departmentId: editData?.departmentId || userInfo?.departmentId,
    teamId: editData?.teamId || userInfo?.teamId,
  });

  const roleCode = userInfo?.role?.roleCode;
  const isTT = roleCode === ROLES.TT;
  const isHLV = roleCode === ROLES.HLV;
  const isQL = roleCode === ROLES.QL;
  const isPCQ = roleCode === ROLES.PCQ;

  useEffect(() => {
    getListSites(siteParams, setSites, setSiteParams);
  }, [siteParams.pageSize, siteParams.textSearch]);

  useEffect(() => {
    getListDepartment(departmentParams, setDepartments, setDepartmentParams);
  }, [departmentParams.pageSize, departmentParams.textSearch]);

  useEffect(() => {    
    getListAreas(areaParams, setAreas, setAreaParams);
  }, [
    areaParams.pageSize,
    areaParams.textSearch,
    areaParams.siteId,
    areaParams.departmentId,
  ]);

  useEffect(() => {
    getListTeams(teamParams, setTeams, setTeamParams);
  }, [
    teamParams.pageSize,
    teamParams.textSearch,
    teamParams.siteId,
    teamParams.areaId,
    teamParams.departmentId,
  ]);

  useEffect(() => {
    getListGroups(groupParams, setGroups, setGroupParams);
  }, [
    groupParams.pageSize,
    groupParams.textSearch,
    groupParams.siteId,
    groupParams.areaId,
    groupParams.departmentId,
    groupParams.teamId,
  ]);

  const onValuesChange = (changedValues) => {
    if (changedValues.userName) {
      form.setFieldsValue({ fullName: changedValues.userName });
    }
  };

  useEffect(() => {
    if (editData) form.setFieldsValue({
      ...editData,
      siteId: editData?.siteId
        ? {
            label: editData?.site?.siteName,
            value: editData?.siteId,
          }
        : null,
      departmentId: editData?.departmentId
        ? {
            label: editData?.department?.departmentName,
            value: editData?.departmentId,
          }
        : null,
      areaId: editData?.areaId
        ? {
            label: editData?.area?.areaName,
            value: editData?.areaId,
          }
        : null,
      teamId: editData?.teamId
        ? {
            label: editData?.team?.teamName,
            value: editData?.teamId,
          }
        : null,
      groupId: editData?.groupId
        ? {
            label: editData?.group?.groupName,
            value: editData?.groupId,
          }
        : null,
    });
    return () => form.resetFields();
  }, [isOpenModal, editData, form]);

  const onFinish = async (formData) => {
    const data = transformData(formData);
    setLoading(true);
    const successMessages = {
      create: "Thêm nhân viên thành công!",
      update: "Chỉnh sửa nhân viên thành công!",
    };
    const actionType = isCreate ? "create" : "update";
    const dataUpdate = isCreate ? data : { ...editData, ...omit(data, ["fullName"]) };
    try {
      const response = isCreate ? await createUser(dataUpdate) : await updateUser(dataUpdate);

      if (response.code !== StatusResponse.SUCCESS) {
        message.error(response.message);
      } else {
        message.success(successMessages[actionType]);
        actionUser(response.data, actionType);
        closeModal();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((isFullPermission(roleCode) || isFullPermissionManageUser(roleCode)) && !editData ) {
      form.setFieldsValue({
        siteId: userInfo?.siteId,
        departmentId: userInfo?.departmentId,
        areaId:userInfo?.areaId,
        teamId: userInfo?.teamId,
        groupId: userInfor?.groupId
      })
    }
    setRole(form.getFieldValue('roleId'));
  }, [isOpenModal]);

  const isAllowedRole = (role) => {
    if (userInfo.role.roleLevel === null) return true;
    if (isHLV) return role.roleCode === ROLES.TT || role.roleCode === ROLES.MEMBER;
    return role.roleLevel < userInfor.role.roleLevel && role.roleLevel !== null;
  };

  const renderRoleOptions = () => {
    return roles.filter(isAllowedRole).map((role) => (
      <Select.Option key={role.id} value={role.id}>
        {role?.roleName}
      </Select.Option>
    ));
  };

  const onChangeRole = (value) => {
    setRole(value);
    if (userInfo.role?.roleCode === ROLES.TT) return;
  }

  const handleChangeSite = (value) => {
    setAreaParams((prev) => ({
      ...prev,
      siteId: value
    }));
    setTeamParams((prev) => ({
      ...prev,
      siteId: value
    }));
    setGroupParams((prev) => ({
      ...prev,
      siteId: value
    }));

    setSiteId(value);
    form.resetFields(["departmentId", "areaId", "teamId", "groupId"]);
  }

  const handleChangeArea = (value) => {
    setTeamParams((prev) => ({
      ...prev,
      areaId: value
    }));
    setGroupParams((prev) => ({
      ...prev,
      areaId: value
    }));

    setAreaId(value);
    form.resetFields(["teamId", "groupId"]);
  }

  const handleChangeDepartment = (value) => {
    setAreaParams((prev) => ({
      ...prev,
      departmentId: value
    }));
    setTeamParams((prev) => ({
      ...prev,
      departmentId: value
    }));
    setGroupParams((prev) => ({
      ...prev,
      departmentId: value
    }));

    setDepartmentId(value);
    form.resetFields(["areaId", "teamId", "groupId"]);
  }

  const handleChangeTeam = (value) => {
    setGroupParams((prev) => ({
      ...prev,
      teamId: value
    }));

    form.resetFields(["groupId"]);
  }

  return (
    <Modal title={titleModal} open={isOpenModal} width="600px" onCancel={closeModal} footer={null}>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ isBlock: false }} onValuesChange={onValuesChange}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="fullName" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Tài khoản"
          name="userName"
          rules={[{
            required: true, message: "Vui lòng không để trống.",
          }, {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "Tên đăng nhập chỉ được bao gồm chữ cái, số và dấu gạch dưới (_), và không bắt đầu bằng số hoặc dấu gạch dưới (_).",
          }]}
        >
          <Input disabled={!isCreate} placeholder="Nhập tài khoản"/>
        </Form.Item>
        <Form.Item label="Mật khẩu" name="password" rules={[{ required: isCreate, message: "Vui lòng không để trống." }]}>
          <Input.Password placeholder="Nhập mật khẩu"/>
        </Form.Item>
        <Form.Item label="Vai trò" name="roleId" rules={[{ required: true, message: "Vui lòng không để trống." }]}>
          <Select allowClear placeholder="Chọn vai trò" onChange={onChangeRole}>
            {renderRoleOptions()}
          </Select>
        </Form.Item>
        {role !== ROLE_ID.ADMIN && (
          <>
            <Form.Item name="id" hidden>
              <Input hidden />
            </Form.Item>
            <Form.Item label="Trang" name="siteId" rules={[{ required: true, message: "Vui lòng không để trống." }]}>
              <Select
                allowClear
                showSearch
                disabled={isPCQ || isQL || isHLV || isTT}
                placeholder="Chọn trang"
                onSearch={(e) => handleSearchLoadMore(e, setSiteParams)}
                onClear={handleClearLoadMore(setSiteParams)}
                onChange={handleChangeSite}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={sites?.map(site => ({ value: site.id, label: site.siteName }))}
                onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
              />
            </Form.Item>
            {role !== ROLE_ID.PCQ && (
              <>
                <Form.Item label="Bộ phận" name="departmentId" rules={[{ required: true, message: "Vui lòng không để trống."}]}>
                  <Select
                    allowClear
                    showSearch
                    disabled={isTT || isQL || isHLV}
                    placeholder="Chọn bộ phận"
                    onSearch={(e) => handleSearchLoadMore(e, setDepartmentParams)}
                    onClear={handleClearLoadMore(setDepartmentParams)}
                    onChange={handleChangeDepartment}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={departments?.map(department => ({ value: department.id, label: department.departmentName }))}
                    onPopupScroll={handleLoadMore(departmentParams, setDepartmentParams)}
                  />
                </Form.Item>
                {role !== ROLE_ID.QL && role !== ROLE_ID.TLB && role !== ROLE_ID.TL && (
                  <>
                    <Form.Item label="Khu" name="areaId" rules={[{ required: true, message: "Vui lòng không để trống."}]}>
                      <Select
                        allowClear
                        showSearch
                        disabled={isTT || isHLV}
                        placeholder="Chọn khu"
                        onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
                        onClear={handleClearLoadMore(setAreaParams)}
                        onChange={handleChangeArea}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        options={areas?.map(area => ({ value: area.id, label: area.areaName }))}
                        onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
                      />
                    </Form.Item>
                    {role !== ROLE_ID.HLV && (
                      <>
                        <Form.Item label="Tổ" name="teamId" rules={[{ required: true, message: "Vui lòng không để trống."}]}>
                          <Select
                            allowClear
                            showSearch
                            disabled={isTT}
                            placeholder="Chọn tổ"
                            onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
                            onClear={handleClearLoadMore(setTeamParams)}
                            onChange={handleChangeTeam}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={teams?.map(team => ({ value: team.id, label: team.teamName }))}
                            onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
                          />
                        </Form.Item>
                        <Form.Item label="Nhóm hậu đài" name="groupId">
                          <Select
                            allowClear
                            showSearch
                            placeholder="Chọn nhóm hậu đài"
                            onSearch={(e) => handleSearchLoadMore(e, setGroupParams)}
                            onClear={handleClearLoadMore(setGroupParams)}
                            options={groups?.map(group => ({ value: group.id, label: group.groupName }))}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            onPopupScroll={handleLoadMore(groupParams, setGroupParams)}
                          />
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <Row justify="end">
          <Button type="primary" htmlType="submit" className="mt-4" loading={loading}>
            {buttonOkModal}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default UsersFormAction;
