import React from 'react';
import { Col, Divider, Row, Typography } from "antd";
import moment from "moment/moment";

const MessageInfo = ({ messInfor }) => {
  const { Title, Text } = Typography;
  return (
    <div>
      {messInfor && (
        <>
          <section>
            <Row className="flex justify-between mt-10">
              <Title level={3}>Báo cáo chi tiêu</Title>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <Text strong>Thời gian báo cáo: </Text>
                <Text>
                  {moment(messInfor.creationDate + "z").format("DD/MM/YYYY HH:mm")}
                </Text>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={8}>
                <Text strong>Số lượng mess về: </Text>{" "}
                <Text>{messInfor.quantityIncome}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Số lượng mess phản hồi: </Text>{" "}
                <Text>{messInfor.quantityResponse}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Số lượng mess có tài khoản: </Text>{" "}
                <Text>
                  {messInfor.quantityExistAccount}
                </Text>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={8}>
                <Text strong>Số lượng mess rác: </Text>{" "}
                <Text>{messInfor.quantityTemp}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Số lượng mess tham khảo: </Text>{" "}
                <Text>{messInfor.quantityReference}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Số lượng mess chốt được: </Text>{" "}
                <Text>{messInfor.quantityClosing}</Text>
              </Col>
            </Row>
          </section>
        </>
      )}
    </div>
  );
};

export default MessageInfo;