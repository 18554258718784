import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  Divider,
  message,
  Col,
  Select,
  Upload,
  Image,
  Tooltip,
  Typography,
} from "antd";
import {
  createUpdatePurchaseRequest,
  postFile,
  getProcessingStepSupervisor,
  getListProcessingStep
} from "@/services/base";
import { StatusResponse, PROCESSING_EXPENSE_CODE } from "@/utils/const";
import "./styles.css";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { formatCurrencyVND } from "@/utils/common";
import { omit } from "lodash";
import qs from "query-string";
import MessageForm from "@/components/listRequset/MessageForm";
import { beforeUpload, getAmountDetail, handlePreview } from "@/utils/utils";
import { getListProcessTypes } from "@/utils/fetchData";

const DailyReportForm = ({
  modalState,
  handleCancel,
  dataDetail,
  handleReload,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingSupervisor, setLoadingSupervisor] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [listImage, setListImage] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [processTypeList, setProcessTypeList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [processTypeId, setProcessTypeId] = useState(dataDetail?.processTypeId);

  useEffect(() => {
    if (modalState.isConfirm) {
      form.setFieldsValue({ totalAmount: totalAmount });
    }
  }, [totalAmount]);

  useEffect(() => {
    if (modalState.isConfirm) {
      const calAmountExpenseDetail = dataDetail.calAmountExpenseDetail.map(
        (item) => {
          return {
            id: item.id,
            categoryName: item.categoryName,
            categoryId: item.categoryId,
            amount: item.amountInUse,
          };
        }
      );

      const totalAmountExpense = calAmountExpenseDetail?.reduce(
        (perviousValue, currentValue) =>
          perviousValue + parseFloat(currentValue.amount || 0),
        0
      );

      setTotalAmount(totalAmountExpense);

      form.setFieldsValue({
        ...dataDetail.messInfor,
        expenseDetails: calAmountExpenseDetail,
        totalAmount: totalAmountExpense,
        processTypeId: dataDetail?.processTypeId,
        supervisorId: dataDetail?.supervisorId,
      });
    }
    if (modalState.isSetMessageValue) {
      form.setFieldsValue({
        ...dataDetail.messInfor,
        processTypeId: dataDetail?.processTypeId,
        supervisorId: dataDetail?.supervisorId,
      });
    }

    if (dataDetail?.purpose?.id) {
      getListProcessTypes(
        {
          expenseTypeId: PROCESSING_EXPENSE_CODE.SpendingHistory,
          purposeId: dataDetail?.purpose?.id,
        },
        setProcessTypeList
      );
    }
  }, []);

  useEffect(() => {
    fetchDataProcessStep();
  }, [processTypeId])

  const fetchDataProcessStep = async () => {
    const params = {
      processTypeId: processTypeId
    };
    const paramString = qs.stringify(params);

    const res = await getListProcessingStep(paramString);
    if (res.code !== StatusResponse.SUCCESS) return message.error(res.message);

    const preccessStep = res?.data?.find(e => e.stepPriority === 0);
    await fetchSupervisorList(preccessStep?.id);
  };

  const fetchSupervisorList = async (processStepId) => {
    setLoadingSupervisor(true);
    const value = {
      processStepId: processStepId,
      departmentId: dataDetail?.departmentId,
      pageSize: 100
    };
    const params = qs.stringify(value);

    const res = await getProcessingStepSupervisor(params);
    if (res.code !== StatusResponse.SUCCESS) {
      return message.error(res.message)
    }
    setSupervisorList(res.data);
    setLoadingSupervisor(false);
  };

  const onFinish = async (value) => {
    setLoading(true);
    try {
      const expenseRequest = {
        id: modalState.isConfirm ? dataDetail.id : 0,
        refId: modalState.isConfirm ? dataDetail.refId : dataDetail.id,
        processTypeId: value.processTypeId,
        supervisorId: value.supervisorId,
        assignTo: dataDetail.assignTo,
        expenseTypeCode: "SpendingHistory",
        departmentId: dataDetail.departmentId,
        siteId: dataDetail.siteId,
        startDate: dataDetail.startDate,
        endDate: dataDetail.endDate,
        purposeId: dataDetail.purposeId,
        // totalAmount: modalState.isConfirm ? value.totalAmount : 0,
        note: dataDetail.note,
        // isConfirm: !!modalState.isConfirm,
      };

      const messInfor = {
        ...omit(value, ["expenseDetails", "totalAmount", "url"]),
        expenseId: dataDetail.id,
      };

      let expenseDetailAddOrEdit = null;
      let attachFiles = null;

      if (modalState.isConfirm) {
        expenseDetailAddOrEdit = {
          expenseDetailAdd: value.expenseDetails.map((item) => {
            return {
              expenseId: 0,
              categoryId: item.categoryId,
              amount: item.amount,
            };
          }),
        };

        const uploadedFiles = await Promise.all(
          listImage.map((image) => {
            let dataFile = {
              file: image.originFileObj,
            };
            return postFile(dataFile).then((result) => {
              return { ...result, description: image.description };
            });
          })
        );

        attachFiles = uploadedFiles.map((file) => ({
          expenseId: dataDetail.id,
          filePath: file.data.filePath,
          fileType: file.data.fileType,
          description: file?.description,
        }));
      }

      const expenseDailyData = {
        expenseRequest,
        messInfor,
        expenseDetailAddOrEdit,
        attachFiles,
      };

      const res = await createUpdatePurchaseRequest(expenseDailyData);
      if (!res.isError) {
        message.success(res.message);
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSuccess = () => {
    handleReload();
    form.resetFields();
    handleCancel();
    setListImage([]);
  };

  const handleChange = (file) => {
    setListImage(file.fileList);
  };

  const handleAmountChange = (changedValues, allValues) => {
    const { expenseDetails } = allValues;
    let total = 0;
    expenseDetails?.forEach((item) => {
      total += parseFloat(item?.amount || 0);
    });
    setTotalAmount(total);
  };

  const handleProcessTypeChange = (value) => {
    setProcessTypeId(value);
    form.setFieldsValue({
      supervisorId: null,
    });
  }

  return (
    <Modal
      title={modalState.title}
      open={modalState.isOpen}
      width="600px"
      onCancel={handleCancel}
      footer={null}
    >
      <Divider />
      <div className="mb-4 modal-body">
        {modalState.isConfirm ? (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            onValuesChange={handleAmountChange}
            initialValues={{
              expenseDetails: dataDetail.expenseDetails.map((item) => {
                return { id: item.id, categoryId: item.categoryId };
              }),
            }}
          >
            <MessageForm
              handleProcessTypeChange={handleProcessTypeChange}
              loadingSupervisor={loadingSupervisor}
              processTypeList={processTypeList}
              supervisorList={supervisorList}
            />
            <Form.List name="expenseDetails">
              {(fields) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item
                          label="Danh mục đề xuất"
                          name={[name, "categoryId"]}
                          {...restField}
                        >
                          <Select
                            disabled
                            allowClear
                            showSearch
                            placeholder="Chọn danh mục đề xuất"
                            filterOption={(input, option) =>
                              (option?.children ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={dataDetail.calAmountExpenseDetail.map(
                              (item) => {
                                return {
                                  label: item.categoryName,
                                  value: item.categoryId,
                                };
                              }
                            )}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <>
                              Số tiền đã chi tiêu
                              <Tooltip
                                overlayClassName="!min-w-[320px]"
                                title={
                                  <>
                                    Số tiền NS đã sử dụng:{" "}
                                    {formatCurrencyVND(
                                      getAmountDetail(key, dataDetail).amountInUse
                                    )}
                                    .<br></br>Số tiền NS đã được duyệt:{" "}
                                    {formatCurrencyVND(
                                      getAmountDetail(key, dataDetail).amount
                                    )}
                                    .<br></br>
                                  </>
                                }
                              >
                                <QuestionCircleOutlined className="ml-2" />
                              </Tooltip>
                            </>
                          }
                          {...restField}
                          name={[name, "amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập số tiền",
                            },
                            {
                              validator: (_, value) => {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Số tiền không được âm!")
                                  );
                                } else if (
                                  value > getAmountDetail(key, dataDetail).amount
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      `Số tiền không được vượt quá ngân sách phê duyệt cho mục này: ${formatCurrencyVND(
                                        getAmountDetail(key, dataDetail).amount
                                      )}`
                                    )
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            min={0}
                            placeholder="Nhập số tiền"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Form.Item
              label={
                <>
                  Tổng tiền danh mục đề xuất
                  <Tooltip
                    overlayClassName="!min-w-[320px]"
                    title={
                      <>
                        Số tiền NS đã sử dụng:{" "}
                        {formatCurrencyVND(
                          dataDetail?.calAmountExpenseRequest
                            ?.calculateAmountInUse || 0
                        )}
                        .<br></br>Số tiền NS đã được duyệt:{" "}
                        {formatCurrencyVND(
                          dataDetail?.calAmountExpenseRequest
                            ?.calculateAmount || 0
                        )}
                        .<br></br>
                      </>
                    }
                  >
                    <QuestionCircleOutlined className="ml-2" />
                  </Tooltip>
                </>
              }
              name="totalAmount"
              rules={[
                {
                  validator: (_, value) => {
                    const calculateAmount =
                      dataDetail?.calAmountExpenseRequest?.calculateAmount;

                    if (calculateAmount && totalAmount > calculateAmount) {
                      return Promise.reject(
                        new Error(
                          `Tổng số tiền không được vượt quá ngân sách phê duyệt ${formatCurrencyVND(
                            calculateAmount
                          )}`
                        )
                      );
                    }

                    if (String(value).length <= 12) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Tổng số tiền không được quá 12 số")
                    );
                  },
                },
              ]}
            >
              <Typography.Text>
                <b>{formatCurrencyVND(totalAmount || 0)}</b>
              </Typography.Text>
            </Form.Item>
            <Form.Item
              name="url"
              value={listImage}
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn hình ảnh!",
                },
              ]}
              label="Hình ảnh kèm theo"
            >
              <Upload
                listType="picture-card"
                className="hidden-tooltip-upload"
                onPreview={(file) => handlePreview(file, setPreviewImage, setPreviewOpen)}
                onChange={(info) => {
                  handleChange(info);
                  form.setFieldsValue({ url: listImage });
                }}
                maxCount={4}
                beforeUpload={beforeUpload}
                accept="image/*"
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{ display: "none" }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
              {/* {previewVideo && <video controls src={previewVideo} />} */}
            </Form.Item>
            <Row justify="end" className="flex gap-4">
              <Button
                type="primary"
                danger
                className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
                onClick={handleCancel}
              >
                Hủy bỏ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
              >
                Xác nhận
              </Button>
            </Row>
          </Form>
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <MessageForm
              handleProcessTypeChange={handleProcessTypeChange}
              loadingSupervisor={loadingSupervisor}
              processTypeList={processTypeList}
              supervisorList={supervisorList}
            />
            <Row justify="end" className="flex gap-4">
              <Button
                type="primary"
                danger
                className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
                onClick={handleCancel}
              >
                Hủy bỏ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
              >
                Báo cáo
              </Button>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default memo(DailyReportForm);
