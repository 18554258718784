import React from "react";
import MessageForm from "@/components/listRequset/MessageForm";

const FormDailyReportForm = ({ form }) => {

  const handleProcessTypeChange = (value) => {
    form.setFieldsValue({
      supervisorId: null,
    });
  }

  return (
    <MessageForm
      typeForm={"Proposol"}
      handleProcessTypeChange={handleProcessTypeChange}
    />
  )
}
export default FormDailyReportForm