import { Badge, Dropdown, Menu, Modal, Tag, message } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { infoUser, signalRState } from "@/recoil/atoms";
import { useRecoilValue } from "recoil";
import { formatCurrencyVND } from "@/utils/common";
import moment from "moment/moment";

import {
  deleteNotification,
  getNotification,
  viewNotification,
} from "@/services/base";
import "./styles.css";

const Notify = () => {
  const dataUser = useRecoilValue(infoUser);
  const connectionSignalR = useRecoilValue(signalRState);

  const [listNotify, setListNotify] = useState([]);
  // const [pagination, setPagination] = useState({
  //   pageIndex: 1,
  //   pageSize: 99,
  //   totalPage: 0,
  // });
  const [countView, setCountView] = useState(0);

  const getNotifies = async () => {
    try {
      const params = {
        pageIndex: 1,
        pageSize: 99,
        userId: dataUser.id,
        isViewAll: true,
      };
      const res = await getNotification(params);
      if (res.code === 200 && res.error === 0) {
        setListNotify(res.data);
        setCountView(res.data.filter((item, index) => !item.isViewed).length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotifies();
  }, [dataUser]);

  const markViewedAll = async () => {
    const params = {
      userId: dataUser?.id,
    };

    try {
      const res = await viewNotification(params);
      if (res.code === 200) {
        setCountView(0);
        getNotifies();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewDetails = async (item) => {
    const callViewNotification = async () => {
      const params = {
        userId: dataUser?.id,
        notificationId: item.notifyId,
      };
      try {
        const res = await viewNotification(params);
        if (res.code === 200) {
          getNotifies();
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (item?.objectType === "ExpenseDailyReport") {
      await callViewNotification();
      window.location.href = "/expense-request";
    } else if (
      item?.objectType === "ExpenseClose" ||
      item?.objectType === "ExpenseCloseAproved" ||
      item?.objectType === "ExpenseClosePending" ||
      item?.objectType === "ExpenseCloseReject"
    ) {
      await callViewNotification();
      window.location.href = `/close-request/${item.expenseRequest.id}`;
    } else if (
      item?.objectType === "ExpenseReport" ||
      item?.objectType === "ExpenseReportAproved" ||
      item?.objectType === "ExpenseReportPending" ||
      item?.objectType === "ExpenseReportReject"
    ) {
      await callViewNotification();
      window.location.href = `/daily-report/${item.expenseRequest.id}`;
    } else if (
      item?.objectType === "ExpenseIncrease" ||
      item?.objectType === "ExpenseIncreaseAproved" ||
      item?.objectType === "ExpenseIncreasePending" ||
      item?.objectType === "ExpenseIncreaseReject"
    ) {
      await callViewNotification();
      window.location.href = `/increase-request/${item.expenseRequest.id}`;
    } else if (item?.objectType === "ExpenseDecrease") {
      await callViewNotification();
      window.location.href = `/decrease-request/${item.expenseRequest.id}`;
    } else {
      await callViewNotification();
      window.location.href = `/expense-request/${item.expenseRequest.id}`;
    }
  };

  const deleteNotify = async () => {
    const params = {
      userId: dataUser?.id,
    };
    try {
      const res = await deleteNotification(params);
      if (res.code === 200 && res.error === 0) {
        message.success("Xóa thông báo thành công!");
        getNotifies();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeleteAllModal = () => {
    Modal.confirm({
      content: "Xác nhận xóa tất cả thông báo?",
      cancelText: "Hủy",
      onOk: deleteNotify,
      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  useEffect(() => {
    const handler = (data) => {      
      setListNotify((prev) => [data, ...prev]);
      setCountView((prev) => prev + 1);
    };
    connectionSignalR?.connection?.on("Expense", handler);
    connectionSignalR?.connection?.on("ExpenseStatusApprove", handler);
    connectionSignalR?.connection?.on("ExpenseDailyReport", handler);
    connectionSignalR?.connection?.on("ExpenseModify", handler);
    connectionSignalR?.connection?.on("ExpenseNeedApproved", handler);
    connectionSignalR?.connection?.on("ExpenseRequestClose", handler);
    connectionSignalR?.connection?.on("ExpenseAproved", handler);
    connectionSignalR?.connection?.on("ExpensePending", handler);
    connectionSignalR?.connection?.on("ExpenseReject", handler);
    connectionSignalR?.connection?.on("ExpenseClose", handler);
    connectionSignalR?.connection?.on("ExpenseIncrease", handler);
    connectionSignalR?.connection?.on("ExpenseDecrease", handler);
    connectionSignalR?.connection?.on("ExpenseReportPending", handler);
    connectionSignalR?.connection?.on("ExpenseReport", handler);
    connectionSignalR?.connection?.on("ExpenseReportAproved", handler);
    connectionSignalR?.connection?.on("ExpenseIncreaseAproved", handler);
    connectionSignalR?.connection?.on("ExpenseReportReject", handler);
    connectionSignalR?.connection?.on("ExpenseCloseAproved", handler);
    connectionSignalR?.connection?.on("ExpenseCloseReject", handler);
    connectionSignalR?.connection?.on("ExpenseIncreaseReject", handler);

    return () => {
      connectionSignalR?.connection?.off("Expense", handler);
      connectionSignalR?.connection?.off("ExpenseStatusApprove", handler);
      connectionSignalR?.connection?.off("ExpenseDailyReport", handler);
      connectionSignalR?.connection?.off("ExpenseModify", handler);
      connectionSignalR?.connection?.off("ExpenseNeedApproved", handler);
      connectionSignalR?.connection?.off("ExpenseRequestClose", handler);
      connectionSignalR?.connection?.off("ExpenseAproved", handler);
      connectionSignalR?.connection?.off("ExpensePending", handler);
      connectionSignalR?.connection?.off("ExpenseReject", handler);
      connectionSignalR?.connection?.off("ExpenseClose", handler);
      connectionSignalR?.connection?.off("ExpenseIncrease", handler);
      connectionSignalR?.connection?.off("ExpenseDecrease", handler);
      connectionSignalR?.connection?.off("ExpenseReportPending", handler);
      connectionSignalR?.connection?.off("ExpenseReport", handler);
      connectionSignalR?.connection?.off("ExpenseReportAproved", handler);
      connectionSignalR?.connection?.off("ExpenseIncreaseAproved", handler);
      connectionSignalR?.connection?.off("ExpenseReportReject", handler);
      connectionSignalR?.connection?.off("ExpenseCloseAproved", handler);
      connectionSignalR?.connection?.off("ExpenseCloseReject", handler);
      connectionSignalR?.connection?.off("ExpenseIncreaseReject", handler);
    };
  }, [connectionSignalR]);

  const menu = (
    <>
      {listNotify.length > 0 && (
        <div className="text-end absolute w-full z-10 bg-white h-[40px] flex items-center justify-around shadow-md rounded-md">
          <span
            onClick={confirmDeleteAllModal}
            className="cursor-pointer hover:text-primary"
          >
            Xóa đã xem
          </span>
          <span
            onClick={markViewedAll}
            className="cursor-pointer hover:text-primary"
          >
            Đánh dấu xem tất cả
          </span>
        </div>
      )}

      <Menu
        className={`min-w-[300px] max-h-[80vh] overflow-y-scroll custom-scroll-notify ${
          listNotify.length > 0 && "!mt-[40px]"
        }`}
      >
        {listNotify.length > 0 ? (
          listNotify.map((item, index) => {
            const totalAmount = formatCurrencyVND(item?.expenseRequest?.totalAmount || 0);
            const groupSite = item?.expenseRequest?.group?.groupName;
            const creationDate = moment(
              item?.expenseRequest?.creationDate + "z"
            ).format("DD/MM/YYYY HH:mm");

            return (
              <Menu.Item
                key={index}
                className={`${item?.isViewed ? "" : "bg-gray-200"} p-2`}
                onClick={() => handleViewDetails(item)}
              >
                {item.objectType === "Expense" ? (
                  item?.expenseRequest?.userCreate?.userName ===
                  dataUser.userName ? (
                    <>
                      <h4
                        className={`${
                          item?.isViewed ? "" : "text-[#38A7FF]"
                        } p-0 m-0`}
                      >
                        Tạo {item?.expenseRequest?.expenseType?.name} thành công
                      </h4>
                      <p
                        className={`${
                          item?.isViewed ? "" : "text-[#38A7FF]"
                        } p-0 m-0`}
                      >
                        Người tạo: {item?.expenseRequest?.userCreate?.fullName}
                      </p>
                      <p
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                      >
                        <b>Hậu đài: {groupSite}</b>
                      </p>
                      <p
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                      >
                        Ngân sách: {totalAmount}
                      </p>
                      <p
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                      >
                        Ngày tạo: {creationDate}
                      </p>
                      <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                        {new Intl.DateTimeFormat("vi-VN", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(new Date(item?.modifiedDate))}{" "}
                        {new Intl.DateTimeFormat("vi-VN", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(item?.modifiedDate))}
                      </p>
                      <hr />
                    </>
                  ) : (
                    <>
                      <h4
                        className={`${
                          item?.isViewed ? "" : "text-[#38A7FF]"
                        } p-0 m-0`}
                      >
                        Có 1 {item?.expenseRequest?.expenseType?.name} vừa được
                        tạo mới
                      </h4>
                      <p
                        className={`${
                          item?.isViewed ? "" : "text-[#38A7FF]"
                        } p-0 m-0`}
                      >
                        Người tạo: {item?.expenseRequest?.userCreate?.fullName}
                      </p>
                      <p
                        className={`${
                          item?.isViewed ? "" : "text-[#38A7FF]"
                        } p-0 m-0`}
                      >
                        Loại hình quảng cáo: {item?.expenseRequest?.purpose?.purposeName}{" "}
                      </p>
                      <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                        {new Intl.DateTimeFormat("vi-VN", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(new Date(item?.modifiedDate))}{" "}
                        {new Intl.DateTimeFormat("vi-VN", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(item?.modifiedDate))}
                      </p>
                      <hr />
                    </>
                  )
                ) : item.objectType === "ExpenseStatusApprove" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã được phê
                      duyệt
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Từ chối" ? (
                        <Tag color="red">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : item?.expenseRequest?.processStatus?.statusName ===
                        "Xác nhận" ? (
                        <Tag color="green">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : item?.expenseRequest?.processStatus?.statusName ===
                        "Đang xử lý" ? (
                        <Tag color="blue">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : (
                        <Tag color="orange">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      )}
                    </p>
                    {item?.expenseRequest?.processStatus?.statusCode ===
                      "Pending" && (
                      <p className="p-0 m-0 italic text-[#aaa]">
                        Phiếu đề xuất cần được cập nhật
                      </p>
                    )}
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseDailyReport" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0 py-1`}
                    >
                      Yêu cầu báo cáo hiệu quả chiến dịch
                    </h4>{" "}
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseModify" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} vừa được cập
                      nhật
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người cập nhật: {item?.expenseRequest?.userCreate?.fullName}
                    </p>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Loại hình quảng cáo: {item?.expenseRequest?.purpose?.purposeName}{" "}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseNeedApproved" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0 py-1`}
                    >
                      Có {item?.count} đề xuất cần được phê duyệt
                    </h4>

                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseRequestClose" ? (
                  <>
                    <h4 className="p-0 m-0 py-1">
                    Có 1 {item?.expenseRequest?.expenseType?.name} vừa được yêu
                    cầu kết toán
                    </h4>
                    <p className="p-0 m-0">
                      Người gửi yêu cầu:{" "}
                      {item?.expenseRequest?.userCreate?.fullName}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseAproved" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã được phê
                      duyệt
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Xác nhận" ? (
                        <Tag color="green">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : item?.expenseRequest?.processStatus?.statusName ===
                        "Đang xử lý" ? (
                        <Tag color="blue">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : (
                        <Tag color="orange">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      )}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpensePending" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đang đợi giải
                      quyết
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      <Tag color="orange">
                        {item?.expenseRequest?.processStatus?.statusName}
                      </Tag>
                    </p>
                    {item?.expenseRequest?.processStatus?.statusCode ===
                      "Pending" && (
                      <p className="p-0 m-0 italic text-[#aaa]">
                        Phiếu đề xuất cần được cập nhật
                      </p>
                    )}
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseReject" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã bị từ chối
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người từ chối: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      <Tag color="red">
                        {item?.expenseRequest?.processStatus?.statusName}
                      </Tag>
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseClose" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Tạo {item?.expenseRequest?.expenseType?.name} thành công
                    </h4>

                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseIncrease" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Tạo {item?.expenseRequest?.expenseType?.name} thành công
                    </h4>

                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseDecrease" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Tạo {item?.expenseRequest?.expenseType?.name} thành công
                    </h4>

                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseReportPending" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đang đợi giải
                      quyết
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      <Tag color="orange">
                        {item?.expenseRequest?.processStatus?.statusName}
                      </Tag>
                    </p>
                    {item?.expenseRequest?.processStatus?.statusCode ===
                      "Pending" && (
                      <p className="p-0 m-0 italic text-[#aaa]">
                        Phiếu Báo cáo hiệu quả chi tiêu cần được cập nhật
                      </p>
                    )}
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseIncreasePending" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đang đợi giải
                      quyết
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      <Tag color="orange">
                        {item?.expenseRequest?.processStatus?.statusName}
                      </Tag>
                    </p>
                    {item?.expenseRequest?.processStatus?.statusCode ===
                      "Pending" && (
                      <p className="p-0 m-0 italic text-[#aaa]">
                        Phiếu Đề xuất tăng cần được cập nhật
                      </p>
                    )}
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseClosePending" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đang đợi giải
                      quyết
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      <Tag color="orange">
                        {item?.expenseRequest?.processStatus?.statusName}
                      </Tag>
                    </p>
                    {item?.expenseRequest?.processStatus?.statusCode ===
                      "Pending" && (
                      <p className="p-0 m-0 italic text-[#aaa]">
                        Phiếu Kết toán cần được cập nhật
                      </p>
                    )}
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseReport" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Tạo {item?.expenseRequest?.expenseType?.name} thành công
                    </h4>

                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseReportAproved" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã được phê
                      duyệt
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Xác nhận" ? (
                        <Tag color="green">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : item?.expenseRequest?.processStatus?.statusName ===
                        "Đang xử lý" ? (
                        <Tag color="blue">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : (
                        <Tag color="orange">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      )}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseIncreaseAproved" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã được phê
                      duyệt
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Xác nhận" ? (
                        <Tag color="green">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : item?.expenseRequest?.processStatus?.statusName ===
                        "Đang xử lý" ? (
                        <Tag color="blue">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : (
                        <Tag color="orange">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      )}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseReportReject" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã bị từ chối
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Từ chối" ? (
                        <Tag color="red">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : null}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseCloseAproved" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã được phê
                      duyệt
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người duyệt: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      <b>Hậu đài: {groupSite}</b>
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngân sách: {totalAmount}
                    </p>
                    <p
                      className={`${item?.isViewed ? "" : "text-[#38A7FF]"} p-0 m-0`}
                    >
                      Ngày tạo: {creationDate}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Xác nhận" ? (
                        <Tag color="green">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : item?.expenseRequest?.processStatus?.statusName ===
                        "Đang xử lý" ? (
                        <Tag color="blue">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : (
                        <Tag color="orange">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      )}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseCloseReject" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã bị từ chối
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người từ chối: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Từ chối" ? (
                        <Tag color="red">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : null}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : item.objectType === "ExpenseIncreaseReject" ? (
                  <>
                    <h4
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Có 1 {item?.expenseRequest?.expenseType?.name} đã bị từ chối
                    </h4>
                    <p
                      className={`${
                        item?.isViewed ? "" : "text-[#38A7FF]"
                      } p-0 m-0`}
                    >
                      Người từ chối: {item?.expenseRequest?.userApprove?.fullName}
                    </p>
                    <p className="p-0 m-0">
                      <span
                        className={`${item?.isViewed ? "" : "text-[#38A7FF]"}`}
                      >
                        Trạng thái:{" "}
                      </span>
                      {item?.expenseRequest?.processStatus?.statusName ===
                      "Từ chối" ? (
                        <Tag color="red">
                          {item?.expenseRequest?.processStatus?.statusName}
                        </Tag>
                      ) : null}
                    </p>
                    <p className="p-0 m-0 text-gray-400 text-[0.7rem] text-end">
                      {new Intl.DateTimeFormat("vi-VN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(item?.modifiedDate))}{" "}
                      {new Intl.DateTimeFormat("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(item?.modifiedDate))}
                    </p>
                    <hr />
                  </>
                ) : null}
              </Menu.Item>
            );
          })
        ) : (
          <p className="text-center text-gray-400 italic">
            Không có thông báo nào!
          </p>
        )}
      </Menu>
    </>
  );

  return (
    <div className="relative">
      <Dropdown
        className="cursor-pointer hover:text-primary transition"
        overlay={menu}
        placement="bottomRight"
      >
        <Badge size="default" count={countView} color="#38a7ff">
          <BellOutlined className="text-[1.5rem]" />
        </Badge>
      </Dropdown>
    </div>
  );
};

export default Notify;
