import React from 'react';
import { Flex, Tag, Typography } from "antd";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const CountData = ({ loading, total, text }) => {
  return (
    <Flex align="center">
      <Typography.Text type="success">
        <CheckCircleOutlined />
        {` Tổng số ${text}: `}
        <Tag color="blue" className="inline-flex items-center justify-between gap-1">
          {loading ? <LoadingOutlined style={{ fontSize: 10 }} /> : (total || 0)} {text}
        </Tag>
      </Typography.Text>
    </Flex>
  );
};

export default CountData;