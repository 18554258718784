import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getListAreas, getListDepartment, getListGroups, getListSites, getListTeams } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore } from "@/utils/utils";

const initParams = {
  textSearch: '',
  pageIndex: 1,
  pageSize: 20,
  total: 0
};

const UserFilterForm = ({ formSearch, handleOnchangeSearch, loading, role }) => {
  const { Item } = Form;
  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);

  const [siteParams, setSiteParams] = useState(initParams);
  const [departmentParams, setDepartmentParams] = useState(initParams);
  const [areaParams, setAreaParams] = useState(initParams);
  const [teamParams, setTeamParams] = useState(initParams);
  const [groupParams, setGroupParams] = useState(initParams);
  const [params, setParams] = useState({
    textSearch: '',
    pageIndex: 1,
    pageSize: 20,
  });
  useEffect(() => {
    getListSites(siteParams, setSites, setSiteParams);
  }, [siteParams.pageSize, siteParams.textSearch]);

  useEffect(() => {
    getListDepartment(departmentParams, setDepartments, setDepartmentParams);
  }, [departmentParams.pageSize, departmentParams.textSearch]);

  useEffect(() => {
    if (params.siteId || params.departmentId) {
      getListAreas(areaParams, setAreas, setAreaParams);
    } else {
      setAreas([]);
    }
  }, [areaParams.pageSize, areaParams.textSearch, params]);

  useEffect(() => {
    if (params.areaId) {
      getListTeams(teamParams, setTeams, setTeamParams);
    } else {
      setTeams([]);
    }
  }, [teamParams.pageSize, teamParams.textSearch, params]);

  useEffect(() => {
    if (params.teamId) {
      getListGroups(groupParams, setGroups, setGroupParams);
    } else {
      setGroups([]);
    }

  }, [groupParams.pageSize, groupParams.textSearch, params]);

  const createSelectOptions = (data, valueKey, labelKey) =>
    data?.map(item => ({ value: item[valueKey], label: item[labelKey] }));

  const handleChangeSite = (value) => {
    setParams((prev) => ({
      ...prev,
      siteId: value,
      departmentId: null,
      areaId: null,
      teamId: null,
      groupId: null
    }));
    setTeams([]);
    setGroups([]);
    setAreaParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    formSearch.resetFields(["departmentId", "areaId", "teamId", "groupId"]);
  };

  const handleChangeDepartment = (value) => {
    setParams((prev) => ({
      ...prev,
      departmentId: value,
      areaId: null,
      teamId: null,
      groupId: null
    }));
    setAreaParams((prev) => ({
      ...prev,
      departmentId: value
    }));
    setTeams([]);
    setGroups([]);

    formSearch.resetFields(["areaId", "teamId", "groupId"]);
  };

  const handleChangeArea = (value) => {
    setParams((prev) => ({
      ...prev,
      areaId: value,
      teamId: null,
      groupId: null
    }));
    setTeamParams((prev) => ({
      ...prev,
      areaId: value
    }));
    setGroups([]);

    formSearch.resetFields(["teamId", "groupId"]);
  };

  const handleChangeTeam = (value) => {
    setParams((prev) => ({
      ...prev,
      teamId: value,
      groupId: null
    }));
    setGroupParams((prev) => ({
      ...prev,
      teamId: value
    }));

    formSearch.resetFields(["groupId"]);
  };

  return (
    <Form onFinish={(values) => {
      handleOnchangeSearch(values)
    }} form={formSearch} className="flex flex-wrap gap-2 items-center">
      <Item name="textSearch" className="w-[220px] !m-0">
        <Input
          placeholder="Tìm kiếm tài khoản"
          allowClear
          addonBefore={<SearchOutlined/>}
          // onChange={handleOnchangeSearch}
        />
      </Item>
      <Item name="siteId" className='w-[190px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Chọn trang"
          onSearch={(e) => handleSearchLoadMore(e, setSiteParams)}
          onClear={handleClearLoadMore(setSiteParams)}
          options={createSelectOptions(sites, "id", "siteName")}
          onChange={handleChangeSite}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
        />
      </Item>
      <Item name="departmentId" className='w-[190px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Chọn bộ phận"
          onSearch={(e) => handleSearchLoadMore(e, setDepartmentParams)}
          onClear={handleClearLoadMore(setDepartmentParams)}
          options={createSelectOptions(departments, "id", "departmentName")}
          onChange={handleChangeDepartment}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onPopupScroll={handleLoadMore(departmentParams, setDepartmentParams)}
        />
      </Item>
      <Item name="areaId" className='w-[190px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Chọn khu"
          onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
          onClear={handleClearLoadMore(setAreaParams)}
          options={createSelectOptions(areas, "id", "areaName")}
          onChange={handleChangeArea}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
        />
      </Item>
      <Item name="teamId" className='w-[190px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Chọn tổ"
          onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
          onClear={handleClearLoadMore(setTeamParams)}
          options={createSelectOptions(teams, "id", "teamName")}
          onChange={handleChangeTeam}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
        />
      </Item>
      <Item name="groupId" className='w-[190px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Nhóm hậu đài"
          onSearch={(e) => handleSearchLoadMore(e, setGroupParams)}
          onClear={handleClearLoadMore(setGroupParams)}
          options={createSelectOptions(groups, "id", "groupName")}
          onChange={(value) => setParams((prev) => ({ ...prev, groupId: value }))}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onPopupScroll={handleLoadMore(groupParams, setGroupParams)}
        />
      </Item>
      <Item name="roleId" className='w-[170px] !m-0'>
        <Select
          disabled={loading}
          allowClear
          showSearch
          placeholder="Chọn vai trò"
          options={createSelectOptions(role, "id", "roleName")}
          onChange={(value) => setParams((prev) => ({ ...prev, roleId: value }))}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Item>
      <Button type={"primary"} htmlType={"submit"}>
        Lọc
      </Button>
      <Button onClick={()=>{
        handleOnchangeSearch(initParams)
      }} type={"primary"} htmlType={"reset"}>
        Xóa
      </Button>
    </Form>
  );
};

export default UserFilterForm;