import React from "react";
import { Form, Input } from "antd";

const ProcessNoteForm = () => {
  return (
    <Form.Item
      name="processNote"
    >
      <Input.TextArea
        className="w-full"
        placeholder="Ghi chú"
      />
    </Form.Item>
  );
};

export default ProcessNoteForm;
