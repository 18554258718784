import React, { useEffect, useState } from "react";

import { Layout } from "antd";
import Header from "./header";
import Slider from "./sider";
import { Outlet } from "react-router-dom";
const { Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  const checkCollapsed = () => {
    if (window.innerWidth <= 1024) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkCollapsed);
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Slider collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout style={{ background: "#f7fafc" }}>
        <Header collapsed={collapsed} />
        <Content
          style={{
            padding: "0 50px",
            ...(!collapsed
              ? {
                  marginLeft: "240px",
                  transition: "margin 0.2s",
                }
              : {
                  marginLeft: "80px",
                  transition: "margin 0.2s",
                }),
          }}
        >
          <div className="mt-10 p-[24px] min-h-[80vh] bg-[#fff] shadow-2xl rounded-lg border border-solid border-[#e0e0e0]">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;
