import { Button, Form, Row } from "antd";
import React from "react";

const CompForm = ({
  form,
  onFinish,
  formItemsConfig,
  buttonOkModal,
  loading,
  children,
  activeBtnSub,
  className,
  ...props
}) => {
  return (
    <Form
      className={className}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      {children
        ? children
        : (formItemsConfig || []).map((item) => (
          <Form.Item className={item.className} label={item.label} name={item.name} rules={item.rules} tooltip={item.tooltip} hidden={item.hidden}>
            {item.component}
          </Form.Item>
          ))}
      {activeBtnSub && (
        <Row justify="end">
          <Button loading={loading} type="primary" htmlType="submit">
            {buttonOkModal}
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default CompForm;
