import axios from "axios";
import { API_URL } from "./config";
import {
  getAccessTokenBase,
  getRefreshTokenBase,
  setAccessTokenBase,
  setRefreshTokenBase,
} from "@/utils/common";
import { authRefreshToken } from "@/services/base";

const BEARER = "Bearer ";
const baseConfig = {
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const instanceBase = axios.create({
  baseURL: API_URL,
  ...baseConfig,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};

// Add a request interceptor
instanceBase.interceptors.request.use(
  function(config) {
    const accessToken = getAccessTokenBase();
    if (accessToken) {
      config.headers.Authorization = `${BEARER} ${accessToken}`;
    }
    return config;
  },
  function(error) {
    console.log("Request error: " + error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceBase.interceptors.response.use(
  function(response) {
    // const originalRequest = response.config;
    // const refreshToken = getRefreshTokenBase();
    // if (response.data && response.data.code === 401 && !originalRequest._retry) {
    //   if (!refreshToken) {
    //     localStorage.clear();
    //     window.location.href = "/login";
    //     return Promise.reject(response);
    //   }
    //
    //   if (isRefreshing) {
    //     return new Promise((resolve, reject) => {
    //       failedQueue.push({ resolve, reject });
    //     })
    //     .then((token) => {
    //       originalRequest.headers.Authorization = `Bearer ${token}`;
    //       return instanceBase(originalRequest);
    //     })
    //     .catch((err) => {
    //       return Promise.reject(err);
    //     });
    //   }
    //
    //   originalRequest._retry = true;
    //   isRefreshing = true;
    //
    //   return new Promise((resolve, reject) => {
    //     authRefreshToken({
    //       accessToken: getAccessTokenBase(),
    //       refreshToken: refreshToken,
    //     })
    //     .then((refreshResponse) => {
    //       setAccessTokenBase(refreshResponse.data.accessToken);
    //       setRefreshTokenBase(refreshResponse.data.refreshToken);
    //       instanceBase.defaults.headers.common["Authorization"] = `Bearer ${refreshResponse.data.accessToken}`;
    //       originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;
    //       processQueue(null, refreshResponse.data.accessToken);
    //       resolve(instanceBase(originalRequest));
    //     })
    //     .catch((refreshError) => {
    //       processQueue(refreshError, null);
    //       localStorage.clear();
    //       window.location.href = "/login";
    //       reject(refreshError);
    //     })
    //     .finally(() => {
    //       isRefreshing = false;
    //     });
    //   });
    // }
    if (response.data.code >= 400) {

      if (response.data.code === 404) {
        // window.location.href = "/404";
      } else if (response.data.code === 401 ) {
        localStorage.clear();
        window.location.href = "/login";
      } else if (response.data.code === 403) {
        window.location.href = "/forbidden";
      }
    }
    if (response.data.code >= 500) {
      window.location.href = "/server-errors";

    }
    return response.data ? response.data : response;
  },
  function(error) {
    console.log("Response error: " + error.message);
    return Promise.reject(error);
  }
);

class APIBase {
  get = async (url, config) => {
    return instanceBase.get(url, { ...config });
  };

  post = (url, data, config) => {
    return instanceBase.post(url, data, { ...config });
  };

  put = (url, data, config) => {
    return instanceBase.put(url, data, { ...config });
  };

  patch = (url, data, config) => {
    return instanceBase.patch(url, data, { ...config });
  };

  delete = (url, config) => {
    return instanceBase.delete(url, { ...config });
  };

  postFile = (url, data, config) => {
    return instanceBase.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...config,
    });
  };
}

const setAuthorizationBase = (token) => {
  instanceBase.defaults.headers.common["Authorization"] = token.replace(
    /"/g,
    ""
  );
};

export { APIBase, setAuthorizationBase };
