import { Button, Card, Form, Input, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { authSignIn } from "@/services/base";
import { useState } from "react";
import { routes, StatusResponse } from "@/utils/const";
import { setAccessTokenBase, setUserInfor } from "@/utils/common";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { infoUser } from "@/recoil/atoms";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setUserInfo = useSetRecoilState(infoUser);

  const onFinish = async (fieldValue) => {
    const {userName,password}=fieldValue;
    try {
      setLoading(true);
      const res = await authSignIn({userName:userName.trim(),password:password.trim()});

      if (res?.code !== StatusResponse.SUCCESS) {
        setLoading(false);
        message.error(res?.message);
        return;
      }

      if (res?.data) {
        message.success("Đăng nhập thành công!");
        setLoading(false);
        setAccessTokenBase(res?.data?.token);
        setUserInfor(res?.data?.data);
        setUserInfo(res?.data?.data);
        navigate(routes.home);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <main className="h-[100dvh] bg-background flex items-center justify-center bg-cover">
      <Card className="w-full md:w-2/3 xl:w-1/3 bg-white rounded-xl shadow-xl mx-2 md:mx-auto px-2">
        <h2 className="text-center text-3xl font-bold uppercase tracking-wide mb-0 text-gray-500">
          Đăng nhập
        </h2>
        <h2 className="text-center text-gray-500">
          Chào mừng đến với liên minh{" "}
          <span className="text-[#fd9900]">OKVIP</span> ! 👋
        </h2>

        <Form
          onFinish={onFinish}
          className="w-full xl:my-10 lg"
          layout="horizontal"
          size={"large"}
        >
          <Form.Item
            name="userName"
            rules={[{ required: true, message: "Vui lòng nhập tài khoản!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Tài khoản"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Mật khẩu"
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </main>
  );
}
