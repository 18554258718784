import React from 'react';
import { validateTotalAmount } from "@/utils/utils";
import { Form, Typography } from "antd";
import { formatCurrencyVND } from "@/utils/common";

const TotalAmountCreateForm = ({ groupSummary, totalAmount, isSpecial,specialApproval }) => {
  return (
    <Form.Item label="Tổng tiền" name="totalAmount" rules={[{
      validator: (_, value) => {
        const isValidated = validateTotalAmount(groupSummary, value, isSpecial,specialApproval);
        if (isValidated) {
          return Promise.reject(new Error(isValidated));
        }
        return Promise.resolve();
      },
    }]}
    >
      <Typography.Text>
        <b>{formatCurrencyVND(totalAmount || 0)}</b>
      </Typography.Text>
    </Form.Item>
  );
};

export default TotalAmountCreateForm;