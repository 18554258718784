import React from 'react';
import { Form, Row, Tooltip, Typography } from "antd";
import { formatCurrencyVND } from "@/utils/common";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { validateTotalAmount } from "@/utils/utils";
import FigureSiteInfor from "@/common/FigureSiteInfor";

const TooltipContent = ({ calculateAmountInUse, calculateAmount }) => (
  <>
    Số tiền NS đã sử dụng: {formatCurrencyVND(calculateAmountInUse || 0)}
    <br />
    Số tiền NS đã được duyệt: {formatCurrencyVND(calculateAmount || 0)}
  </>
);

const formValidation = (dataDetail, isIncreaseType, siteSummary,specialApproval) => {
  const validateTotalAmountRule = (_, value) => {
    const calculateAmount = dataDetail?.calAmountExpenseRequest?.calculateAmount;

    if (calculateAmount && value > calculateAmount) {
      return Promise.reject(new Error(`Tổng số tiền không được vượt quá ngân sách phê duyệt ${formatCurrencyVND(calculateAmount)}`));
    }

    if (isIncreaseType) {
      const isSpecial = dataDetail?.group?.isSupper;
      
      const isValidated = validateTotalAmount(siteSummary, value, isSpecial,specialApproval);
      if (isValidated) {
        return Promise.reject(new Error(isValidated));
      }
    }

    return Promise.resolve();
  };

  return { validateTotalAmountRule };
};

const TotalAmountForm = ({ dataDetail, totalAmount, isIncreaseType, groupSummary ,specialApproval}) => {
  const { validateTotalAmountRule } = formValidation(dataDetail, isIncreaseType, groupSummary,specialApproval);
  const isSpecialGroup = dataDetail?.group?.isSupper;

  return (
    <>
      <Form.Item
        label={
          <>
            Tổng tiền danh mục đề xuất
            <Tooltip overlayClassName="!min-w-[320px]" title={<TooltipContent {...dataDetail?.calAmountExpenseRequest} />}>
              <QuestionCircleOutlined className="ml-2" />
            </Tooltip>
          </>
        }
        name="totalAmount"
        rules={[{ validator: validateTotalAmountRule }]}
      >
        <Typography.Text>
          <b>{formatCurrencyVND(totalAmount || 0)}</b>
        </Typography.Text>
      </Form.Item>
      {isIncreaseType && (
        <FigureSiteInfor
          groupSummary={groupSummary}
          totalAmount={totalAmount}
          isSpecial={isSpecialGroup}
        />
      )}
    </>
  );
};

export default TotalAmountForm;