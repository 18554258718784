import React from 'react';
import { Form, Input, Modal, Select } from 'antd';

const ModalPending = ({ visible, steps, onOk, onCancel }) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onOk(values);
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  return (
    <Modal
      width="450px"
      title="Đợi giải quyết"
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Đồng ý"
      okType="primary"
      cancelText="Hủy"
      cancelButtonProps={{
        type: 'primary',
        danger: true
      }}
    >
      <Form form={form}>
        <p className="mt-4">Xác nhận chuyển ngân sách sang trạng thái đợi giải quyết?</p>
        <Form.Item
          name="step"
          rules={[{ required: true, message: 'Vui lòng chọn bước trong quy trình!' }]}
        >
          <Select
            className="w-full"
            placeholder="Chọn bước trong quy trình"
            options={steps.map(step => ({
              label: step.stepName,
              value: step.id
            }))}
          />
        </Form.Item>
        <Form.Item
          name="note"
          rules={[{ required: true, message: 'Vui lòng nhập ghi chú!' }]}
        >
          <Input.TextArea
            className="w-full"
            placeholder="Ghi chú"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalPending;