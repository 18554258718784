import React, { useState, useEffect, useRef } from 'react';
import { Form, Image, Upload, Input, Card, Row, Col, message, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { beforeUpload, getFileExtension, handlePreview, getBase64 } from "@/utils/utils";

const UploadImageForm = ({ form, listImage, handleChange }) => {
  const [fileList, setFileList] = useState(listImage || []);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const uploadInputRef = useRef(null);

  const dropOrPaste = async (files, isAsFile) => {
    const referFileList = uploadInputRef.current?.upload?.props?.fileList || [];

    for (let i = 0; i < files?.length; i++) {
      if (files[i]?.type?.indexOf('image') !== -1 || files[i]?.type?.indexOf('video') !== -1) {
        const file = isAsFile ? files[i]?.getAsFile() : files[i];
        
        if (!beforeUpload(file)) {
          const filePast = {
            uid: `rc-upload-${Date.now()}-2`,
            lastModified: file?.size,
            lastModifiedDate: file?.lastModifiedDate,
            name: file?.name,
            size: file?.size,
            type: file?.size,
            originFileObj: file,
            thumbUrl: await getBase64(file)
          }

          referFileList.push(filePast);          
        }
      }
    }

    updateFileList(referFileList);
  };

  const handlePaste = async (event) => {
    const items = event?.clipboardData?.items;
    
    dropOrPaste(items, true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    
    dropOrPaste(droppedFiles, false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    form.setFieldsValue({ url: fileList });
  }, [fileList, form]);

  const updateFileList = (newFileList) => {
    const formatTextToHTML = (text) => {
      let formattedText = text?.replace(/\n/g, '<br>');

      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|$!.,;]*[-A-Z0-9+&@#\/%=~_|$])/ig;
      formattedText = formattedText?.replace(urlPattern, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');

      return formattedText;
    };

    const dataFile = newFileList?.map(item => {
      const description = formatTextToHTML(item?.description);
      return {
        ...item,
        description
      }
    })

    setFileList(newFileList);
    form.setFieldsValue({ url: newFileList });
    handleChange({ fileList: dataFile });
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    updateFileList(newFileList);
  };

  const handleDescriptionChange = (targetFile, e) => {
    const newFileList = fileList.map(file =>
      file.uid === targetFile.uid ? { ...file, description: e.target.value } : file
    );
    updateFileList(newFileList);
  };

  const handleRemove = (fileToRemove) => {
    const newFileList = fileList.filter(file => file.uid !== fileToRemove.uid);
    updateFileList(newFileList);
  };

  const renderUploadButton = (
    <div>
      <PlusOutlined />
      <div className="mt-2">Upload</div>
    </div>
  );

  return (
    <Form.Item
      name="url"
      rules={[{ required: true, message: "Vui lòng chọn hình ảnh!" }]}
      label="Hình ảnh kèm theo"
    >
      <Flex className='flex-wrap'>
        <Upload
          className="mr-2 mb-2 w-auto"
          ref={uploadInputRef}
          listType="picture-card"
          fileList={fileList}
          onPreview={file => handlePreview(file, setPreviewImage, setPreviewOpen)}
          onChange={handleUploadChange}
          onRemove={handleRemove}
          beforeUpload={beforeUpload}
          accept="image/*,video/*"
        >
          {renderUploadButton}
        </Upload>
        <Col
          onPaste={handlePaste}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="rounded-lg bg-black/2 border border-dashed border-gray-300 hover:border-[#1677ff] bg-black/2 w-[102px] h-[102px] p-2 flex items-center justify-center text-center"
        >
          Dán hoặc kéo thả vào đây
        </Col>
      </Flex>
      {fileList?.map(file => {
        const fileExtension = getFileExtension(file?.name);
        const isVideo = fileExtension === "mp4" || fileExtension === "webm" || fileExtension === "ogg";
        return (
          <Card
            key={file.uid}
            style={{ marginTop: 16 }}
          >
            <Row gutter={16}>
              <Col span={isVideo ? 24 : 10}>
                {isVideo ? (
                  <video
                    key={file.uid}
                    controls
                    className="w-full h-[250px]"
                    autoPlay={true}
                  >
                    <source
                      src={URL.createObjectURL(file.originFileObj)}
                      type={`video/${fileExtension}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <Image
                    className="max-h-[250px] w-full"
                    src={URL.createObjectURL(file.originFileObj)}
                    alt={file.name}
                  />
                )}
              </Col>
              <Col span={isVideo ? 24 : 14}>
                <Input.TextArea
                  value={file.description || ''}
                  onChange={e => handleDescriptionChange(file, e)}
                  placeholder="Nhập mô tả"
                  rootClassName={isVideo ? "mt-2" : ""}
                />
              </Col>
            </Row>
          </Card>
        )
      })}
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: setPreviewOpen,
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </Form.Item>
  );
};

export default UploadImageForm;