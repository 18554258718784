import { signalRState } from "@/recoil/atoms";
import { useRecoilState } from "recoil";

const { URL_SIGNALR } = require("@/configs/config");
const { default: createSignalRConnection } = require("@/services/signalR");

const InitSignalR = () => {
  const [connectionSignalR, setConnectionSignalR] =
    useRecoilState(signalRState);

  const connect = async (hubUrl) => {
    if (!connectionSignalR) {
      const connection = createSignalRConnection(hubUrl);
      try {
        await connection.startConnection();
        setConnectionSignalR(connection);
        // console.log(`Connected to ${hubUrl}`);
      } catch (err) {
        console.error(`Error connecting to ${hubUrl}:`, err);
      }
    }
  };

  connect(URL_SIGNALR);
};
export default InitSignalR;
