import { Card } from "antd";
import React from "react";

const CardFormRequest = ({ title, children, ...props }) => {
  return (
    <Card
      title={title}
      className={`mb-4 overflow-auto ${props.className}`}
      styles={{
        body: { padding: 10 },
        header: {
          padding: 10,
          fontSize: 16,
          position: "sticky",
          top: 0,
          background: "#F5F5F5",
          zIndex: 1,
        },
        ...props.styles,
      }}
    >
      {children && children}
    </Card>
  );
};

export default CardFormRequest;
