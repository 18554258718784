import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import CardFormRequest from "@/common/FormCardRequest";
import FormDailyReportForm from "@/components/listRequset/FormDailyReportFormRequest";
import { useAudioRecorder } from "react-audio-voice-recorder";
import {
  createUpdatePurchaseRequest,
  deleteExpenseDetail,
  getSupervisorByProcessIdPaging,
  handleApproveExprense,
  postFile,
} from "@/services/base";
import { PROCESSING_EXPENSE_CODE, StatusResponse, TYPE_INCREASE } from "@/utils/const";
import { getListProcessTypes } from "@/utils/fetchData";
import { useLocation } from "react-router";
import SupervisorInfoForm from "@/components/form/SupervisorInfoForm";
import UploadVoiceForm from "@/components/form/UploadVoiceForm";
import LastReportForm from "@/components/form/LastReportForm";
import SpecialApprovalForm from "@/components/form/SpecialApprovalForm";
import BankInfoForm from "@/components/form/BankInfoForm";
import UploadImageForm from "@/components/form/UploadImageForm";
import AddCategoryForm from "@/components/form/AddCategoryForm";
import CategoryForm from "@/components/form/CategoryForm";
import TotalAmountForm from "@/components/form/TotalAmountForm";
import ProcessNoteForm from "@/components/form/ProcessNoteForm";
import { useRecoilState } from "recoil";
import { paramsSuperivorState } from "@/recoil/atoms";

const ApproveModalForm = ({
  dataDetail,
  groupSummary,
  handleReload,
  handleCancel,
  approveModal,
  listProducts,
  IncreaseDecreaseType
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [voice, setVoice] = useState(null);
  const [totalAmount, setTotalAmount] = useState(dataDetail?.totalAmount || 0);
  const [listImage, setListImage] = useState([]);
  const [processTypes, setProcessTypes] = useState([]);
  const [dataSupervior, setDataSupervior] = useState([]);
  const [paramsSuperivor, setParamsSupervior] = useRecoilState(paramsSuperivorState);
  const [isApproveSpecial, setIsApproveSpecial] = useState(false);


  let location = useLocation();
  const isDailyReportPage = location.pathname.split("/")[1] === "daily-report";
  const isCloseRequestPage =
    location.pathname.split("/")[1] === "close-request";
  const isIncreaseType = IncreaseDecreaseType === TYPE_INCREASE;

  useEffect(() => {
    getListProcessTypes(
      {
        expenseTypeId: PROCESSING_EXPENSE_CODE.Proposal,
        purposeId: dataDetail?.purpose?.id,
      },
      setProcessTypes
    );
  }, []);

  const fetchSuperviorProcessStep = async (data) => {
    try {
      const res = await getSupervisorByProcessIdPaging({
        ...data,
        processStepId: dataDetail?.processStep?.id || data?.processStepId,
        groupId: dataDetail?.groupId,
      });

      if (res.code !== StatusResponse.SUCCESS)
        return message.error(res.message);
      setDataSupervior(res.data);
      setParamsSupervior((prev) => {
        return {
          pageIndex: res.pagination.pageIndex,
          pageSize: res.pagination.pageSize,
          total: res.pagination.total,
          processStepId: dataDetail?.processStep?.id || data.processStepId,
          textSearch: data?.textSearch || "",
          groupId: dataDetail?.group?.id || data?.groupId
        };
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...dataDetail });
    setIsApproveSpecial(dataDetail?.isSpecialApprove)
  }, []);

  useEffect(() => {
    form.setFieldsValue({ totalAmount: totalAmount });
  }, [totalAmount, form]);

  useEffect(() => {
    if (dataDetail?.processStep?.id) {
      fetchSuperviorProcessStep(paramsSuperivor);
    }
  }, [dataDetail?.processStep?.id]);

  const onFinish = async (value) => {
    setLoading(true);

    if (dataDetail?.processStep?.isUploadVoice && !voice) {
      message.error("Vui lòng cung cấp xác nhận bằng giọng nói");
      setLoading(false);
      return;
    }

    const buildExpenseRequest = (dataDetail, value) => ({
      id: dataDetail?.id,
      refId: dataDetail.refId,
      processTypeId: dataDetail?.processTypeId,
      supervisorId: value.supervisorId
        ? value.supervisorId
        : dataDetail?.supervisorId,
      assignTo: dataDetail?.assignTo,
      expenseTypeCode: dataDetail?.expenseType?.code,
      departmentId: dataDetail.departmentId,
      siteId: dataDetail.siteId,
      areaId: dataDetail.areaId,
      teamId: dataDetail.teamId,
      groupId: dataDetail?.groupId,
      startDate: dataDetail.startDate,
      endDate: dataDetail.endDate,
      purposeId: dataDetail.purposeId,
      totalAmount: value.totalAmount
        ? value.totalAmount
        : dataDetail.totalAmount,
      note: dataDetail.note,
      bankName: value.bankName ? value.bankName : dataDetail.bankName,
      accountName: value.accountName ? value.accountName : dataDetail.accountName,
      numberAccount: value.numberAccount ? value.numberAccount : dataDetail.numberAccount,
      bankNote: value.bankNote ? value.bankNote : dataDetail.bankNote,
      isLastReport: value.isLastReport ?? dataDetail.isLastReport,
      isSupportPackage: value.isSupportPackage ?? dataDetail.isSupportPackage,
      isSpecialApprove: value?.isSpecialApprove ?? dataDetail?.isSpecialApprove,
    });

    const buildMessInfor = (dataDetail, value) =>
      dataDetail?.processStep?.isMessInfor
        ? {
          expenseId: dataDetail.id,
          quantityExistAccount: value.quantityExistAccount,
          quantityResponse: value.quantityResponse,
          quantityIncome: value.quantityIncome,
          quantityClosing: value.quantityClosing,
          quantityReference: value.quantityReference,
          quantityTemp: value.quantityTemp,
        }
        : null;

    const buildExpenseDetailAddOrEdit = async (dataDetail, value) => {
      if (!dataDetail?.processStep?.isAmount) {
        return null;
      }
      const expenseIdList = dataDetail?.expenseDetails?.map((rc) => rc.id);
      const deleteExitsExpense = expenseIdList.map((id) =>
        deleteExpenseDetail(id)
      );
      await Promise.all(deleteExitsExpense);

      const expenseDetailAdd = [];

      value.expenseDetails.forEach((expenseDetail) => {
        expenseDetailAdd.push({
          expenseId: expenseDetail.expenseId || 0,
          categoryId: expenseDetail.categoryId || 0,
          amount: expenseDetail.amount || 0,
          amountCamp: expenseDetail.amountCamp || 0,
          amountAdditional: expenseDetail.amountAdditional || 0,
        });
      });

      return {
        expenseDetailAdd:
          expenseDetailAdd.length > 0 ? expenseDetailAdd : undefined,
      };
    };

    const buildAttachFiles = async (listImage, expenseId) => {
      const uploadedFiles = await Promise.all(
        listImage.map((image) => {
          let dataFile = {
            file: image.originFileObj,
          };
          return postFile(dataFile).then((result) => {
            return { ...result, description: image.description };
          });
        })
      );
      return uploadedFiles.map((file) => ({
        expenseId: expenseId,
        filePath: file.data.filePath,
        fileType: file.data.fileType,
        description: file?.description,
      }));
    };

    const buildAttachUploadVoice = async (voice, expenseId) => {
      if (!voice) return null;
      try {
        const res = await postFile({
          file: voice,
          fileType: "video/webm",
        });
        return {
          expenseId: expenseId,
          filePath: res?.data?.filePath,
          fileType: res?.data?.fileType,
          isVoice: true,
        };
      } catch (err) {
        console.log("Error uploadFile", err);
        return null;
      }
    };

    try {
      const expenseRequest = buildExpenseRequest(dataDetail, value);
      const messInfor = buildMessInfor(dataDetail, value);
      const attachFiles = await buildAttachFiles(listImage, dataDetail.id);
      const attachUploadVoice = await buildAttachUploadVoice(
        voice,
        dataDetail.id
      );
      const expenseDetailAddOrEdit = await buildExpenseDetailAddOrEdit(
        dataDetail,
        value
      );

      const data = {
        expenseRequest,
        messInfor,
        expenseDetailAddOrEdit,
        attachFiles: attachUploadVoice
          ? [...attachFiles, attachUploadVoice]
          : attachFiles,
      };

      const res = await createUpdatePurchaseRequest(data);

      if (res.isError) {
        message.error(res.message);
        setLoading(false);
        return;
      }

      const res1 = await handleApproveExprense({ expenseRequestId: dataDetail.id, processNote: value?.processNote });

      if (res1.isError) {
        message.error(res1.message);
        setLoading(false);
        return;
      }

      message.success("Phê duyệt thành công!");
      handleCancel();
      handleReload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAmountChange = (changedValues, allValues) => {
    const { expenseDetails } = allValues;
    let total = 0;
    let hasChangedAmountField = false;
    let amountExpenseDetails = expenseDetails;

    // Auto fill the amount field
    if (changedValues?.expenseDetails) {
      changedValues?.expenseDetails?.forEach(expenseAmount => {
        if (expenseAmount.hasOwnProperty('amountCamp') || expenseAmount.hasOwnProperty('amountAdditional')) {
          hasChangedAmountField = true;
        }
      });

      if (hasChangedAmountField) {
        const newExpenseDetails = expenseDetails.map((item) => {
          return {
            ...item,
            amount:
              parseFloat(item?.amountCamp || 0) +
              parseFloat(item?.amountAdditional || 0) +
              parseFloat(item?.amountInUse || 0),
          };
        });

        form.setFieldsValue({
          expenseDetails: newExpenseDetails,
        });

        amountExpenseDetails = newExpenseDetails;
      }
    }

    amountExpenseDetails?.forEach((item) => {
      total += parseFloat(item?.amount || 0);
    });
    setTotalAmount(total);
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const handleChange = (file) => {
    setListImage(file.fileList);
  };

  const calAmountExpenseDetail = dataDetail?.calAmountExpenseDetail?.map(
    (item) => {
      return {
        id: item.id,
        categoryName: item.categoryName,
        categoryId: item.categoryId,
        amount: isCloseRequestPage ? item.amount - item.amountInUse : null,
        amountInUse: item.amountInUse
      };
    }
  );

  useEffect(() => {
    if (isDailyReportPage || isCloseRequestPage) {
      const messInfor = dataDetail?.messInfor ?? {};      

      form.setFieldsValue({
        expenseDetails: calAmountExpenseDetail,
        quantityClosing: messInfor?.quantityClosing,
        quantityExistAccount: messInfor?.quantityExistAccount,
        quantityIncome: messInfor?.quantityIncome,
        quantityReference: messInfor?.quantityReference,
        quantityResponse: messInfor?.quantityResponse,
        quantityTemp: messInfor?.quantityTemp
      });
    }
    if (isCloseRequestPage) {
      setTotalAmount(
        dataDetail?.calAmountExpenseRequest?.calculateAmount -
        dataDetail?.calAmountExpenseRequest?.calculateAmountInUse
      );
    }
  }, [approveModal.isOpen]);

  return (
    <Modal
      title={`${
        isDailyReportPage ? "PHÊ DUYỆT BÁO CÁO" : "PHÊ DUYỆT NGÂN SÁCH"
      } `}
      open={approveModal.isOpen}
      width="700px"
      onCancel={handleCancel}
      footer={null}
    >
      <Divider/>
      <div className="mb-4 modal-body">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          onCancel={handleCancel}
          onValuesChange={handleAmountChange}
          initialValues={{
            expenseDetails: [{ categoryId: undefined, amount: "" }],
          }}
        >
          {dataDetail?.processStep?.isAmount && (
            <CardFormRequest title="Thông tin những danh mục đề xuất">
              {!isDailyReportPage && !isCloseRequestPage
                ? <AddCategoryForm listProducts={listProducts}/>
                : <CategoryForm dataDetail={dataDetail} isCloseRequestPage={isCloseRequestPage}
                                isDailyReportPage={isDailyReportPage}/>
              }
              <TotalAmountForm
                dataDetail={dataDetail}
                totalAmount={totalAmount}
                isIncreaseType={isIncreaseType}
                groupSummary={groupSummary}
                specialApproval={isApproveSpecial}
              />
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isHandleOverExpense && (
            <CardFormRequest title="Xử lý vượt ngân sách">
              <Row className="mt-5">
                <span>
                  Nếu số tiền vượt quá ngân sách khả dụng, vui lòng tạo&nbsp;
                  <a
                    href
                    onClick={() => window.open(
                      `/expense-request/${dataDetail?.refId}?increaseBudgetForm=true`,
                      '_blank',
                      'noopener,noreferrer'
                    )}
                  >
                    đề xuất tăng ngân sách
                  </a>!
                </span>
              </Row>
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.supervisorDepartmentId && (
            <CardFormRequest title="Thông tin người phụ trách camp">
              <SupervisorInfoForm dataSupervior={dataSupervior}/>
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isUploadBill && (
            <CardFormRequest title="Thông tin hình ảnh">
              <UploadImageForm listImage={listImage} form={form} handleChange={handleChange}/>
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isUploadVoice && (
            <CardFormRequest title="Xác nhận bằng giọng nói">
              <UploadVoiceForm
                recorderControls={recorderControls}
                setVoice={setVoice}
              />
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isLastReport && (
            <CardFormRequest title="Báo cáo cuối cùng">
              <LastReportForm/>
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isSpecialApprove && (
            <CardFormRequest title="Phê duyệt đặc biệt">
              <SpecialApprovalForm setIsApproveSpecial={setIsApproveSpecial}/>
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isBankInfor && (
            <CardFormRequest title="Thông tin ngân hàng">
              <BankInfoForm/>
            </CardFormRequest>
          )}
          {dataDetail?.processStep?.isMessInfor && (
            <CardFormRequest title="Báo cáo chi tiêu">
              <FormDailyReportForm
                dataDetail={dataDetail}
                form={form}
                dataSupervior={dataSupervior}
                processTypes={processTypes}
              />
            </CardFormRequest>
          )}
          <CardFormRequest title="Ghi chú thêm thông tin">
            <ProcessNoteForm />
          </CardFormRequest>
          
          <Row>
            <Col span={24}>
              <Typography.Text>
                {isDailyReportPage
                  ? "Đồng ý xác nhận phê duyệt báo cáo ?"
                  : "Đồng ý xác nhận phê duyệt ngân sách ?"}{" "}
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="end" className="flex gap-4 mt-4">
            <Button type="primary" danger onClick={handleCancel}>
              Hủy bỏ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Phê duyệt
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ApproveModalForm;
