import TagStatus from "@/components/listRequset/TagStatus";
import { formatCurrencyVND } from "@/utils/common";
import { Divider } from "antd";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useState,
} from "react";
import { ROLES } from "@/utils/const";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { useNavigate, useParams } from "react-router";
import {
  getListDecreaseRequestAdj,
} from "@/services/base";
import DataTable from "@/common/DataTable";
import FormSearch from "@/common/FormSearch";
import FormRequest from "@/components/listRequset/FormRequest";
import { getExpenseTypeList, getListDepartment, getListProcessStatus, getListSites } from "@/utils/fetchData";
import { goToPage } from "@/utils/utils";
import CountData from "@/common/CountData";

const DecreaseRequest = () => {
  document.title = "Quản lý đề xuất giảm";
  const infoUserState = useRecoilValue(infoUser);
  const [listProducts, setListProducts] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [listProcessStatus, setlistProcessStatus] = useState([]);
  const [expenseTypeList, setExpenseTypeList] = useState([]);
  const [listProcess, setlistProcess] = useState([]);
  const [listSite, setListSite] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [loadingForm, setLoadingForm] = useState(false);

  const userRoleCode = infoUserState?.role?.roleCode;
  const disableFilterRole =
    userRoleCode === ROLES.MEMBER ||
    userRoleCode === ROLES.TT ||
    userRoleCode === ROLES.TL ||
    userRoleCode === ROLES.TLB ||
    userRoleCode === ROLES.HLV;

  const [paginationDepartment, setPaginationDepartment] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 5,
    pages: 1,
  });

  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [dataRequestAdj, setDataRequestAdj] = useState([]);

  useEffect(() => {
    getExpenseTypeList(setExpenseTypeList);
    getListDepartment(paginationDepartment, setListDepartment, setPaginationDepartment);
    getListSites({}, setListSite);
    getListProcessStatus(setlistProcessStatus);
  }, []);

  const fetchDataListExpenseAdj = async (params) => {
    try {
      setLoading(true);
      const res = await getListDecreaseRequestAdj(params);
      if (res.code === 200 && res.error === 0) {
        setDataRequestAdj(res.data.map((u) => ({ ...u, key: u.id })));
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataListExpenseAdj(searchParams);
  }, []);

  const renderStatusCol = (value) => {
    return <TagStatus record={value}/>;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      width: 85,
      render: (id, record) => (
        <a
          href
          onClick={() => goToPage(id, "DXG", navigate, true)}
        >
          {`${record.expenseType.shortName}${id}`}
        </a>
      ),
    },
    {
      key: "refId",
      title: "Mã ngân sách",
      dataIndex: "refId",
      width: 85,
      render: (redId) => (
        <a
          href
          onClick={() => goToPage(redId, "NS", navigate, true)}
        >
          {`NS${redId}`}
        </a>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Trang",
      dataIndex: "site",
      key: "site",
      render: (value) => (value ? value.siteName : "--"),
    },
    {
      title: "Tổ làm việc",
      dataIndex: "team",
      key: "team",
      render: (value) => (value ? value.teamName : "--"),
    },
    {
      title: "Nhóm hậu đài",
      dataIndex: "group",
      key: "group",
      render: (value) => (value ? value.groupName : "--"),
    },
    {
      title: "Thời gian bắt đầu sử dụng ngân sách",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
      render: (value) => moment(value + "z").format("DD/MM/YYYY"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          width: 200,
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          width: 200,
          render: (value) => (value?.stepName ? value?.stepName : "--"),
        },
      ]
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      render: (_, rec) => renderStatusCol(rec),
    },
    {
      title: "Người tạo",
      dataIndex: "userCreate",
      key: "userCreate",
      render: (value) => (value ? value.fullName : "--"),
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "userApprove",
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    },
  ];

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
    fetchDataListExpenseAdj({
      pageIndex,
      pageSize,
      expenseId: searchParams.expenseId,
      textSearch: searchParams.textSearch,
    });
  };

  useEffect(() => {
    fetchDataListExpenseAdj(searchParams);
  }, [searchParams]);

  const handlechangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields, pageIndex: 1 };

      setSearchParam(newParams);
    },
    [searchParams]
  );

  const handleClose = () => {
    setIsModal(false);
    setDataEdit(null);
  };

  return (
    <>
      <section className="flex justify-between mb-4">
        <FormSearch
          listExpenseType={expenseTypeList}
          listProcess={listProcess}
          listProcessStatus={listProcessStatus}
          searchParams={searchParams}
          listSite={listSite}
          listDepartment={listDepartment}
          disableFilterRole={disableFilterRole}
          userRoleCode={userRoleCode}
          onchangeSearch={handlechangeSearch}
          setSearchParam={setSearchParam}
        />
      </section>
      <CountData loading={loading} total={totalPage} text="đề xuất giảm"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={dataRequestAdj || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loading}
        scroll={{ x: "max-content", y: 500 }}
        isBorder
        onPageChange={onchangePage}
      />
      {isModal && (
        <FormRequest
          isModalOpen={isModal}
          handleCancel={handleClose}
          dataEdit={dataEdit}
          listDepartment={listDepartment}
          listSite={listSite}
          listProducts={listProducts}
          loadingForm={loadingForm}
          setLoadingForm={setLoadingForm}
          handleReload={() => fetchDataListExpenseAdj(searchParams)}
        />
      )}
    </>
  );
};

export default DecreaseRequest;
