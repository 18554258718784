import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import { CHECK_PROCESSING_EXPENSE, ROLES, StatusResponse } from "@/utils/const";
import { useRecoilState, useRecoilValue } from "recoil";
import { infoUser, paramsSuperivorState } from "@/recoil/atoms";
import moment from "moment/moment";
import {
  getInitProcessingStep,
  getPagingAreas,
  getPagingUsers,
  getSupervisorByProcessIdPaging,
} from "@/services/base";
import qs from "query-string";
import { getListDepartment, getListGroups, getListSites, getListTeams, getListProcessTypes } from "@/utils/fetchData";
import { handleLoadMore } from "@/utils/utils";

const supportPackages = [{ id: 1, value: true, name: "Có" }, { id: 2, value: false, name: "Không" }];

const InfoGeneralForm = ({
  modalState,
  processTypeId,
  totalAmount,
  processInit,
  handleDataSuperviorChange,
  handleProcessTypeIdChange,
  handleProcessInitChange,
  handleSelectedGroupChange,
  selectedGroupId,
  form,
  listPurpose
}) => {
  const { typeForm } = modalState;
  const infoUserState = useRecoilValue(infoUser);
  const [listUser, setListUser] = useState([]);
  const [totalListUser, setTotalListUser] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(infoUserState?.departmentId);
  const [selectedTeamId, setSelectedTeamId] = useState(infoUserState?.teamId);
  const [selectedAreaId, setSelectedAreaId] = useState(infoUserState?.areaId);
  const [selectedSiteId, setSelectedSiteId] = useState(infoUserState?.siteId);
  const [selectedPurposeId, setSelectedPurposeId] = useState(null);
  const [listArea, setListArea] = useState([]);
  const [listTeam, setListTeam] = useState([]);
  const [listDepartment, setListDepartment] = useState([])
  const [processTypes, setProcessTypes] = useState([]);
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState([]);
  const [value, setValue] = useState([]);
  const [listGroups, setListGroups] = useState([])
  const [paginationGroup, setPaginationGroup] = useState({
    pageIndex: 1,
    pageSize: 10,
  })
  const [listSite, setListSite] = useState([])
  const [paramsListSite, setParamsListSite] = useState({
    pageIndex: 1,
    pageSize: 10,
    total: 0
  });
  const [paramsListUser, setParamsListUser] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const [paramsSuperivor, setParamsSupervior] = useRecoilState(paramsSuperivorState);

  const isTT = infoUserState?.role?.roleCode === ROLES.TT;
  const isMEMBER = infoUserState?.role?.roleCode === ROLES.MEMBER;

  useEffect(() => {
    getListDepartment({ pageIndex: 1, pageSize: 99 }, setListDepartment)
  }, []);

  useEffect(() => {
    if (!selectedPurposeId) {{
      return;
    }}

    const body = {
      expenseTypeId: CHECK_PROCESSING_EXPENSE(typeForm),
      purposeId: selectedPurposeId
    }

    getListProcessTypes(body, setProcessTypes);
  }, [selectedPurposeId]);

  useEffect(() => {
    getListSites(paramsListSite, setListSite, setParamsListSite);
  }, [paramsListSite.pageSize]);


  useEffect(() => {
    if (processInit?.id) {
      fetchSuperviorProcessStep({
        ...paramsSuperivor,
      });
    }
  }, [processInit?.id, selectedGroupId]);

  useEffect(() => {
    if (selectedSiteId) {
      const params = qs.stringify({
        pageSize: 99,
        siteId: selectedSiteId,
      });
      getListArea(params);
    }
  }, [selectedSiteId]);

  useEffect(() => {
    if (selectedAreaId && selectedDepartment) {
      getListTeams({ pageSize: 99, areaId: selectedAreaId, departmentId: selectedDepartment }, setListTeam)
    }
  }, [selectedAreaId, selectedDepartment]);

  useEffect(() => {
    if (selectedDepartment && selectedSiteId && selectedAreaId && selectedTeamId && selectedGroupId) {
      fetchListUser(paramsListUser);
    }
  }, [paramsListUser.pageSize, selectedDepartment, selectedSiteId, selectedAreaId, selectedTeamId, selectedGroupId]);

  useEffect(() => {
    if (selectedDepartment && selectedSiteId && selectedAreaId && selectedTeamId) {
      getListGroups({
        ...paginationGroup,
        siteId: selectedSiteId,
        departmentId: selectedDepartment,
        areaId: selectedAreaId,
        teamId: selectedTeamId,
      }, setListGroups, setPaginationGroup);
    }
  }, [selectedDepartment, selectedSiteId, selectedAreaId, selectedTeamId, selectedGroupId]);

  useEffect(() => {
    form.setFieldsValue({ totalAmount: totalAmount });
  }, [totalAmount, form]);

  // const fetchInitProcess = async () => {
  //   const resProcessType = await getInitProcessingStep(processTypeId);
  //   // const processTeamId = resProcessType?.data?.departmentId
  //   // const newListTeam = listTeam?.filter((team) => team?.id === processTeamId);
  //   // setListTeam(newListTeam);
  //   handleProcessInitChange(resProcessType?.data);
  // };

  const fetchSuperviorProcessStep = async (data) => {
    try {
      const res = await getSupervisorByProcessIdPaging({
        ...data,
        processStepId: processInit?.id || data?.processStepId,
        groupId: infoUserState?.group?.id || selectedGroupId,
      });

      if (res.code !== StatusResponse.SUCCESS) return message.error(res.message);

      handleDataSuperviorChange(res.data);
      setParamsSupervior((prev) => {
        return {
          ...prev,
          processStepId: processInit?.id || data.processStepId,
          groupId: infoUserState?.group?.id || selectedGroupId,
        }
      });
    } catch (error) {
      console.error("Error fetching supervisor process step:", error);
    }
  };

  const fetchListUser = async (data) => {
    const body = {
      ...data,
      departmentId: selectedDepartment || infoUserState?.departmentId,
      siteId: selectedSiteId || infoUserState?.siteId,
      areaId: selectedAreaId || infoUserState?.areaId,
      teamId: selectedTeamId || infoUserState?.teamId,
      groupId: selectedGroupId || infoUserState?.groupId
    };
    try {
      const res = await getPagingUsers(qs.stringify(body));
      if (!res.isError) {
        setTotalListUser(res?.pagination?.total);
        setListUser(res.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getListArea = async (params) => {
    try {
      const res = await getPagingAreas(params);
      if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
      setListArea(res?.data);
    } catch (err) {
      console.error("Error fetching areas:", err);
    }
  };

  const handleLoadMoreListUser = async (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && totalListUser > listUser.length) {
      setParamsListUser((prev) => ({ ...prev, pageSize: prev.pageSize + 10 }));
    }
  };

  const handleSiteChange = (value) => {
    setSelectedSiteId(value === undefined ? null : value)
    form.setFieldsValue({ areaId: null, departmentId: null, teamId: null, assignTo: null, groupId: null });
  };

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    setParamsListUser((prev) => ({ ...prev, departmentId: value }));
  };

  const handleAreaChange = (value) => {
    setSelectedAreaId(value);
    form.setFieldsValue({ teamId: null, groupId: null });
    setParamsListUser((prev) => ({ ...prev, areaId: value }));
  };

  const handleTeamChange = (value) => {
    setSelectedTeamId(value === undefined ? null : value);
    form.setFieldsValue({ groupId: null, assignTo: null })
    // setParamsSupervior((prev) => ({ ...prev,teamId: value }));
    setParamsListUser((prev) => ({ ...prev, teamId: value }));
  };

  const handleGroupChange = (value, group) => {
    handleSelectedGroupChange(group);
    form.setFieldsValue({ assignTo: null });
    setParamsSupervior((prev) => ({ ...prev, groupId: value }));
    setParamsListUser((prev) => ({ ...prev, groupId: value }));
  };

  const onOpenChange = (open) => {
    setHackValue(open ? [] : undefined);
  };

  const handleCalendarChange = (val) => {
    setDates(val);
  };

  const handleChangeD = (val) => {
    setValue(val);
  };

  const disabledDate = (current) => {
    const currentDate = moment().startOf("day");
    const [startDate] = dates;

    if (current && current < currentDate) {
      return true;
    }

    return !!(startDate && !current?.isSame(startDate, "month"));
  };

  const handleChangePurpose = (id) => {
    setSelectedPurposeId(id);
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={8}>
        <Form.Item
          label="Loại hình quảng cáo"
          name="purposeId"
          rules={[{
            required: true, message: "Vui lòng chọn loại hình quảng cáo!",
          },]}
        >
          <Select
            allowClear
            placeholder="Chọn loại hình quảng cáo"
            onChange={handleChangePurpose}
          >
            {listPurpose.map((item) => (<Select.Option key={item.id} value={item.id}>
              {item.purposeName}
            </Select.Option>))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Chọn quy trình"
          name="processTypeId"
          rules={[{ required: true, message: "Vui lòng chọn quy trình!" }]}
        >
          <Select
            allowClear
            placeholder="Chọn quy trình"
            onChange={(e) => {
              if (e === undefined) {
                handleDataSuperviorChange([]);
                handleProcessTypeIdChange(e);
                handleProcessInitChange(null);
                return;
              }
              handleProcessTypeIdChange(e);
            }}

            options={processTypes?.map(process => ({
              value: process.id, label: process.name,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Chọn trang" name="siteId" rules={[{
          required: true, message: "Vui lòng chọn trang",
        }]}
        >
          <Select
            allowClear
            showSearch
            onChange={handleSiteChange}
            defaultValue={infoUserState?.site?.id}
            disabled={!!infoUserState?.site?.id}
            placeholder="Chọn trang"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            onPopupScroll={handleLoadMore(paramsListSite, setParamsListSite)}
            options={listSite?.map(site => ({
              label: site.siteName,
              value: site.id
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Chọn bộ phận"
          name="departmentId"
          rules={[{
            required: true, message: "Vui lòng chọn bộ phận",
          }, {
            validator: (_, value) => {
              if (processInit && value && processInit?.teamId && processInit?.teamId !== value) {
                return Promise.reject(new Error("Không thể chọn bộ phận khác bộ phận xử lý của quy trình."));
              }
              return Promise.resolve();
            },
          },]}
        >
          <Select
            allowClear
            showSearch
            onChange={handleDepartmentChange}
            defaultValue={infoUserState?.departmentId}
            disabled={!!infoUserState?.departmentId}
            placeholder="Chọn bộ phận"
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
          >
            {
              listDepartment?.filter(item => item?.departmentCode === "Sale").map((depart) => {
                return (
                  <Select.Option key={depart.id} value={depart.id}>
                    {depart?.departmentName}
                  </Select.Option>
                );
              })
            }
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Chọn khu"
          name="areaId"
          rules={[{
            required: true, message: "Vui lòng chọn khu",
          },]}
        >
          <Select
            allowClear
            showSearch
            onChange={handleAreaChange}
            defaultValue={infoUserState?.areaId}
            disabled={!!infoUserState?.areaId}
            placeholder="Chọn khu"
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
          >
            {listArea?.map((area) => {
              return (<Select.Option key={area.id} value={area.id}>
                {area.areaName}
              </Select.Option>);
            })
            }
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Chọn tổ làm việc"
          name="teamId"
          rules={[{
            required: true, message: "Vui lòng chọn tổ làm việc",
          }]}
        >
          <Select
            allowClear
            showSearch
            onChange={handleTeamChange}
            defaultValue={infoUserState?.teamId}
            disabled={!!infoUserState?.teamId}
            placeholder="Chọn tổ làm việc"
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
          >
            {listTeam.map((team) => {
              return (<Select.Option key={team.id} value={team.id}>
                {team?.teamName}
              </Select.Option>)
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Chọn nhóm hậu đài"
          name="groupId"
          rules={[{
            required: true, message: "Vui lòng chọn nhóm hậu đài!!",
          }]}
        >
          <Select
            allowClear
            disabled={!isTT && (!!infoUserState?.groupId || isMEMBER)}
            showSearch
            onPopupScroll={handleLoadMore(paginationGroup, setPaginationGroup)}
            onChange={handleGroupChange}
            defaultValue={infoUserState?.groupId}
            placeholder="Chọn nhóm hậu đài"
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
          >
            {listGroups.map((group) => {
              return (
                <Select.Option
                  key={group.id}
                  value={group.id}
                  isSupper={group.isSupper}
                >
                  {group?.groupName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label="Người được giao"
          name="assignTo"
          tooltip="Người sử dụng ngân sách"
          rules={[{
            required: true, message: "Vui lòng chọn người được giao!",
          }]}
        >
          <Select
            allowClear
            showSearch
            onPopupScroll={handleLoadMoreListUser}
            placeholder="Chọn người được giao"
            disabled={infoUserState.role.roleCode === ROLES.MEMBER}
            filterOption={(input, option) => option?.children?.toLowerCase().includes(input?.toLowerCase())}
          >
            {infoUserState.role.roleCode === ROLES.MEMBER ? (<Select.Option
              key={infoUserState?.id}
              value={infoUserState?.id}
            >
              {infoUserState?.userName}
            </Select.Option>) : (listUser?.map((user) => {
              return (<Select.Option
                key={user?.id}
                value={user?.id}
              >
                {user?.userName}
              </Select.Option>);
            }))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label="Ngân sách hỗ trợ"
          name="isSupportPackage"
          rules={[{
            required: true, message: "Vui lòng chọn ngân sách hỗ trợ!",
          },]}
        >
          <Select
            allowClear
            placeholder="Chọn ngân sách hỗ trợ"
          >
            {supportPackages.map((item) => (<Select.Option key={item.id} value={item.value}>
              {item.name}
            </Select.Option>))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label="Thời gian sử dụng ngân sách"
          name="timeUseBudget"
          rules={[{
            required: true, message: "Vui lòng chọn thời gian sử dụng ngân sách!",
          },]}
        >
          <DatePicker.RangePicker
            value={hackValue || value}
            placeholder={["Ngày Bắt Đầu", "Ngày Kết Thúc"]}
            format={"DD/MM/YYYY"}
            allowClear
            size="medium"
            className="w-full"
            disabledDate={disabledDate}
            onCalendarChange={handleCalendarChange}
            onChange={handleChangeD}
            onOpenChange={onOpenChange}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Mô tả"
          name="note"
          rules={[{
            required: true, message: "Vui lòng Nhập mô tả!",
          }, {
            max: 4000, message: "Số lượng ký tự tối đa là 4000!",
          },]}
        >
          <Input.TextArea
            showCount
            maxLength={4000}
            rows={6}
            placeholder="Nhập mô tả..."
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InfoGeneralForm;