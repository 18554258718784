import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal, Select } from "antd";
import CompForm from "@/common/CompForm";
import { createDepartments, updateDepartments } from "@/services/base";
import { StatusResponse } from "@/utils/const";

const DepartmentsFormAction = ({ stateModal, closeModal, reloadDepart, listDepartmentType }) => {
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } = stateModal;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
    }

    return () => {
      form.resetFields();
    };
  }, [isOpenModal, editData, form]);

  const onFinish = async (fieldValues) => {
    setLoading(true);
    try {
      const action = isCreate ? createDepartments : updateDepartments;
      const successMessage = isCreate
        ? "Tạo mới bộ phận thành công"
        : "Cập nhật bộ phận thành công.";

      const res = await action(fieldValues);

      if (res.code !== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }

      message.success(successMessage);
      reloadDepart();
      closeModal();
    } catch (error) {
      console.error("Error in onFinish:", error);
    } finally {
      setLoading(false);
    }
  };

  const formItemsConfig = [
    {
      name: "id",
      hidden: true,
      component: <Input hidden/>,
    },
    {
      label: "Mã bộ phận",
      name: "departmentCode",
      rules: [
        {
          required: true,
          message: "Mã bộ phận không được bỏ trống!",
        },
      ],
      component: <Input placeholder="Nhập mã bộ phận..."/>,
    },
    {
      label: "Tên bộ phận",
      name: "departmentName",
      rules: [
        {
          required: true,
          message: "Tên bộ phận không được bỏ trống!",
        },
      ],
      component: <Input placeholder="Nhập tên bộ phận..."/>,
    },

    {
      label: "Loại bộ phận",
      name: "departmentTypeId",
      rules: [
        {
          required: true,
          message: "Loại bộ phận không được bỏ trống!",
        },
      ],
      component: <Select allowClear placeholder="Chọn loại bộ phận..">
        {
          listDepartmentType.map((item, index) => {
            return <Select.Option key={item?.id} value={item?.id}>
              {item?.departmentTypeName}
            </Select.Option>
          })
        }

      </Select>,
    },
  ];
  return (
    <Modal
      title={titleModal}
      open={isOpenModal}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <CompForm
        loading={loading}
        buttonOkModal={buttonOkModal}
        onFinish={onFinish}
        form={form}
        formItemsConfig={formItemsConfig}
        activeBtnSub={true}
      />
    </Modal>
  );
};

export default DepartmentsFormAction;
