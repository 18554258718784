import React from 'react';
import { Button, Col, Form, InputNumber, Row, Select, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { formatCurrencyVND } from "@/utils/common";

const CategorySelect = ({ name, restField, listProducts }) => (
  <Form.Item
    label="Danh mục đề xuất"
    name={[name, "categoryId"]}
    {...restField}
    rules={[{
      required: true, message: "Vui lòng chọn danh mục",
    },]}
  >
    <Select
      allowClear
      showSearch
      placeholder="Chọn danh mục đề xuất"
      filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
      options={listProducts?.map(product => ({
        label: product.categoryName,
        value: product.id
      }))}
    />
  </Form.Item>
);

const AmountInput = ({ name, restField }) => (
  <Form.Item
    label="Số tiền"
    {...restField}
    name={[name, "amount"]}
    rules={[{
      required: true, message: "Vui lòng nhập số tiền",
    }]}
  >
    <InputNumber
      min={0}
      className={"w-full"}
      placeholder="Nhập số tiền"
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
    />
  </Form.Item>
);

const AddCategoryForm = ({ listProducts, totalAmount, isDailyReport }) => {

  return (
    <>
      <Form.List name="expenseDetails">
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }) => {
              return (
                <Row key={key} gutter={[10, 0]} className="relative">
                  <Col span={12}>
                    <CategorySelect name={name} listProducts={listProducts} restField={restField} />
                  </Col>
                  <Col span={11}>
                    <AmountInput name={name} restField={restField} />
                  </Col>
                  <Col span={1}>
                    {fields.length > 1 && (
                      <MinusCircleOutlined className="text-[16px] absolute right-1 top-[52px]" onClick={() => remove(name)} />
                    )}
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Thêm danh mục đề xuất
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {isDailyReport && (
        <Form.Item
          label="Tổng tiền"
          name="totalAmount"
          rules={[{
            validator: (_, value) => {
              if (String(value).length <= 12) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Tổng số tiền không được quá 12 số"));
            },
          },]}
        >
          <Typography.Text>
            {formatCurrencyVND(totalAmount || 0)}
          </Typography.Text>
        </Form.Item>
      )}
    </>
  );
};

export default AddCategoryForm;