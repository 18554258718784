import React, { useEffect, useState, useMemo } from "react";
import {
  Input,
  Flex,
  DatePicker,
  Select,
  Typography,
  Form,
  Divider,
  Badge,
  message,
} from "antd";
import dayjs from "dayjs";
import debounce from "debounce";
import { SearchOutlined } from "@ant-design/icons";
import DataTable from "../../common/DataTable";
import { getPagingLogaction } from "@/services/base";
import CompForm from "@/common/CompForm";
import { StatusResponse } from "@/utils/const";
import moment from "moment";
import CountData from "@/common/CountData";

const { RangePicker } = DatePicker;

function LogAction() {
  document.title = "Quản lý logs";

  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [logs, setLogs] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchField, setSearchField] = useState("userName");
  const [formSearch] = Form.useForm();

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPage: 0,
  });

  const fetchLogsData = async (pramSearch) => {
    setLoading(true);
    try {
      const params = {
        FilterValue: keyword.trim(),
        FilterColumn: searchField,
        StartDate: startDate,
        EndDate: endDate,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...pramSearch,
      };
      const res = await getPagingLogaction(params);
      if (res.code !== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }
      setLogs(res?.data);
      setPagination({
        ...pagination,
        totalPage: res.pagination.total,
        pageSize: res.pagination.pageSize,
      });
      setLoading(false);
    } catch (error) {
      console.log("FetchData Errror", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogsData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    pagination.pageIndex,
    searchField,
    startDate,
    endDate,
  ]);

  const renderStatus = (status) => {
    switch (status) {
      case 200:
        return (
          <Flex gap="4px" align="center">
            <Badge
              status="success"
              size="default"
              color="green"
              text={<span>Thành công</span>}
            />
          </Flex>
        );
      default:
        return (
          <Flex gap="4px" align="center">
            <Badge
              status="error"
              size="default"
              color="red"
              text={<span>Thất Bại</span>}
            />
          </Flex>
        );
    }
  };

  const cols = [
    {
      title: "Địa chỉ IP",
      dataIndex: "Ip",
      key: "Ip",
    },
    {
      title: "Tên Đăng Nhập",
      dataIndex: "UserName",
      key: "UserName",
    },
    {
      title: "Tên Hành Động",
      dataIndex: "ActionName",
      key: "ActionName",
      render: (ActionName) => {
        return (
          <Typography.Paragraph ellipsis={{ tooltip: true, rows: 3 }}>
            {ActionName}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Thời gian tạo",
      dataIndex: "CreateDate",
      key: "CreateDate",
      render: (CreateDate) => {
        return (
          <span>{moment(CreateDate + "z").format("DD/MM/YYYY HH:mm")}</span>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "ResponseData",
      key: "ResponseData",
      render: (ResponseData) => {
        const parseResponseData = JSON.parse(ResponseData);
        const parseResponseBody =
          parseResponseData?.ResponseBody &&
          JSON.parse(parseResponseData?.ResponseBody);
        return renderStatus(parseResponseBody?.code);
      },
    },
  ];

  const onSearch = async (params) => {
    fetchLogsData(params);
  };

  const onChangeKeyword = (event) => {
    const targetValue = event.target.value;
    setKeyword(targetValue);
    const params = {
      FilterValue: targetValue.trim(),
      FilterColumn: searchField,
      StartDate: startDate,
      EndDate: endDate,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    };

    debouncedSearch(params);
  };

  const handleSearchField = (value) => {
    setSearchField(value);
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
  };

  const onChangeDate = (date) => {
    if (date) {
      // Sử dụng format() thay vì toISOString() để giữ ngày giờ địa phương
      const startDateString = dayjs(date[0]).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const endDateString = dayjs(date[1]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      setStartDate(startDateString);
      setEndDate(endDateString);
      setPagination({
        ...pagination,
        pageIndex: 1,
      });
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce((data) => onSearch(data), 300);
  }, []);

  const onChangePagination = (curent, size) => {
    setPagination({
      ...pagination,
      pageIndex: curent,
      pageSize: size,
    });
  };

  return (
    <>
      <section className="mb-4">
        <CompForm
          className={"flex items-center "}
          children={
            <div className="flex">
              {" "}
              <Form.Item name="FilterValue" className="w-[250px] !m-0">
                <Input
                  size="middle"
                  type="search"
                  allowClear
                  placeholder="Nhập để tìm kiếm..."
                  onChange={(event) => onChangeKeyword(event)}
                  className="custom__input__searchlog"
                  addonBefore={<SearchOutlined/>}
                />
              </Form.Item>
              <Form.Item name="FilterColumn" className="w-[180px] !m-0">
                <Select
                  defaultValue="Theo Tên Đăng Nhập"
                  onChange={handleSearchField}
                  size="middle"
                  className="user-search-select-log m-0 min-w-44"
                >
                  <Select.Option value="userName">
                    Theo Tên Đăng Nhập
                  </Select.Option>
                  <Select.Option value="ip">Theo Địa Chỉ IP</Select.Option>
                  <Select.Option value="actionName">
                    Theo Tên Hành Động
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item className="w-[300px] ml-2 !m-0">
                <RangePicker
                  placeholder={["Ngày Bắt Đầu", "Ngày Kết Thúc"]}
                  format={"DD/MM/YYYY"}
                  allowClear
                  size="middle"
                  className="min-w-80 ml-2"
                  onChange={(date) => onChangeDate(date)}
                />
              </Form.Item>
            </div>
          }
          activeBtnSub={false}
          form={formSearch}
        />
      </section>
      <CountData
        loading={loading}
        total={pagination.totalPage}
        text="hành động"
      />
      <Divider/>
      <DataTable
        columns={cols}
        dataSource={logs}
        currentPage={pagination.pageIndex}
        pageSize={pagination.pageSize}
        totalPage={pagination.totalPage}
        loading={loading}
        onPageChange={onChangePagination}
        scroll={{ y: 550 }}
      />
    </>
  );
}

export default LogAction;
