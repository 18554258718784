import React from 'react';
import { Form, Switch } from "antd";

const SpecialApprovalForm = ({ setIsApproveSpecial }) => {

  return (
    <Form.Item
      label="Bật phê duyệt đặc biệt"
      name="isSpecialApprove"
      tooltip="Khi bật phê duyệt đặc biệt, sẽ không phụ thuộc vào tiêu chí hậu đài, cần cấp 'Phó chủ quản' phê duyệt!"
    >
      <Switch onChange={(e) => setIsApproveSpecial(e)}/>
    </Form.Item>
  );
};

export default SpecialApprovalForm;