import { Tooltip } from "antd";

const CardItem = ({ card, tooltip }) => {
  let textOuput;

  if (tooltip) {
    textOuput = (
      <h1 className="my-1 text-3xl">
        <Tooltip placement="topLeft" title={card.detail}>
          {card.total}
        </Tooltip>{" "}
        <span className="text-sm">{card.unit}&nbsp;₫</span>
      </h1>
    );
  } else {
    textOuput = <h1 className="my-1 text-3xl">{card.total}</h1>;
  }

  return (
    <div
      className="flex w-full items-center bg-gradient-primary rounded-2xl text-white shadow-[0_5px_15px_rgba(0,0,0,0.3)] px-5 py-2"
      style={{ backgroundImage: card.backgroundImage }}
    >
      <div className="flex items-center text-[30px]">{card.icon}</div>
      <div className="text-center pl-4 w-full">
        {textOuput}
        <p className="my-1 text-sm">{card.title}</p>
      </div>
    </div>
  );
};

export default CardItem;
