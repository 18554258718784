import { createProposedCategory, updateProposedCategory } from "@/services/base";
import { Button, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect } from "react";

const ProposedCategoryForm = ({stateModal, closeModal, reload}) => {
  const {title, isOpen, data, confirmText} = stateModal;
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }

    return () => {
      form.resetFields();
    };
  }, [isOpen, data, form]);

  const onFinish = async (fieldValues) => {
    const res = fieldValues.id ? await updateProposedCategory(fieldValues) : await createProposedCategory(fieldValues);

    if (res.isError) {
      message.error(res.message);
    } else {
      message.success(res.message);
      closeModal();
      reload();
    }
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          isBlock: false,
        }}
      >
        <Form.Item hidden name="id">
          <Input hidden />
        </Form.Item>
        <Form.Item
          label="Tên danh mục đề xuất"
          name="categoryName"
          rules={[
            {
              required: true,
              message: "Vui lòng không để trống.",
            },
          ]}
        >
          <Input placeholder="Nhập tên danh mục đề xuất..."/>
        </Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
          >
            {confirmText}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProposedCategoryForm;
