import React from 'react';
import { Col, Form, InputNumber, Row, Select, Tooltip } from "antd";
import { formatCurrencyVND } from "@/utils/common";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getAmountDetail } from "@/utils/utils";

const CategoryForm = ({ dataDetail, isDailyReportPage, isCloseRequestPage }) => {
  const spanNumber = isDailyReportPage ? 6 : 12;

  const validateAmount = (key, value) => {
    const amountItem = getAmountDetail(key, dataDetail);
    const reachedAmount = amountItem.amount === amountItem.amountInUse;

    if (value < 0) {
      return Promise.reject("Số tiền không được âm!");
    }

    if (isDailyReportPage && value > dataDetail?.calAmountExpenseRequest?.calculateAmount) {
      return Promise.reject(`Số tiền không được lớn hơn ngân sách khả dụng: ${formatCurrencyVND(dataDetail?.calAmountExpenseRequest?.calculateAmount)}`);
    }

    if (isCloseRequestPage) {
      if (amountItem.calAmountExpen > 0 && value > amountItem.calAmountExpen) {
        return Promise.reject(`Số tiền không được lớn hơn ngân sách đã sử dụng cho mục này: ${formatCurrencyVND(amountItem.calAmountExpen)}`);
      }
      if (reachedAmount && value > 0) {
        return Promise.reject("Số tiền không thể lớn hơn 0 vì NS sử dụng đã chạm mốc.");
      }
    }

    if (!isCloseRequestPage && !isDailyReportPage && value < amountItem.amountInUse) {
      return Promise.reject(`Số tiền không được thấp hơn ngân sách đã sử dụng cho mục này: ${formatCurrencyVND(amountItem.amountInUse)}`);
    }

    if (value > amountItem.amount) {
      return Promise.reject(`Số tiền không được vượt quá ngân sách phê duyệt cho mục này: ${formatCurrencyVND(amountItem.amount)}`);
    }

    return Promise.resolve();
  };

  const renderTooltipContent = (key) => {
    const { amountInUse, amount } = getAmountDetail(key, dataDetail);
    return (
      <>
        Số tiền NS đã sử dụng: {formatCurrencyVND(amountInUse)}.<br />
        Số tiền NS đã được duyệt: {formatCurrencyVND(amount)}.<br />
      </>
    );
  };

  return (
    <Form.List name="expenseDetails">
      {(fields) => (
        <>
          {fields?.map(({ key, name, ...restField }) => (
            <Row gutter={[8, 8]} key={key}>
              <Col span={spanNumber}>
                <Form.Item
                  label="Danh mục đề xuất"
                  name={[name, "categoryId"]}
                  {...restField}
                >
                  <Select
                    disabled
                    allowClear
                    showSearch
                    placeholder="Chọn danh mục đề xuất"
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={dataDetail.calAmountExpenseDetail.map(item => ({
                      label: item.categoryName,
                      value: item.categoryId,
                    }))}
                  />
                </Form.Item>
              </Col>
              {isDailyReportPage && (
                <>
                  <Col span={spanNumber}>
                    <Form.Item
                      label={<>Tiền camp</>}
                      {...restField}
                      name={[name, "amountCamp"]}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập số tiền",
                        },
                        {
                          validator: (_, value) => {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Số tiền không được âm!")
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        className="w-full"
                        placeholder="Nhập số tiền camp"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={spanNumber}>
                    <Form.Item
                      label={<>Tiền dôi camp</>}
                      {...restField}
                      name={[name, "amountAdditional"]}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Số tiền không được âm!")
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        className="w-full"
                        placeholder="Nhập số tiền dôi camp"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              
              <Col span={spanNumber}>
                <Form.Item
                  label={
                    <>
                      Tổng tiền
                      <Tooltip
                        overlayClassName="!min-w-[320px]"
                        title={renderTooltipContent(key)}
                      >
                        <QuestionCircleOutlined className="ml-2" />
                      </Tooltip>
                    </>
                  }
                  {...restField}
                  name={[name, "amount"]}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số tiền",
                    },
                    {
                      validator: (_, value) => validateAmount(key, value),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder="Nhập số tiền"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </>
      )}
    </Form.List>
  );
};

export default CategoryForm;