import React, { useEffect, useMemo, useState } from "react";
import ButtonPrimary from "@/common/ButtonPrimary";
import {
  Button,
  Divider,
  Flex,
  Form,
  Image,
  message,
  Modal,
  Space,
  Switch,
  Tooltip,
  Upload,
} from "antd";
import DataTable from "@/common/DataTable";
import UsersFormAction from "@/components/users/UsersFormAction";
import {
  deleteUser,
  exportFileUser,
  getPagingUsers,
  importFileUser
} from "@/services/base";
import {
  StatusResponse,
} from "@/utils/const";
import qs from "query-string";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import avatar from "@/assets/images/avatar-default.png";
import debounce from "debounce";
import { updateUser } from "@/services/base";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import {
  getAllRoles,
} from "@/utils/fetchData";
import CountData from "@/common/CountData";
import { isFullPermission } from "@/utils/common";
import { downloadExcelFile } from "@/utils/utils";
import UserFilterForm from "@/components/users/UserFilterForm";

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const initParams = {
  textSearch: "",
  roleId: "",
  siteId: "",
  departmentId: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0,
};

const { confirm } = Modal;

const Users = () => {
  document.title = "Quản lý nhân viên";
  const userInfor = useRecoilValue(infoUser);
  const [loading, setLoading] = useState(false);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [users, setUsers] = useState([]);
  const [exportBtnLoading, setExportBtnLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [formSearch] = Form.useForm();
  const [params, setParams] = useState(initParams);

  useEffect(() => {
    getUsers(params);
    getAllRoles(setRole);
  }, []);

  const getUsers = async (value) => {
    setLoading(true);
    try {
      const res = await getPagingUsers(qs.stringify(value));

      if (res?.code !== StatusResponse.SUCCESS) {
        return message.error(res?.message);
      }

      setParams({
        ...params,
        pageIndex: res?.pagination?.pageIndex,
        total: res?.pagination?.total,
        pageSize: res?.pagination?.pageSize,
        textSearch: value?.textSearch || "",
        roleId: value?.roleId || "",
        siteId: value?.siteId || "",
        departmentId: value?.departmentId || "",
        areaId: value?.areaId || "",
        teamId: value?.teamId || "",
        groupId: value?.groupId || "",
      });
      setUsers(res?.data);
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const editCapability = (record) => {
    let disabled = true;
    const isMe = record?.id === userInfor?.id;
    const sameSiteId = record?.site?.id === userInfor?.site?.id;
    const sameDepartmentId =
      record?.department?.id === userInfor?.department?.id;
    const largerRole = userInfor?.role?.roleLevel > record.role.roleLevel;
    const roleCode = userInfor?.role?.roleCode;
    const isPCQ = roleCode === "PCQ";
    const isHlv = roleCode === "HLV";
    const isTT = roleCode === "TT";
    const isQL = roleCode === "QL";
    if (
      (isPCQ && sameSiteId && largerRole && !isMe) ||
      (isHlv && sameDepartmentId && largerRole && !isMe) ||
      ((isFullPermission(roleCode) || isTT || isQL) && !isMe)
    ) {
      disabled = false;
    }

    return disabled;
  };

  const columns = [
    {
      title: "Hình ảnh",
      dataIndex: "",
      width: 100,
      render: (_, record) => {
        return (
          <Image
            style={{
              background:
                "linear-gradient(282deg, #00B0FF 5.54%, #3E98EB 100%)",
              borderRadius: 50,
            }}
            width={30}
            preview={false}
            src={record?.avataUrl || null}
            fallback={avatar}
          />
        );
      },
    },
    {
      title: "Tài khoản",
      dataIndex: "userName",
    },
    {
      title: "Họ và tên",
      dataIndex: "fullName",
    },
    {
      title: "Trang",
      dataIndex: "site",
      render: (site) => (site ? site.siteName : ""),
    },
    {
      title: "Bộ phận",
      dataIndex: "department",
      render: (department) => (department ? department.departmentName : ""),
    },
    {
      title: "Khu ",
      dataIndex: "area",
      render: (area) => (area ? area.areaName : ""),
    },
    {
      title: "Tổ",
      dataIndex: "team",
      render: (team) => (team ? team.teamName : ""),
    },
    {
      title: "Nhóm hậu đài",
      dataIndex: "group",
      render: (group) => (group ? group.groupName : ""),
    },
    {
      title: "Vai trò",
      dataIndex: "role",
      render: (role) => (role ? role.roleName : ""),
    },
  ].concat(
    userInfor?.role?.roleLevel === null || userInfor.role.roleLevel > 0
      ? [
        {
          title: "Trạng thái",
          dataIndex: "isActive",
          width: 100,
          render: (_, record) => {
            const disabled = editCapability(record);

            return (
              <Tooltip
                title={`${
                  record.isBlock !== true
                    ? "Khóa tài khoản"
                    : "Mở khóa tài khoản"
                }`}
              >
                <Switch
                  disabled={disabled}
                  size="small"
                  checked={record.isBlock !== true}
                  onChange={(e) => handleChangeStatus(record, e)}
                />
              </Tooltip>
            );
          },
        },
        {
          title: "Hành động",
          dataIndex: "",
          fixed: "right",
          width: 110,
          render: (_, record) => {
            const disabled = editCapability(record);
            return (
              <Space>
                <Tooltip title={"Chỉnh sửa"}>
                  <Button
                    disabled={disabled}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined/>}
                    size="small"
                    onClick={() => updateUserData(record)}
                  />
                </Tooltip>
                <Tooltip title={"Xóa"}>
                  <Button
                    disabled={disabled}
                    type="primary"
                    shape="circle"
                    danger
                    icon={<DeleteOutlined/>}
                    size="small"
                    onClick={() => showModalConfirmDelete(record.key)}
                  />
                </Tooltip>
              </Space>
            );
          },
        },
      ]
      : []
  );

  const handleChangeStatus = async (record, value) => {
    const valueStatus = {
      ...record,
      isBlock: !value,
    };

    try {
      const res = await updateUser(valueStatus);

      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
        return;
      }

      message.success("Cập nhật trạng thái thành công!");
      actionUser(res.data, "changeStatus");
    } catch (error) {
      message.error(error);
    }
  };

  const onchangePage = (pageIndex, pageSize) => {
    setParams((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));

    getUsers({
      ...params,
      pageIndex: pageIndex,
      pageSize: pageSize,
    });
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled/>,
      content: "Điều này sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",
      cancelText: "Hủy",
      onOk() {
        onDeleteUser(record);
      },
      onCancel() {},
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const onDeleteUser = async (userid) => {
    try {
      const res = await deleteUser(userid);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      message.success("Xóa nhân viên thành công!");
      actionUser(userid, "delete");
    } catch (error) {
      message.error(error);
    }
  };

  const handleOnchangeSearch = (data) => {
    getUsers({
      ...data,
      pageIndex: 1,
      pageSize: 20
    });
  }

  const actionUser = async (data, type) => {
    switch (type) {
      case "create":
        getUsers(params);
        break;
      case "update":
        getUsers(params);
        break;
      case "delete":
        getUsers(params);
        break;
      case "cancel":
        getUsers(params);
        break;
      case "changeStatus":
        setUsers(
          users.map((u) =>
            u.id === data.id ? { ...u, isBlock: data.isBlock } : u
          )
        );
        break;
      default:
        break;
    }
  };

  const createUser = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Tạo mới nhân viên",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const updateUserData = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa nhân viên",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const onImportUser = async (file) => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: (
          <p className="-ml-6">
            Bạn có chắc chắn muốn import file không?
          </p>
        ),
        okText: "Đồng ý",
        cancelText: "Hủy",
        onOk: async () => {
          const res = await importFileUser({
            file: file.file.originFileObj,
          });
          if (res?.code === 200 && res?.error === 0) {
            message.success("Import file thành công!");
            downloadExcelFile(res.data, `Imported-users`);
            getUsers(initParams);
          } else {
            message.error("Import file thất bại!");
          }
        },
        cancelButtonProps: {
          type: 'primary',
          danger: true
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportFile = async () => {
    setExportBtnLoading(true)
    try {
      const res = await exportFileUser({
        textSearch: params.textSearch || '',
        pageIndex: params.pageIndex || 1,
        pageSize: params.pageSize || 10,
        siteId: params.siteId || null,
        teamId: params.teamId || null,
        roleId: params.roleId || null,
        areaId: params.areaId || null,
        departmentId: params.departmentId || null
      });
      if (res.code === 200 && res.error === 0) {
        downloadExcelFile(res.data, `Exported-users`);
        message.success("Export file thành công!");
      } else {
        message.error("Export file thất bại!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportBtnLoading(false);
    }
  }

  return (
    <>
      <section className="flex items-end justify-between mb-4 flex-wrap gap-3">
        <UserFilterForm loading={loading} role={role} formSearch={formSearch}
                        handleOnchangeSearch={handleOnchangeSearch}/>
      </section>
      <Flex gap={12} justify="space-between" align="flex-end">
        <CountData loading={loading} total={params.total} text="nhân viên"/>
        <Flex gap={8}>
          <Upload
            accept=".xlsx, .xls"
            className="hidden-tooltip-upload"
            onChange={onImportUser}
            fileList={[]}
          >
            <Button className="h-[37px]">
              <UploadOutlined/>
              Import file
            </Button>
          </Upload>
          <Button
            className="h-[37px]"
            onClick={handleExportFile}
            loading={exportBtnLoading}
          >
            <DownloadOutlined/>
            Export file
          </Button>
          <ButtonPrimary text="Thêm nhân viên" onClick={createUser}/>
        </Flex>
      </Flex>
      <Divider className="mt-3"/>
      <DataTable
        columns={columns}
        dataSource={users.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={params.total}
        loading={loading}
        onPageChange={onchangePage}
        scroll={{ y: 550 }}
      />
      {stateModal?.isOpenModal && (
        <UsersFormAction
          stateModal={stateModal}
          closeModal={closeModal}
          actionUser={actionUser}
          roles={role}
          userInfor={userInfor}
        />
      )}
    </>
  );
};
export default Users;
