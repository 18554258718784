import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { DatePicker, message, Select } from "antd";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import qs from "query-string";
import { getSumaryTicketYear } from "@/services/base";
import { StatusResponse } from "@/utils/const";

import { LoadingOutlined } from "@ant-design/icons";
import {
  handleClearLoadMore,
  handleLoadMore,
  handleSearchLoadMore,
} from "@/utils/utils";
import {
  getListSites,
  getListAreas,
  getListGroups,
  getListTeams,
} from "@/utils/fetchData";

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0,
};

const initOptions = {
  colors: ["#38a7ff", "#cccccc"],
  chart: {
    type: "bar",
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [
      "Tháng 1",
      "Tháng 2",
      "Tháng 3",
      "Tháng 4",
      "Tháng 5",
      "Tháng 6",
      "Tháng 7",
      "Tháng 8",
      "Tháng 9",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12",
    ],
  },
  yaxis: {
    tickAmount: 3,
    title: {
      text: "Số lượng ngân sách",
    },
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " ngân sách";
      },
    },
  },
};

const ColumnChart = ({
  isVisibleFullView,
  visibleOptions,
  optionsSelectSite,
  optionsSelectArea,
  optionsSelectTeam,
  optionsSelectGroup,
  isTLBank
}) => {
  const initData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const [loading, setLoading] = useState(true);
  const [dataApproval, setDataApproval] = useState(initData);
  const [dataReject, setDataReject] = useState(initData);
  const [series, setSeries] = useState([
    {
      name: "Hoàn Thành",
      data: initData,
    },
    {
      name: "Từ Chối",
      data: initData,
    },
  ]);
  const [options, setOptions] = useState({
    ...initOptions,
    plotOptions: {
      bar: {
        ...initOptions.plotOptions.bar,
        columnWidth: isVisibleFullView ? "55%" : "30%",
      },
    },
  });
  const [year, setYear] = useState(dayjs(new Date()));
  const userInfor = useRecoilValue(infoUser);
  const isSupperSite = userInfor?.site?.isSupper;
  const [teamSite, setTeamSite] = useState({
    teamId: isVisibleFullView ? null : userInfor?.teamId,
    siteId: isVisibleFullView ? null : userInfor?.siteId,
  });
  const [siteParams, setSiteParams] = useState(initParams);
  const [areaParams, setAreaParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
  });
  const [teamParams, setTeamParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite ? null : userInfor?.areaId,
  });
  const [groupParams, setGroupParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite ? null : userInfor?.areaId,
    teamId: isSupperSite ? null : userInfor?.teamId,
  });
  const [sites, setSites] = useState([]);
  const [areas, setAreas] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchParams, setSearchParams] = useState({
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite || isTLBank ? null : userInfor?.areaId,
    teamId: isSupperSite ? null : userInfor?.teamId,
    groupId: isSupperSite ? null : userInfor?.groupId,
    year: dayjs(new Date()).year(),
  });

  useEffect(() => {
    getListSites(siteParams, setSites, setSiteParams);
  }, [siteParams.pageSize, siteParams.textSearch]);

  useEffect(() => {
    getListAreas(areaParams, setAreas, setAreaParams);
  }, [areaParams.pageSize, areaParams.textSearch, areaParams.siteId]);

  useEffect(() => {
    getListTeams(teamParams, setTeams, setTeamParams);
  }, [teamParams.pageSize, teamParams.textSearch, teamParams.siteId, , teamParams.areaId]);

  useEffect(() => {
    getListGroups(groupParams, setGroups, setGroupParams);
  }, [
    groupParams.pageSize,
    groupParams.textSearch,
    groupParams.siteId,
    groupParams.areaId,
    groupParams.teamId,
  ]);

  useEffect(() => {
    getAnalytics();
  }, [searchParams]);

  useEffect(() => {
    setSeries([
      {
        name: "Hoàn Thành",
        data: dataApproval,
      },
      {
        name: "Từ Chối",
        data: dataReject,
      },
    ]);
  }, [dataApproval, dataReject, teamSite]);

  const getAnalytics = async () => {
    setLoading(true);

    try {
      const params = qs.stringify(searchParams);

      const res = await getSumaryTicketYear(params);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      const newDataApproval = [...dataApproval];
      const newDataReject = [...dataReject];

      if (Array.isArray(res.data)) {
        res.data.map((item, index) => {
          const itemData = item.data;

          if (itemData.length > 0) {
            const itemApproval = itemData.find((e) => e?.processStatusId == 5);
            const itemReject = itemData.find((e) => e?.processStatusId == 6);
            const itemFinal = itemData.find((e) => e?.processStatusId == 7);
            const approvalCount = itemApproval ? itemApproval?.count : 0;
            const finalCount = itemFinal ? itemFinal?.count : 0;

            newDataApproval[index] = approvalCount + finalCount;
            newDataReject[index] = itemReject ? itemReject?.count : 0;
          } else {
            newDataApproval[index] = 0;
            newDataReject[index] = 0;
          }
        });
      }
      setDataApproval(newDataApproval);
      setDataReject(newDataReject);

      const dataApprovalReject = [...newDataApproval, ...newDataReject];
      const maxTickAmount = Math.max(...dataApprovalReject);

      setOptions((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          tickAmount: maxTickAmount > 10 ? 3 : maxTickAmount,
        },
      }));

      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const onChangeYear = (value) => {
    if (value) {
      setSearchParams((prev) => ({
        ...prev,
        year: dayjs(value).year(),
      }));
    }
  };

  const handleSiteChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setAreaParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setTeamParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setGroupParams((prev) => ({
      ...prev,
      siteId: value,
    }));
  };

  const handleAreChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      areaId: value,
    }));

    setTeamParams((prev) => ({
      ...prev,
      areaId: value,
    }));

    setGroupParams((prev) => ({
      ...prev,
      areaId: value,
    }));
  };

  const handleTeamChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      teamId: value,
    }));
  };

  const handleGroupChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      groupId: value,
    }));
  };

  const filterOption = useCallback(
    (input, option) =>
      option?.label.toLowerCase().includes(input.toLowerCase()),
    []
  );

  const siteOptions = useMemo(
    () =>
      sites?.map(({ id, siteName }) => ({
        value: id,
        label: siteName,
      })),
    [sites]
  );

  const areaOptions = useMemo(
    () =>
      areas?.map(({ id, areaName }) => ({
        value: id,
        label: areaName,
      })),
    [areas]
  );

  const teamOptions = useMemo(
    () =>
      teams?.map(({ id, teamName }) => ({
        value: id,
        label: teamName,
      })),
    [teams]
  );

  const groupOptions = useMemo(
    () =>
      groups?.map(({ id, groupName }) => ({
        value: id,
        label: groupName,
      })),
    [groups]
  );

  return (
    <div>
      <h4 className="mb-7 mt-0">
        Thống kê số lượng ngân sách đã hoàn thành và từ chối
      </h4>
      <div className="flex gap-3">
        <DatePicker
          picker="year"
          allowClear={false}
          placeholder="Chọn năm"
          defaultValue={year}
          onChange={onChangeYear}
        />
        {visibleOptions?.isShowSite && (
          <Select
            {...optionsSelectSite}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn trang"
            onChange={handleSiteChange}
            onSearch={(e) => handleSearchLoadMore(e, setSiteParams)}
            onClear={handleClearLoadMore(setSiteParams)}
            filterOption={filterOption}
            options={siteOptions}
            onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
          />
        )}

        {visibleOptions?.isShowArea && (
          <Select
            {...optionsSelectArea}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn khu"
            onChange={handleAreChange}
            onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
            onClear={handleClearLoadMore(setAreaParams)}
            filterOption={filterOption}
            options={areaOptions}
            onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
          />
        )}

        {visibleOptions?.isShowTeam && (
          <Select
            {...optionsSelectTeam}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn tổ"
            onChange={handleTeamChange}
            onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
            onClear={handleClearLoadMore(setTeamParams)}
            filterOption={filterOption}
            options={teamOptions}
            onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
          />
        )}

        {visibleOptions?.isShowGroup && (
          <Select
            {...optionsSelectGroup}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn hậu đài"
            onChange={handleGroupChange}
            onSearch={(e) => handleSearchLoadMore(e, setGroupParams)}
            onClear={handleClearLoadMore(setGroupParams)}
            filterOption={filterOption}
            options={groupOptions}
            onPopupScroll={handleLoadMore(groupParams, setGroupParams)}
          />
        )}
        {loading && (
          <LoadingOutlined
            style={{
              fontSize: 28,
              color: "#38a7ff",
              fontWeight: "bold",
            }}
          />
        )}
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ColumnChart;
