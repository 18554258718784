import ButtonPrimary from "@/common/ButtonPrimary";
import DataTable from "@/common/DataTable";
import { getListExpenseApi } from "@/services/base";
import { formatCurrencyVND } from "@/utils/common";
import { Divider, Form, Tag } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import FormRequest from "@/components/listRequset/FormRequest";
import FormSearch from "@/common/FormSearch";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { useNavigate } from "react-router-dom";
import { CONST_PROCESSING_EXPENSE_TYPEFORM, ROLES } from "@/utils/const";
import TagStatus from "@/components/listRequset/TagStatus";
import {
  getExpenseTypeList,
  getListDepartment,
  getListGroups,
  getListProcess,
  getListProcessStatus,
  getListProduct,
  getListSites,
  getListUser,
  getListPurposes
} from "@/utils/fetchData";
import { goToPage } from "@/utils/utils";
import CountData from "@/common/CountData";

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0,
};

export const initSearchParams = {
  textSearch: "",
  stepId: null,
  statusId: null,
  siteId: null,
  departmentId: null,
  date: [],
  groupId: null,
  pageIndex: 1,
  pageSize: 10,
};

const ListRequest = () => {
  document.title = "Quản lý ngân sách";

  const infoUserState = useRecoilValue(infoUser);
  const [listRequest, setListRequest] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [paginationUser, setPaginationUser] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 5,
    pages: 1,
  });
  const [paginationDepartment, setPaginationDepartment] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 5,
    pages: 1,
  });
  const [params, setParams] = useState(initParams);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    typeForm: "",
    title: "",
  });
  const [dataEdit, setDataEdit] = useState();
  const [listUser, setListUser] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listSite, setListSite] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [listPurpose, setListPurpose] = useState([]);
  const [listProcess, setlistProcess] = useState([]);
  const [listProcessStatus, setlistProcessStatus] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [expenseTypeList, setExpenseTypeList] = useState([]);
  const userRoleCode = infoUserState?.role?.roleCode;
  const [listGroup, setListGroup] = useState([]);

  const disableFilterRole =
    userRoleCode === ROLES.MEMBER ||
    userRoleCode === ROLES.TT ||
    userRoleCode === ROLES.TL ||
    userRoleCode === ROLES.TLB ||
    userRoleCode === ROLES.HLV;
  const [searchParams, setSearchParam] = useState(initSearchParams);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const renderStatusCol = (rec) => {
    return <TagStatus record={rec}/>;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      width: 85,
      render: (id, record) => (
        <a
          href
          onClick={() => goToPage(id, "NS", navigate, true)}
        >{`${record.expenseType.shortName}${id}`}</a>
      ),
    },
    {
      title: "Mã báo cáo ngày hôm nay",
      dataIndex: "expenseReportDailyId",
      key: "expenseReportDailyId",
      width: 85,
      render: (id, record) =>
        id ? (
          <a
            href
            onClick={() => goToPage(id, "BC", navigate, true)}
          >{`BC${id}`}</a>
        ) : (
          "--"
        ),
    },
    {
      title: "Số tiền",
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng chi tiêu",
      dataIndex: "calculateAmountInUse",
      width: 130,
      key: "calculateAmountInUse",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Trang",
      dataIndex: "site",
      key: "site",
      render: (value) => (value ? value.siteName : "--"),
    },
    {
      title: "Tổ làm việc",
      dataIndex: "team",
      key: "team",
      render: (value) => (value ? value.teamName : "--"),
    },
    {
      title: "Nhóm hậu đài",
      dataIndex: "group",
      key: "group",
      render: (value) => (value ? value.groupName : "--"),
    },
    {
      title: "Thời gian bắt đầu sử dụng ngân sách",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
      render: (value) => moment(value + "z").format("DD/MM/YYYY"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          width: 200,
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          width: 200,
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      width: 140,
      render: (_, rec) => renderStatusCol(rec),
    },

    {
      title: "Người được giao",
      dataIndex: "userAssignTo",
      key: "userAssignTo",
      width: 140,
      render: (value) => (value ? value.fullName : "--"),
    },
    {
      title: "Người phụ trách camp",
      dataIndex: "userSupervisorCamp",
      key: "userSupervisorCamp",
      render: (value) => (value ? value.fullName : "--"),
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "userApprove",
      width: 150,
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    },
  ];

  useEffect(() => {
    getListUser(setListUser, setPaginationUser, paginationUser);
    getExpenseTypeList(setExpenseTypeList);
    getListDepartment(
      paginationDepartment,
      setListDepartment,
      setPaginationDepartment
    );
    getListSites({}, setListSite);
    getListProcess(setlistProcess);
    getListProcessStatus(setlistProcessStatus);
    getListProduct(setListProducts, params);
    getListGroups({ pageSize: 10 }, setListGroup);
    getListPurposes({}, setListPurpose)
  }, []);

  useEffect(() => {
    getListExpense(searchParams);
  }, [searchParams]);

  const getListExpense = async (params) => {
    try {
      setLoading(true);
      const res = await getListExpenseApi(params);
      if (res.code === 200 && res.error === 0) {
        setListRequest(res.data);
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setModalState({ isModalOpen: false, title: "", typeForm: "" });
    setDataEdit(null);
  };

  const handlechangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields };
      setSearchParam({
        ...newParams,
        pageIndex: 1
      });
    },
    [searchParams]
  );

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
  };

  return (
    <>
      <section className="flex justify-between  mb-4">
        <FormSearch
          form={form}
          listExpenseType={expenseTypeList}
          listProcess={listProcess}
          listProcessStatus={listProcessStatus}
          searchParams={searchParams}
          setSearchParam={setSearchParam}
          listSite={listSite}
          listDepartment={listDepartment}
          listGroup={listGroup}
          disableFilterRole={disableFilterRole}
          userRoleCode={userRoleCode}
          onchangeSearch={handlechangeSearch}
        />
        <ButtonPrimary
          text="Tạo ngân sách"
          onClick={() =>
            setModalState({
              isModalOpen: true,
              typeForm: CONST_PROCESSING_EXPENSE_TYPEFORM.Proposal,
              title: "TẠO NGÂN SÁCH DỰ CHI",
            })
          }
        />
      </section>
      <CountData loading={loading} total={totalPage} text="ngân sách"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={listRequest.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loading}
        scroll={{ x: "max-content", y: 490 }}
        isBorder
        onPageChange={onchangePage}
      />
      {modalState?.isModalOpen && (
        <FormRequest
          modalState={modalState}
          handleCancel={handleClose}
          dataEdit={dataEdit}
          listUser={listUser}
          listDepartment={listDepartment}
          listSite={listSite}
          listPurpose={listPurpose}
          listProducts={listProducts}
          loadingForm={loadingForm}
          setLoadingForm={setLoadingForm}
          handleReload={() => getListExpense(searchParams)}
        />
      )}
    </>
  );
};

export default ListRequest;
