import React from 'react';
import { Col, Divider, Row, Typography } from "antd";
import { PROCESS_STATUS } from "@/utils/const";
import moment from "moment/moment";

const ApproveHistoryInfo = ({ approvalHistory }) => {
  const { Title, Text } = Typography;

  const formattedNote = (note) => note?.replace(/\n/g, '<br />');

  return (
    <div>
      {approvalHistory && approvalHistory.length > 0  && (
        <>
          <section>
            <Row className="flex justify-between mt-10">
              <Title level={3}>Lịch sử quy trình</Title>
            </Row>
            <Divider />
            <div className="max-h-[350px] overflow-y-auto custom-scroll-history">
              {approvalHistory.map((item, idx) => (
                <div key={idx}>
                  <Row>
                    <Col span={6}>
                      <Row>
                        <Text strong>
                          {item.processStatus.statusCode ===
                          PROCESS_STATUS.new
                            ? "Tạo bởi:"
                            : "Phê duyệt bởi:"}&nbsp;
                        </Text>
                        <Text>{item?.approvedBy?.fullName}</Text>
                      </Row>
                      <Row className="mt-4">
                        <Text strong>Người được giao:&nbsp;</Text>
                        <Text>{item?.assignName}</Text>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Text strong>Bước:&nbsp;</Text>
                        <Text>{item?.processStep?.stepName}</Text>
                      </Row>
                      <Row className="mt-4">
                        <Text strong>Người phụ trách:&nbsp;</Text>
                        <Text>{item?.supervisorName ?? '--'}</Text>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Text strong>Trạng thái duyệt:&nbsp;</Text>
                        <Text>{item?.processStatus?.statusName}</Text>
                      </Row>
                      <Row className="mt-4">
                        <Text strong>
                          {item.processStatus.statusCode ===
                          PROCESS_STATUS.new
                            ? "Ngày Tạo:"
                            : "Ngày phê duyệt:"}&nbsp;
                        </Text>
                        <Text>
                          {moment(item?.creationDate + "z").format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </Text>
                      </Row>
                    </Col>
                    {item?.note && (
                      <Col span={6}>
                        <Row>
                          <Text strong>Ghi chú: &nbsp;</Text>
                          <Text>
                            <span dangerouslySetInnerHTML={{ __html: formattedNote(item?.note) }} />
                          </Text>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <Divider />
                </div>
              ))}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default ApproveHistoryInfo;