import React, { useCallback } from 'react';
import { Form, Select } from "antd";
import { useRecoilValue } from "recoil";
import { paramsSuperivorState } from "@/recoil/atoms";
import { handleLoadMore } from "@/utils/utils";

const SupervisorInfoForm = ({ dataSupervior }) => {
  const paramsSupervior = useRecoilValue(paramsSuperivorState)
  const filterOption = useCallback((input, option) =>
    option?.label.toLowerCase().includes(input.toLowerCase()),
  []);

  return (
    <Form.Item
      label="Chọn người phụ trách"
      name="supervisorId"
      tooltip="Chọn người phụ trách camp cho phiếu ngân sách này"
      rules={[{ required: true, message: "Vui lòng chọn người phụ trách" }]}
    >
      <Select
        allowClear
        filterOption={filterOption}
        // onPopupScroll={handleLoadMore(siteParams, setSiteParams)}
        showSearch
        placeholder="Chọn người phụ trách"
        options={dataSupervior?.map(item => ({
          value: item.id,
          label: item.userName
        }))}
      />
    </Form.Item>
  );
};

export default SupervisorInfoForm;