import React from 'react';
import { Col, Divider, Row, Typography } from "antd";
import { formatCurrencyVND } from "@/utils/common";

const ProposeCategoryDetail = ({ dataDetail }) => {
  const { Title, Text } = Typography;

  return (
    <section>
      <Row className="flex justify-between mt-10">
        <Title level={3}>Chi tiết danh mục đề xuất</Title>
      </Row>
      <Divider/>
      <div>
        {dataDetail?.expenseDetails?.map((item, idx) => (
          <div key={idx}>
            <Row>
              <Col span={8}>
                <Text strong>Tên danh mục: </Text>
                <Text>{item?.categoryName}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Tổng tiền: </Text>
                <Text>{formatCurrencyVND(item?.amount || 0)}</Text>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col span={8}>
                <Text strong>Tiền camp báo cáo: </Text>
                <Text>{formatCurrencyVND(item?.amountCamp || 0)}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Tiền dôi camp: </Text>
                <Text>{formatCurrencyVND(item?.amountAdditional || 0)}</Text>
              </Col>
            </Row>
            <Divider/>
          </div>
        ))}
      </div>
      <Row>
        <Col span={8}>
          <Typography.Text strong>Tổng tiền: </Typography.Text>
          <Typography.Text>{formatCurrencyVND(dataDetail?.totalAmount || 0)}</Typography.Text>
        </Col>
        <Col span={8}>
          <Typography.Text strong>Tổng ngân sách đã ứng: </Typography.Text>
          <Typography.Text>{formatCurrencyVND(dataDetail?.calAmountExpenseRequest?.calculateAmount || 0)}</Typography.Text>
        </Col>
        <Col span={8}>
          <Typography.Text strong>Tổng ngân sách chi tiêu: </Typography.Text>
          <Typography.Text>{formatCurrencyVND(dataDetail?.calAmountExpenseRequest?.calculateAmountInUse || 0)}</Typography.Text>
        </Col>
      </Row>
      <Divider />
    </section>
  );
};

export default ProposeCategoryDetail;