import React from 'react';
import { Col, Divider, Flex, Image, Row, Tag, Tooltip, Typography } from "antd";
import moment from "moment/moment";
import { getFileExtension, goToPage } from "@/utils/utils";
import { useLocation, useNavigate } from "react-router";

const TicketInfo = ({ dataDetail }) => {
  const navigate = useNavigate();
  let location = useLocation();

  const isDailyReportPage = location.pathname.split("/")[1] === "daily-report";
  const isCloseRequestPage = location.pathname.split("/")[1] === "close-request";
  const suffixGroup = dataDetail?.purpose?.purposeCode === "Tiktok" ? "a" : "";

  return (
    <section>
      <Row>
        <Col span={6}>
          <Typography.Text strong>Mã phiếu: </Typography.Text>
          <Tag>{`${dataDetail?.expenseType.shortName}${dataDetail?.id}`}</Tag>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Mã phiếu gốc: </Typography.Text>
          <Tag color="blue" className="cursor-pointer" title={`Đi tới trang ngân sách ${dataDetail?.refId}`}
               onClick={() => goToPage(dataDetail?.refId, 'NS', navigate, true)}
          >
            {`NS${dataDetail?.refId}`}
          </Tag>
        </Col>
        <Col span={12}>
          <Typography.Text strong>Quy trình: </Typography.Text>
          <Typography.Text>{dataDetail?.processType?.name}</Typography.Text>
        </Col>
      </Row>
      <Divider/>
      <Row>
        <Col span={6}>
          <Typography.Text strong>Nhân viên báo cáo: </Typography.Text>
          <Typography.Text>{dataDetail?.userCreate?.fullName || "Trống"}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Nhân viên được giao: </Typography.Text>
          <Typography.Text>{dataDetail?.userAssignTo?.fullName || "Trống"}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Nhân viên phụ trách camp: </Typography.Text>
          <Typography.Text>{dataDetail?.userSupervisorCamp?.fullName || "Trống"}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Nhân viên xử lý: </Typography.Text>
          <Typography.Text>{dataDetail?.processingUser?.fullName  || "Trống"}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Nhóm hậu đài: </Typography.Text>
          <Typography.Text>{dataDetail?.group?.groupName}{suffixGroup}</Typography.Text>
        </Col>
      </Row>
      <Divider/>
      <Row>
        {isDailyReportPage && (
          <Col span={6}>
            <Typography.Text strong>Xác nhận camp cuối cùng: </Typography.Text>
            <Typography.Text>
              {dataDetail?.isLastReport ? "Có" : "Không"}
            </Typography.Text>
          </Col>
        )}
        <Col span={6}>
          <Typography.Text strong>Thời gian báo cáo: </Typography.Text>
          <Typography.Text>{moment(dataDetail?.creationDate + "z").format("DD/MM/YYYY HH:mm")}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text strong>Thời gian sử dụng ngân sách: </Typography.Text>
          <Typography.Text>
            {" "}
            Từ{" "}
            {moment(dataDetail?.startDate + "").format("DD/MM/YYYY")}
          </Typography.Text>
          <Typography.Text>
            {" "}
            đến{" "}
            {moment(dataDetail?.endDate + "").format("DD/MM/YYYY")}
          </Typography.Text>
        </Col>
        <Divider />
      </Row>
      {isCloseRequestPage && (
        <Row>
          <Col span={6}>
            <Typography.Text strong>Tên ngân hàng: </Typography.Text>
            <Typography.Text>{dataDetail?.bankName ?? '--'}</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text strong>Tên tài khoản: </Typography.Text>
            <Typography.Text>{dataDetail?.accountName ?? '--'}</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text strong>Số tài khoản: </Typography.Text>
            <Typography.Text>{dataDetail?.numberAccount ?? '--'}</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text strong>Nội dung chuyển khoản: </Typography.Text>
            <Typography.Text>{dataDetail?.bankNote ?? '--'}</Typography.Text>
          </Col>
          <Divider/>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Typography.Text className="block" strong>
            Hình ảnh:{" "}
          </Typography.Text>
          <div className="flex items-center mt-4 gap-4 flex-wrap">
            {dataDetail?.attFiles?.map((item, index) => {
              const fileExtension = getFileExtension(item?.filePath);
              const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExtension);
              if (item.isVoice === false) {
                const fileExtension = getFileExtension(item?.filePath);
                return (
                  <Flex className="flex-col" key={index}>
                    <div className="w-[180px] h-[140px] overflow-hidden relative image-border flex items-center justify-center">
                      {isVideo ? (
                        <video
                          controls
                          className="absolute top-0 left-0 w-full h-full"
                          autoPlay={true}
                        >
                          <source
                            src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                            type={`video/${fileExtension}`}
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <Image
                          key={index}
                          src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                          alt={item?.filePath}
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                    <div className="min-h-[1rem] mt-2 max-w-[180px] break-words"
                         dangerouslySetInnerHTML={{ __html: item?.description }}/>
                  </Flex>
                )
              }
            })}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default TicketInfo;